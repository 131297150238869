import React, { useState, useEffect } from 'react'

// MUI Components
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'

// Icons
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

// Custom hook
import useLocalStorage from '../../../hooks/useLocalStorage'

// Styles and theme
import styled from '@mui/material/styles/styled'
import useTheme from '@mui/material/styles/useTheme'

const openedMixin = (theme, width, elevation) => ({
  width: width || theme?.dimentions?.drawerWidth,
  backgroundColor: 'transparent',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: 'none',
  boxShadow: elevation ? theme.shadows[elevation] : 'none',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    paddingTop: '60px'
  },
})

const closedMixin = (theme, width, elevation) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  border: 'none',
  width: 0,
  boxShadow: elevation ? theme.shadows[elevation] : 'none',
  backgroundColor: 'transparent',
  [theme.breakpoints.up('sm')]: {
    width: width || `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    paddingTop: '60px'
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const MobileDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  position: 'fixed',
  top: 0,
  left: 0,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'openwidth' && prop !== 'elevation',
})(({ theme, open, width, elevation }) => ({
  width: width || theme?.dimentions?.drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflow: 'hidden',
  position: 'relative',
  ...(open && {
    ...openedMixin(theme, width?.open, elevation),
    '& .MuiDrawer-paper': openedMixin(theme, width?.open, elevation),
  }),
  ...(!open && {
    ...closedMixin(theme, width?.close, elevation),
    '& .MuiDrawer-paper': closedMixin(theme, width?.close, elevation),
  }),
}))

/**
 * Drawer Component
 * @param {ReactElement} children
 * @param {function} onOpen
 * @param {ReactElement} logo
 * @param {number} width
 * @returns
 */
export default function Drawer({ children, onOpen, logo: Logo, width, elevation = 0 }) {
  const theme = useTheme()
  // persisting the sidebar state
  const [sidebarState, storeSidebarState] = useLocalStorage('sidebar', true)

  const [open, setOpen] = useState(sidebarState)

  // pass the sidebar open state to parent function whenever the state changes
  useEffect(() => {
    onOpen(open)
  }, [open, onOpen])

  // updating and storing the sidebar open state
  const handleDrawerToggle = () => {
    setOpen((prevState) => {
      const updatedState = !prevState
      setOpen(updatedState)
      onOpen(updatedState)
      storeSidebarState(updatedState)
      return updatedState
    })
  }

  const handleDrawerClose = () => {
    if (!open) return
    setOpen(false)
    onOpen(false)
    storeSidebarState(false)
  }

  const handleDrawerOpen = () => {
    if (open) return
    setOpen(true)
    onOpen(true)
    storeSidebarState(true)
  }

  window.sidebar = {
    toggle: handleDrawerToggle, 
    close: handleDrawerClose, 
    open: handleDrawerOpen
  }

  return (
    <StyledDrawer variant="permanent" open={open} width={width} elevation={elevation}>
      <MobileDrawerHeader>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            margin: '1em auto',
          }}
        >
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </MobileDrawerHeader>
      <DrawerHeader sx={{ height: '80px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginY: '1em',
            ...(!open && { display: 'none' }),
          }}
        >
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            margin: '1em auto',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      {children}
    </StyledDrawer>
  )
}

export const DrawerWithRoundedNavButton = (props) => {
  return <Drawer {...props} width={{ open: 240, close: 82 }} />
}
