const forms = {
  facility_details: {
    title: 'Facility Details',
    subTitle:
      'This is where instructions would go like, fill this out so we can properly display your basic practice information.',
    success: {
      create: 'Facility has been added successfully',
      update: 'Facility has been updated successfully',
    },
  },
  facility_configs: {
    title: 'Facility Configs',
    subTitle:
      'This is where instructions would go like, fill this out so we can properly display your basic practice information.',
    success: {
      create: 'Facility config has been added successfully',
      update: 'Facility config has been updated successfully',
      delete: 'Facility config has been deleted successfully',
    },
  },
  global_configs: {
    title: 'Global Config',
    subTitle:
      'This is where instructions would go like, fill this out so we can properly display your basic practice information.',
    success: {
      create: 'Global config has been added successfully',
      update: 'Global config has been updated successfully',
      delete: 'config has been deleted successfully',
    },
  },
  general: {
    encrypted_note:
      '* Encrypted configs are not editable. Please, delete and add a new one instead.',
  },
}

export default forms
