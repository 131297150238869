import React from 'react'

// MUI component
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { Box, useTheme } from '@mui/material'

/**
 * @param {
 *  teampInfo = {
 *      src?: string,
 *      alt?: string,
 *      firstName: string,
 *      lastName: string,
 *      teamName?: string,
 *     },
 *     open : boolean
 * }
 * @returns JSX ELEMENT
 */
const TeamProfile = (props) => {
  const { teamInfo = {}, open, children } = props
  const theme = useTheme()
  const { firstName = '', name: teamName = '', src, alt = '' } = teamInfo
  return (
    <Stack
      direction="column"
      alignItems="center"
      marginY={theme.spacing(2)}
      minHeight={theme.dimentions.teamProfile.heigth}
    >
      <img src={src} alt={alt} />
      <Box
        visibility={open ? 'visible' : 'hidden'}
        sx={{
          transition: 'all .1s ease-in',
          textAlign: 'center',
          marginTop: 1,
        }}
      >
        <Typography
          variant="h5"
          fontWeight="600"
          textTransform="capitalize"
          sx={{
            transition: 'all .1s ease-in',
          }}
        >
          {firstName}
        </Typography>
        <Typography
          variant="overline"
          sx={{
            transition: 'all .1s ease-in',
          }}
        >
          {teamName}
        </Typography>
        {children}
      </Box>
    </Stack>
  )
}

export default TeamProfile
