import { Navigate, useLocation } from 'react-router-dom'

import { useAuthPersist, useAuth } from '../../../auth/hooks/'
import Loader from '../../../components/common/Loader'

import ROUTES from '../../routes'

const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth()
  const location = useLocation()
  const isLoading = useAuthPersist()
  if (isLoading) return <Loader />
  return auth.isAuthenticated ? (
    children
  ) : (
    // passing the from location to login page to use it as back url/route
    <Navigate to={ROUTES.ALL.LOGIN} state={{ from: location }} replace />
  )
}

export default ProtectedRoute
