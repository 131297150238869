import React from 'react'
import Typography from '@mui/material/Typography'

// Sample Page Title Component
const PageTitle = ({ children }) => {
  return (
    <Typography variant="h4" textTransform="capitalize">
      {children}
    </Typography>
  )
}

export default PageTitle
