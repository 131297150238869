export const BASE_URL = process.env.REACT_APP_API_URL

export const API_URL = {
  TASKS: 'tasks',
  RECORDINGS: 'recordings?task_id=',
  RECORDING: 'recording?recording_id=',
}

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
}

export const ACTIONS = {
  INIT: 'INIT',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETED: 'completed',
  FAILED: 'failed'
}
