// import lib(s)
import React, { useEffect, useState, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./MedianBeats.module.css"
import { useTheme } from "@emotion/react"

import ROUTES from "../../../route/routes"
import { useNavigate, useParams } from "react-router-dom"

import { useAxiosPrivate, useDocumentTitle } from "../../../hooks"
import { PAGE_TITLE } from "../../../constants/constants"

import Layout from "../../../layouts/common/Layout"
import PlaceholderText from "../../../components/common/SampleText"
import Loader from "../../../components/common/Loader"
import toast from "react-hot-toast"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Grid } from "@mui/material"

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { selectLead } from '../AnnotatorSlice'

import ECGGrid from "../../../components/Grid"
import ECGWaveform from "../../../components/Waveform"
import FullWidthECGPreview from "../../../components/FullWidthECGPreview"

import ComputeAvgMedBeat from '../../../utils/a&mPlot'
import calculateLeads from '../../../utils/leadCalc'

const MedianBeats = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const urlParams = useParams()
	const theme = useTheme()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	const boxes = useRef([]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [LeadGroup, setLeadGroup] = useState([]);
	const [ThreeRowSamples, setThreeRowSamples] = useState({
		I: [],
		II: [],
		III: [],
		aVR: [],
		aVL: [],
		aVF: [],
		"V1-syn": [],
		"V2-syn": [],
		V1: [],
		V2: [],
		"V3-syn": [],
		V4: [],
		"V5-syn": [],
		"V6-syn": [],
	});
	const [ThreeRowSampleDuration, setThreeRowSampleDuration] = useState(2.5);
	const [Frequency, setFrequency] = useState(300);
	const [WindowCalc, setWindowCalc] = useState({windowWidthPerColumn: 0, samplePerPx: 0, samplesPerColumn: 0, totalSamples:0, samplesPerWindow: 0, newDuration: 0});

	const handleBoxScroll = useCallback((e, index) => {
		const scrollLeft = e.target.scrollLeft;
		setScrollPosition(scrollLeft);

		boxes.current.forEach((box, i) => {
		if (i !== index) {
			box.scrollLeft = scrollLeft;
		}
		});
	}, []);

	
	// Annotator Data
	const AnnotatorData = useSelector((state) => state.Annotator)
	const AnnotatorSettings = useSelector((state) => state.Annotator.settings)
	const ecg = AnnotatorData.filtered
	const totalLeads = AnnotatorData.leadCount

	
	const HeightPerStrip = 200
	const AllLeadWidth = (10*100) * AnnotatorData.settings.scale
	const AllLeadHeight = (totalLeads*HeightPerStrip + 1) * AnnotatorData.settings.scale

	useEffect(() => {
		const leadGroups = Object.keys(ecg.samples).reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index/3)
			if(!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = []
			}
			resultArray[chunkIndex].push(item)
			return resultArray
		}, [])
		setLeadGroup(leadGroups)

		const windowWidthPerColumn = 1000/leadGroups.length
		// ecg.frequency number of samples in 100px
		const samplePerPx = ecg.frequency/100
		const samplesPerColumn = samplePerPx*windowWidthPerColumn
		const totalSamples  = ecg.frequency*10
		const samplesPerWindow = (leadGroups.length) * samplesPerColumn
		let newDuration
		// let newDuration = (totalSamples - samplesPerWindow + samplesPerColumn) / ecg.frequency

		// -- console.log("Total samples: ", totalSamples);
		// -- console.log("Window Size: ", samplesPerWindow);
		// -- console.log("New Duration: ", newDuration);

		// Leads of leadGroups[0] will from 0 index to samplesPerColumn*leadGroups.length
		// Leads of leadGroups[1] will from samplesPerColumn to samplesPerColumn*(leadGroups.length - 1)

		if (totalLeads == 12) {
			const {data } = AnnotatorData.rawData
			let mBGraph = {}
            // Logic For Median Beats 
            if (data.hasOwnProperty("kai12Analysis") ) {
                if (data.kai12Analysis.hasOwnProperty("medianBeats") ) {
                    mBGraph.I = data.kai12Analysis.medianBeats[0]
                    mBGraph.II = data.kai12Analysis.medianBeats[1]
                    mBGraph = calculateLeads(mBGraph);
					if (AnnotatorData?.original?.samples?.V1) {
						mBGraph.V1 = data.kai12Analysis.medianBeats[2]
						mBGraph["V2-syn"] = data.kai12Analysis.medianBeats[3]
					} else {
						mBGraph["V1-syn"] = data.kai12Analysis.medianBeats[2]
						mBGraph.V2 = data.kai12Analysis.medianBeats[3]
					}
                    mBGraph["V3-syn"] = data.kai12Analysis.medianBeats[4]
                    mBGraph.V4 = data.kai12Analysis.medianBeats[5]
                    mBGraph["V5-syn"] = data.kai12Analysis.medianBeats[6]
                    mBGraph["V6-syn"] = data.kai12Analysis.medianBeats[7]
                    setFrequency(150)
                }
            }
			setThreeRowSamples(mBGraph)
			newDuration=2.5;
		} else {
			// Compute
			let output = {}
			Object.keys(AnnotatorData.filtered.samples).map(LeadLabel => {
				output[LeadLabel] = ComputeAvgMedBeat(AnnotatorData.annotations.beats, AnnotatorData.filtered.samples[LeadLabel], [], true)
			})
			setThreeRowSamples({
				...output
			})
			newDuration=10;
			setFrequency(AnnotatorData.filtered.frequency)
		}

		setWindowCalc({windowWidthPerColumn, samplePerPx, samplesPerColumn, totalSamples, samplesPerWindow, newDuration})
		setThreeRowSampleDuration(newDuration)
		setLoading(false)
	}, [ecg.samples, AnnotatorSettings])
	


	return (
		<>
		{loading ? (
			<Loader />
		) : error ? (
			<PlaceholderText>Error</PlaceholderText>
		) : (
			<React.Fragment>
			<div className={styles.Container} style={{height: `${(HeightPerStrip * 3) * AnnotatorData.settings.scale}px`, width: `${AllLeadWidth}px`}}>
				{LeadGroup.map((leadGroup, indexL) => (
					<div
						key={leadGroup}
						style={{ width: `${100 / LeadGroup.length}%`, overflowX: "hidden"}}
						className={styles.box}
						ref={(el) => (boxes.current[indexL] = el)}
						onScroll={(e) => handleBoxScroll(e, indexL)}
					>
						<div style={{ height: `${HeightPerStrip * 3 * AnnotatorData.settings.scale}px`, width: `${AllLeadWidth}px`, position: "relative" }}>
							{leadGroup.map((lead, index) => (
								<div style={{height:`${HeightPerStrip * AnnotatorData.settings.scale}px`,  "--ECGScale": AnnotatorData.settings.scale}} key={index}>
									{/* <span key={`${index}-span`} style={{ position: "fixed", padding: "5px", fontWeight: "900", borderRadius: "4px", backgroundColor: "#ffffff66", color: "#000" }}>{lead}</span> */}
									<FullWidthECGPreview
										key={index}
										ecgTitle = {lead}
										enableBeats={false}
										inverted = {AnnotatorData.settings.inverted}
										scale = {AnnotatorData.settings.scale}
										sample={ThreeRowSamples[lead]}
										seconds={ThreeRowSampleDuration || 2.5}
										heightFactor={2}
										mvSwing={ecg.gain * 2.5}
										onClick={()=>{dispatch(selectLead(lead))}}
										style={{cursor: "pointer"}}
										frequency={Frequency}
										offsetGrid={!(indexL%2 === 0) && WindowCalc.newDuration == 2.5}
									/>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
			{/* {ThreeRowVerticalSamples.map((sample, index) => (
				<div key={index} className={styles.fullLead}>
					<div style={{ height: `199px`, width: `${AllLeadWidth}px`, position: "relative" }}>
						<span style={{ position: "fixed", padding: "5px", fontWeight: "900", borderRadius: "4px", backgroundColor: "#ffffff66", color: "#000" }}>{AnnotatorData.selectedLead || "II"}</span>
						<FullWidthECGPreview
							enableBeats={true}
							sample={sample}
							beats={AnnotatorData.annotations.beats}
							seconds={10 || 30}
							heightFactor={2}
							mvSwing={ecg.gain * 2.5}
							frequency={ecg.frequency}
							// renderTimeScaleFactor = {2}	
						/>
					</div>
				</div>
			))} */}
			{ecg.samples["II"] && (
				<div className={styles.fullLead}>
					<div style={{ height: `${170 * AnnotatorData.settings.scale}px`, width: `${AllLeadWidth}px`, position: "relative" }}>
						{/* <span style={{ position: "fixed", padding: "5px", fontWeight: "900", borderRadius: "4px", backgroundColor: "#ffffff66", color: "#000" }}>{AnnotatorData.selectedLead || "II"}</span> */}
						<FullWidthECGPreview
							enableBeats={true}
							ecgTitle = {AnnotatorData.selectedLead || "II"}
							sample={ecg.samples[AnnotatorData.selectedLead || "II"]}
							inverted = {AnnotatorData.settings.inverted}
							scale = {AnnotatorData.settings.scale}
							beats={AnnotatorData.annotations.beats}
							beatLabel = {AnnotatorData.annotations.labels.beats}
							seconds={10}
							heightFactor={1.7}
							mvSwing={ecg.gain * 2.5}
							frequency={ecg.frequency}
							renderTimeScaleFactor = {1}
						/>
					</div>
				</div>
			)}
			</React.Fragment>
		)}
	</>
)
}

export default MedianBeats
