// import lib(s)
import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./RRIntervalPlot.module.css"

import { useTheme } from "@emotion/react"

import { paintRRGraph } from "../../../utils/rrPlot"
import _ from 'lodash'


const RRIntervalPlot = () => {
    const RRCanvas = useRef()
    const RRContainer = useRef()
    const Annotator = useSelector((state) => state.Annotator)
    const [ContainerSize, setContainerSize] = useState([0,0]) //[w,h]

    const [PageLoaded, setPageLoaded] = useState(false)

    const MARGIN = 60
    const MARGIN_LEFT = 60

    useEffect(() => {
        setPageLoaded(true)
    }, [])
    

    useEffect(() => {
        setContainerSize([RRContainer.current.offsetWidth , RRContainer.current.offsetHeight])
        canvasBlurFix();
        let rr = []
        if (Annotator.annotations.beats) {
            // Beats are present so calculate median beats.
            const sorted = _.sortBy(Annotator.annotations.beats, ['i'])
            for (let i = 0; i < (sorted.length - 1); i++) {
                if (sorted[i].t !== 'x' &&
                    sorted[i + 1].t !== 'x' &&
                    sorted[i].t !== 'd' &&
                    sorted[i + 1].t !== 'd') {
                    let int = {
                        sample: (sorted[i].i + sorted[i + 1].i) / 2,
                        rr: sorted[i + 1].i - sorted[i].i
                    }
                    rr.push(int)
                }
            }
        }
        const canvas = RRCanvas.current
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)

        paintRRGraph(
            context,
            RRContainer.current.offsetWidth - 130,
            RRContainer.current.offsetHeight,
            rr,
            Annotator.filtered.samples.I.length,
            Annotator.filtered.frequency
        )
    }, [PageLoaded, Annotator.annotations.beats]);


    const canvasBlurFix = () => {
        const canvas = RRCanvas.current;
        const ctx = canvas.getContext('2d');
        const devicePixelRatio = window.devicePixelRatio || 1;

        function setCanvasSize() {
        // Get the display size of the canvas container (parent element)
            const containerWidth = canvas.parentElement.clientWidth;
            const containerHeight = canvas.parentElement.clientHeight;

            // Scale the canvas based on the device pixel ratio
            canvas.width = containerWidth * devicePixelRatio;
            canvas.height = containerHeight * devicePixelRatio;

            // Set the canvas size in CSS to ensure it maintains the correct aspect ratio
            canvas.style.width = containerWidth + 'px';
            canvas.style.height = containerHeight + 'px';

            // Scale the context to match the device pixel ratio
            ctx.scale(devicePixelRatio, devicePixelRatio);
        }

        // Call the function once on page load and whenever the window is resized
        setCanvasSize();
        window.addEventListener('resize', setCanvasSize);
    }

    return (
        <div  ref={RRContainer} style={{ height: `calc(100% + ${MARGIN_LEFT}px)`, width:`calc(100% + ${MARGIN_LEFT}px)`}}>

            <h1 className={styles.rrPlotTitle}>R-R Interval Plot</h1>
            <canvas
                style={{marginLeft: MARGIN_LEFT, marginTop: MARGIN}}
                height={ContainerSize[1]}
                width={ContainerSize[0]}
                ref={RRCanvas}
                className={styles.canvas}
            >
            </canvas>
            <svg width={`${ContainerSize[0]}`} height={`${ContainerSize[1]}`} className={styles.topSVG}>
                <g id="xAxisRR" transform={`translate(${MARGIN_LEFT}, ${ContainerSize[1] - MARGIN})`}>
                    <text transform={`translate(${ContainerSize[0] / 2}, ${35})`} fill="black">Time (Seconds)</text>
                </g>
                <g id="yAxisRR" transform={`translate(${MARGIN_LEFT}, ${MARGIN})`}>
                    <text transform={`translate(${-40}, ${ContainerSize[1] / 3})rotate(270)`} fill="black">RR (Seconds)</text>
                </g>
                <g id="yAxisBPM" transform={`translate(${ContainerSize[0] + MARGIN_LEFT}, ${MARGIN})`}>
                    <text transform={`translate(${40}, ${ContainerSize[1] / 3})rotate(90)`} fill="black">BPM</text>
                </g>
            </svg>
        </div>
    )
}

export default RRIntervalPlot