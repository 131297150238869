import React from 'react'
import { Link } from 'react-router-dom'

import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material'

export const RoundedNavButtonRoot = styled(ListItemButton)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  minHeight: '56px',
  '&.Mui-selected, &:hover, &.Mui-focusVisible': {
    borderRadius: '50px 50px',
  },
}))

/**
 * Nav Button component
 * @param {string} to // target route
 * @param {number} index // item index
 * @param {boolean} isSelected // if the nav batton is active or not
 * @param {function} onClick // callback function
 * @param {string} displayName // Nav item display name / link text
 * @param {ReactComponentElement} icon
 * @param {boolean} addDivider // divider is required after nav buttonm or not
 * @returns JSX Element
 */

const NavButton = ({ rootComponent: RootComponent, ...restProps }) => {
  const {
    to,
    index,
    isSelected = false,
    onClick,
    displayName,
    icon,
    addDivider = false,
  } = restProps
  return (
    <>
      <RootComponent
        component={Link}
        to={to}
        selected={isSelected}
        onClick={() => onClick(index)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={displayName} sx={{
          textTransform: 'capitalize'
        }}/>
      </RootComponent>
      {addDivider && <Divider />}
    </>
  )
}

export const RoundedNavButton = (props) => (
  <NavButton rootComponent={RoundedNavButtonRoot} {...props} />
)
export const DefaultNavButton = (props) => (
  <NavButton rootComponent={ListItemButton} {...props} />
)

NavButton.Rounded = RoundedNavButton
NavButton.Default = DefaultNavButton

export default NavButton
