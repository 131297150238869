import React from 'react'

import { styled } from '@mui/material'

import { Outlet } from 'react-router-dom'
import Layout from '../common/Layout'

// Main container
const Main = styled(Layout.Main)(({ theme }) => ({
  height: `calc(100vh - ${
    theme.dimentions.header + theme.dimentions.footer + 52
  }px )`,
}))

const BasicLayout = ({ header, footer }) => {
  return (
    <>
      {/* Header section */}
      {header && <Layout.Header>{header}</Layout.Header>}

      {/* Main content section */}
      <Main>
        <Outlet />
      </Main>

      {/* footer section */}
      {footer && <Layout.Footer>{footer}</Layout.Footer>}
    </>
  )
}

export default BasicLayout
