import React from 'react'

// MUI component
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Stack  from '@mui/material/Stack'

import PageTitle from '../PageTitle'


import useDocumentTitle from '../../../hooks/useDocumentTitle'

/**
 * @param {*} props
 * @returns Header-component
 */

const Header = ({ children }) => {
  // updating the document header
  const [title] = useDocumentTitle()
  // updating the page title
  return (
    <Stack
    component={Toolbar}
      direction="row"
      width="100%"
      alignItems="center"
      flexWrap="wrap"
    >
      <PageTitle>{title}</PageTitle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          marginLeft: 'auto',
          marginTop: 1,
          gap: 1,
        }}
      >
        {children}
      </Box>
    </Stack>
  )
}

export default Header
