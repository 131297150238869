import { useDispatch } from 'react-redux'
import { axiosPrivate } from '../../api/axios'
import { API_URL } from '../../api/constants'
import useAuth from './useAuth'

const useRefreshToken = () => {
  const { auth, login } = useAuth()
  const dispatch = useDispatch()
  const refresh = async () => {
    const controller = new AbortController()
    try {
      const response = await axiosPrivate.get(API_URL.REFRESH, {
        signal: controller.signal,
        timeout: 3000,
      })
      if (response.status === 200) {
        dispatch(
          login({
            ...auth.user,
            token: response.data.id_token,
          })
        )
        return response.data.id_token
      }
    } catch (error) {
      // console.log(error)
      controller.abort()
    } finally {
      return null
    }
  }
  return refresh
}

export default useRefreshToken
