import React from 'react'
import Layout from '../../../layouts/common/Layout'
import Typography from '@mui/material/Typography'
import { Spinner } from '../../../layouts/common/Loaders'
import { ReactComponent as AliveCorLogo } from '../../../assets/images/aliveCor_logo.svg'

const Loader = ({ loadingText }) => {
  return (
    <Layout.CenterWrapper>
      <Spinner loading size="10rem">
        <AliveCorLogo width="5rem" />
      </Spinner>
      {loadingText && (
        <Typography
          sx={{
            marginTop: 8,
            textTransform: 'uppercase',
            letterSpacing: '2.5px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
          variant="h6"
          color="primary"
        >
          {loadingText}
        </Typography>
      )}
    </Layout.CenterWrapper>
  )
}

export default Loader
