// import lib(s)
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./AllLeadView.module.css"
import { useTheme } from "@emotion/react"

import ROUTES from "../../../route/routes"
import { useNavigate, useParams } from "react-router-dom"

import { useAxiosPrivate, useDocumentTitle } from "../../../hooks"
import { PAGE_TITLE } from "../../../constants/constants"

import Layout from "../../../layouts/common/Layout"
import PlaceholderText from "../../../components/common/SampleText"
import Loader from "../../../components/common/Loader"
import toast from "react-hot-toast"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Grid } from "@mui/material"

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { selectLead } from '../AnnotatorSlice'

import ECGGrid from "../../../components/Grid"
import ECGWaveform from "../../../components/Waveform"
import FullWidthECGPreview from "../../../components/FullWidthECGPreview"

const AllLeadView = (props) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const urlParams = useParams()
	const theme = useTheme()

	// Annotator Data
	const AnnotatorData = useSelector((state) => state.Annotator)
	const ecg = AnnotatorData.filtered
	const totalLeads = AnnotatorData.leadCount

	
	const HeightPerStrip = 200
	const AllLeadWidth = (ecg.duration*100) * AnnotatorData.settings.scale
	const AllLeadHeight = (totalLeads*HeightPerStrip + 1) * AnnotatorData.settings.scale
	return (
			<div style={{height: `${AllLeadHeight}px`, width: `${AllLeadWidth}px`, position: "relative" }} className={styles.RightColumn}>
				{Object.keys(ecg.samples).map((lead) => (
					<FullWidthECGPreview 
						key={lead}
						ecgTitle = {lead}
						enableBeats = {true}
						inverted = {AnnotatorData.settings.inverted}
						scale = {AnnotatorData.settings.scale}
						sample = {ecg.samples[lead]}
						beats = {AnnotatorData.annotations.beats}
						beatLabel = {AnnotatorData.annotations.labels.beats}
						seconds = {ecg.duration || 30}
						heightFactor = {2}
						mvSwing = {ecg.gain*2.5}
						onClick={()=>{
							dispatch(selectLead(lead))
							props.changeTab(2)
						}}
						style={{cursor: "pointer"}}
						renderTimeScaleFactor = {2}
						frequency={ecg.frequency}
					/>
				))}
			</div>
	)
}


const AllLeadViewOLD = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const urlParams = useParams()
	const theme = useTheme()

	// Annotator Data
	const AnnotatorData = useSelector((state) => state.Annotator)
	const ecg = AnnotatorData.filtered
	const totalLeads = AnnotatorData.leadCount

	
	const HeightPerStrip = 200
	const AllLeadWidth = (ecg.duration*100) * AnnotatorData.settings.scale
	const AllLeadHeight = (totalLeads*HeightPerStrip + 1) * AnnotatorData.settings.scale
	return (
		<div className={styles.Container}>
			{/* <div className={styles.LeftColumn}>
				{Object.keys(ecg.samples).map((lead) => (
					<div key={lead} style={{height: `${HeightPerStrip}px`}} className={styles.buttonSpace}>
						<button className={styles.openLeadButton} onClick={()=>{dispatch(selectLead(lead))}}> 
							<ChevronRightIcon />
						</button>
					</div>
				))}
			</div> */}

			<div style={{height: `${AllLeadHeight}px`, width: `${AllLeadWidth}px`, position: "relative" }} className={styles.RightColumn}>
				{Object.keys(ecg.samples).map((lead) => (
					<FullWidthECGPreview 
						key={lead}
						ecgTitle = {lead}
						enableBeats = {true}
						inverted = {AnnotatorData.settings.inverted}
						scale = {AnnotatorData.settings.scale}
						sample = {ecg.samples[lead]}
						beats = {AnnotatorData.annotations.beats}
						seconds = {ecg.duration || 30}
						heightFactor = {2}
						mvSwing = {ecg.gain*2.5}
						onClick={()=>{dispatch(selectLead(lead))}}
						style={{cursor: "pointer"}}
						renderTimeScaleFactor = {2}
						frequency={ecg.frequency}
					/>
				))}
			</div>

			{/* <div style={{height: `${AllLeadHeight}px`, width: `${AllLeadWidth}px`, position: "relative" }} className={styles.RightColumn}>
				{/* Top Clicker Layer 
				<div style={{height: `${AllLeadHeight}px`, width: `${AllLeadWidth}px`, zIndex: 3, position: "absolute" }} >
					{Object.keys(ecg.samples).map((lead) => (
						<div key={lead} style={{height: `${HeightPerStrip}px`, width: `${AllLeadWidth}px`, cursor: "pointer", fontWeight: "600"}} onClick={()=>{dispatch(selectLead(lead))}}>
							&nbsp; {lead}
						</div>
					))}
				</div>
				{/* Middle layer - Waves 
				<div style={{height: `${AllLeadHeight}px`, width: `${AllLeadWidth}px`, zIndex: 2, position: "absolute" }}>
					{Object.keys(ecg.samples).map((lead) => (
						<div key={lead} style={{height: `${HeightPerStrip}px`, width: `${AllLeadWidth}px`}}>
							<svg style={{height: `${HeightPerStrip}px`, width: `${AllLeadWidth}px`}}>
								<ECGWaveform
									key={lead}
									capsule={false}
									height={HeightPerStrip}
									samples={ecg.samples[lead]}
									inverted={AnnotatorData.settings.inverted}
									mainsFreq={ecg.mainsFrequency}
									mvSwing={ecg.gain*2}
								/>
							</svg>
						</div>
					))}
				</div>
				{/* Bottom layer - Grids 
				<svg style={{height: `${AllLeadHeight}px`, width: `${AllLeadWidth}px`, zIndex: 1, position: "absolute" }}>
					<ECGGrid 
						renderSmallGrid={true}
						rowSeconds={ecg.duration}
						stripWidthPx={AllLeadWidth}
						height={AllLeadHeight}
					/>
				</svg>
			</div> */}

		</div>
	)
}

export default AllLeadView
