// import lib(s)
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui
import { GridActionsCellItem } from '@mui/x-data-grid-premium'

import styles from './Annotator.module.css'

// Custom component
import { KcTable } from '../../components/common/kc-table'
import {
  SUPPORTED_LOCALE,
  LOCALE_DEFAULT_TEXT,
  IconComboCellRenderer,
} from '../../components/common/kc-table'


// custom component
import PlaceholderText from "../../components/common/SampleText";
import Layout from "../../layouts/common/Layout";

import { METHODS, errorHandler } from "../../hooks/useFetch";

// constants
import { API_URL } from "../../api/constants";

import { useAxiosPrivate, useDocumentTitle } from "../../hooks";
import { PAGE_TITLE } from "../../constants/constants";
import Loader from "../../components/common/Loader";

import { useNavigate, useParams } from 'react-router-dom'
import ROUTES from '../../route/routes'
import { CONST_VARIANT } from '../../components/common/kc-table/constants'

// Icons
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutlined';

import Box from '@mui/material/Box';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { log } from "mathjs";

const Task = (props) => {
  // Grid columns
 const urlParams = useParams()
 const navigate = useNavigate()
  const currentTaskRecordings = useSelector(state => state.Annotator.currentTaskRecordings)

  const renderDateCell = (params) => {
    const { value } = params
    const date = new Date(value)

    return (
      <div>
        {date.toLocaleDateString()} {date.toLocaleTimeString()}
      </div>
    )
  }

  const renderCompletion = (params) => {
    const { value } = params
    if(value && value.questionCorrect) {
      return (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%", width: "100%"}}>
            <div>✅</div>
          </div>
      )
    } else {
      return (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%", width: "100%"}}>
            <div>❌</div>
          </div>
      )
    }
  }

  const columns = [
    { field: 'annotationResults', headerName: 'Status', maxWidth: 60,renderCell:renderCompletion, flex: 1 },
    // { field: 'id', headerName: 'ID', maxWidth: 100, flex: 1 },
    { field: 'name', headerName: 'Recording Name', minWidth: 800, flex: 1 },
    // { field: 'createdAt', headerName: 'Created at', maxWidth: 200, renderCell:renderDateCell, flex: 1 },
    // { field: 'updatedAt', headerName: 'Updated at', maxWidth: 200, renderCell:renderDateCell, flex: 1 },
  ]


  const LOCALE = {
    ...LOCALE_DEFAULT_TEXT.enUS,
    noRowsLabel: 'No Facilities Found...',
  }

  return (
        <React.Fragment>
        {currentTaskRecordings.length && <div style={{display: 'flex', height: "100%", width: "100%"}}>
            <KcTable
            initialState={{
                sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
            }}
            rows={currentTaskRecordings}
            columns={columns}
            defaultLocaleLanguage={SUPPORTED_LOCALE.English}
            defaultLocaleText={LOCALE}
            pagination
            layout={CONST_VARIANT.KC_DEFAULT}
            disableSelectionOnClick
            onRowClick={(params, event) => {navigate(`/annotator/${urlParams["task_id"]}/${params.id}`);window.loadTurkTask(urlParams["task_id"],params.id, false);}}
            getRowClassName={(params) => {
              if (params.row.id.toString() === urlParams["ecg_id"]) {
                return styles.TableSelected
              }
              if(params?.row?.annotationResults && params?.row?.annotationResults?.questionCorrect) {
                return styles.TableCompleted
              } else {
                return styles.TableIncomplete
              }
            }}
            components={{
                Toolbar: QuickSearchToolbar,
            }}
            autoHeight={currentTaskRecordings.length <= 10}
            />
        </div>}
        </React.Fragment>
  );
};

function QuickSearchToolbar() {
	return (
	  <Box
		sx={{
		  p: 0.5,
		  pb: 0,
		  width: '100%',
		}}
	  >
		<GridToolbarQuickFilter sx={{width: '100%'}} />
	  </Box>
	);
  }
export default Task;
