/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import styles from './Beat.module.css'
import { updateContext, updateGhostBeat, updateMouse } from '../../AnnotatorSlice'
import { useDispatch, useSelector } from 'react-redux';

const Beat = (prop) => {
    const dispatch = useDispatch()
    const {
        coordinates = [0,0],
        x = coordinates[0],
        y = coordinates[1],
        type,
        index,
        beatLabel
   } = prop

    // 0 = Noise (Shown on Frontend)
    // 1 = Noise (Shown on Frontend)
    // 2 = Normal (Shown on Frontend)
    // 3 = VEB (Shown on Frontend)
    // 5 = SVEB (Shown on Frontend)

    const renderGhost = () => (
        <svg className={styles.beat}>
            <g id={`${index}`} transform={`translate(${x},${y})`}>
                <circle  onMouseDown={clicked} r="7" fill="#8c8c8c" />
            </g>
        </svg>
        )
    const renderNoise = () => (
        <svg className={styles.beat}>
            <g id={`${index}`} transform={`translate(${x},${y})`}>
                <circle onMouseDown={clicked} r="7" fill="#6CCA6E"/>
            </g>
            {/* <g id={`${index}`} transform={`translate(${x-8},${y-8})`}>
                <rect  onMouseDown={clicked} cx="-8" cy="-8" width="16" height="16" fill="#cacdd0" />
            </g> */}
        </svg>
        )
    const renderBeat = () => (
        <svg className={styles.beat}>
            <g id={`${index}`} transform={`translate(${x},${y})`}>
                <circle onMouseDown={clicked} r="7" fill="#6CCA6E"/>
            </g>
        </svg>
        )
    const renderVe = () => (
        <svg className={styles.beat}>
            <g id={`${index}`} transform={`translate(${x-11},${y-11})`}>
                <path  onMouseDown={clicked} cx="-11" cy="-11" d="M10 0L19.5263 16.5H0.473721L10 0Z" fill="#DC271B"/>
            </g>
        </svg>
        )
    const renderSVe = () => (
        <svg className={styles.beat}>
            <g id={`${index}`} transform={`translate(${x-11},${y-11})`}>
                <path  onMouseDown={clicked} d="M0 10L10 0L20 10L10 20L0 10Z" fill="#5EC2FB"/>
            </g>
        </svg>
        )

    const renderOne = () => {
        switch(type){
            case 0:
                return renderGhost()
                break
            case 1:
                return renderNoise()
                break
            case 2:
                return renderBeat()
                break
            case 3:
                return renderVe()
                break
            case 4:
                return <g></g>
                break
            case 5:
                return renderSVe()
                break
            default:
                return <g></g>
                break
        }
    }
    const clicked = (e) => {
        console.log("Clicked", e.target.id);
        e.preventDefault()
        if(type != 0){
        dispatch(updateGhostBeat({show:false}))
        dispatch(updateMouse({mousedown:true,mouseDownOnBeat:true,bStart:index,mouseDownOnBeatType:type}))
        dispatch(updateContext({
            show : true,
            data : {
                top:y,
                left:x,
                showDelete:true,
                beatIndex:index,
            }
        }))
        }
    }

	// return renderOne(type)

	return (
        <svg className={styles.beat}>
            <g id={`${index}`} transform={`translate(${x},${y})`}>
                <circle onMouseDown={clicked} r="7" fill={beatLabel.find((label) => label.slug === type)?.color || "#29292929"}/>
            </g>
        </svg>
    )
        
}

export default Beat
