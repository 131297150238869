import { createSlice } from '@reduxjs/toolkit'

const initialState = { user: null, isAuthenticated: false }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload
      state.isAuthenticated = true
    },
    logout(state) {
      state.isAuthenticated = false
      state.user = null
    },
  },
})

export const authActions = authSlice.actions

export const authReducer = authSlice.reducer

export const selectAuth = (state) => state.auth
