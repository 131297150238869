import React from 'react'

// MUI Components
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// Svg assest
import { ReactComponent as NotFoundIllustration } from '../../assets/images/notfound.svg'

// Constants
import { CONTENT } from '../../constants/constants'
import Layout from '../../layouts/common/Layout'
import LinkButton from '../../components/common/LinkButton/LinkButton'
import ROUTES from '../../route/routes'

const NotFound = () => {
  const { notfound } = CONTENT
  return (
    <Layout.CenterWrapper>
      <Stack direction="column" alignItems="center" marginY={8}>
        <NotFoundIllustration width="100%" />
        <Typography variant="h2" fontWeight={600} marginY={2}>
          {notfound.title}
        </Typography>
        <Typography variant="subtitle1" marginBottom={2}>
          {notfound.subtitle}
        </Typography>
        <LinkButton to={ROUTES.ALL.ROOT}>Home</LinkButton>
      </Stack>
    </Layout.CenterWrapper>
  )
}

export default NotFound
