import { List, Slide } from '@mui/material'
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import { isScrollable } from '../../../utils'
import NavButton from '../NavButton'
import SIDE_NAV_CONFIG from '../../Sidebar/config'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useLocation } from 'react-router-dom'

const NavList = ({open}) => {
  const { pathname } = useLocation()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isListScrollable, setIsListScrollable] = useState(false)
  const listRef = useRef(null)

  useLayoutEffect(() => {
    setIsListScrollable(isScrollable(listRef.current))
  }, [])
  
  useEffect(() => {
    const selectedItemIndex = SIDE_NAV_CONFIG.findIndex(config => config.to === pathname)
    setSelectedIndex(selectedItemIndex)
  }, [pathname])
  
  // nav item click handler
  const handleNavItemClick = (index) => {
    setSelectedIndex(index)
  }

  // generating nav items based on SID_NAV_CONFIG
  const navItems = SIDE_NAV_CONFIG.map((route, index) => (
    <NavButton.Rounded
      {...route}
      key={index}
      index={index}
      isSelected={selectedIndex === index}
      onClick={handleNavItemClick}
      open={open}
    />
  ))

  return (
    <>
      {/* adding Scroll indicators if the list is scrollale */}
      {isListScrollable && (
        <Slide
          direction="up"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <KeyboardArrowUpIcon sx={{ margin: '0 auto -.5rem auto' }} />
        </Slide>
      )}
      <List sx={{ overflowY: 'auto', overflowX: 'hidden' }} ref={listRef}>
        {navItems}
      </List>
      {isListScrollable && (
        <Slide
          direction="down"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <KeyboardArrowDownIcon sx={{ margin: '-.5rem auto 0 auto' }} />
        </Slide>
      )}
    </>
  )
}

export default NavList
