import { Box, styled } from '@mui/material'

// Navigation Container
const Nav = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'elevation',
})(({ theme, elevation = 0 }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
}))

// Aside container
const Aside = styled('aside', {
  shouldForwardProp: (prop) => prop !== 'elevation',
})(({ theme, elevation = 0 }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
}))

// Header container
const Header = styled('header', {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'fixedHeight' && prop !== 'elevation',
})(({ theme, height, fixedHeight, elevation = 0 }) => ({
  backgroundColor: theme.palette.custom[theme.palette.mode],
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
  padding: `${theme.spacing(1)} 0`,
  minHeight: height || theme.dimentions.header,
  height: fixedHeight || 'auto',
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'nowrap',
  position: 'sticky',
  zIndex: 9,
  top: 0,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: `calc(${theme.spacing(3)} + 4px)`,
  },
}))

// Main container
const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'elevation',
})(({ theme, elevation = 0 }) => ({
  height: 'auto',
  margin: theme.spacing(1),
  borderRadius: '4px',
  flexGrow: 1,
  // backgroundColor: theme.palette.background.default,
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

// Footer container
const Footer = styled('footer', {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'fixedHeight' && prop !== 'elevation',
})(({ theme, height, fixedHeight, elevation = 0 }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
  minHeight: height || theme.dimentions.footer,
  height: fixedHeight || 'auto',
}))

// Column container
const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100%',
  backgroundColor: theme.palette.custom[theme.palette.mode],
  flex: 1,
}))

// Flex container
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  minHeight: '100%',
  backgroundColor: theme.palette.custom[theme.palette.mode],
}))

const CenterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  width: '100%',
  minHeight: '100%',
}))

const ColumnContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'columnCount' &&
    prop !== 'animate' &&
    prop !== 'lastColumnWidth' &&
    prop !== 'display',
})(
  ({ theme, columnCount = 1, animate, lastColumnWidth, display = 'flex' }) => ({
    display: display,
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    position: 'relative',
    '& > *': {
      flex: `calc(${100 / columnCount}% - ${theme.spacing(3)}) 1 0`,
      [theme.breakpoints.down('sm')]: {
        '&:not(:last-child)': {
          minWidth: '100%',
        },
      },
      '@keyframes glow': {
        from: {
          opacity: 0,
        },
        to: {
          opacity: 1,
        },
      },
      animation: animate ? 'glow .5s forwards ease-in-out' : 'none',
      '&:last-child': {
        maxWidth: lastColumnWidth,
        width: lastColumnWidth,
        height: lastColumnWidth,
        fontSize: '32px',
        marginLeft: 'auto',
      },
    },
  })
)

// Tool Header container
const ToolHeader = styled('header', {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'fixedHeight' && prop !== 'elevation',
})(({ theme, height, fixedHeight, elevation = 0 }) => ({
  backgroundColor: theme.palette.custom[theme.palette.mode],
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
  padding: `${theme.spacing(1)} 0`,
  minHeight: 100,
  height: fixedHeight || 'auto',
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'nowrap',
  position: 'sticky',
  zIndex: 9,
  top: 0,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: `calc(${theme.spacing(3)} + 4px)`,
  },
}))

// Tool Main container
const ToolMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'elevation',
})(({ theme, elevation = 0 }) => ({
  height: 'auto',
  margin: theme.spacing(1),
  borderRadius: '4px',
  flexGrow: 1,
  // backgroundColor: theme.palette.background.default,
  boxShadow: elevation ? theme.shadows[+elevation] : 'none',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

const Layout = {
  Nav,
  Header,
  Main,
  Footer,
  Aside,
  Column,
  Container,
  CenterWrapper,
  ColumnContainer,
  ToolHeader,
  ToolMain,
}

export default Layout
