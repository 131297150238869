import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from '../auth/slices/auth.slice'
import { pageReducer } from '../pages/slices/page.slice'
// REDUCER_IMPORTS
import MainToolReducer from '../pages/MainTool/MainToolSlice.js';
import UploadTaskReducer from '../pages/UploadTask/UploadTaskSlice.js';
import TaskListSlice from '../pages/TaskList/TaskListSlice'
import AnnotatorSlice from '../pages/Annotator/AnnotatorSlice'
import OpenEcgSlice from '../pages/OpenEcg/OpenEcgSlice'

const store = configureStore({
  reducer: {
    /**
     * Add Reducers here
     */
    auth: authReducer, //Don't remove if you are using GoogleAuth component
    page: pageReducer,
    
    // REDUCERS
    MainTool : MainToolReducer,
    UploadTask : UploadTaskReducer,
    TaskList: TaskListSlice,
    Annotator: AnnotatorSlice,
    OpenEcg: OpenEcgSlice,
  },
  // disabling redux dev tools on production
  devTools: process.env.NODE_ENV === 'production' ? false : true,
})

export default store
