import React from 'react'
import NotFound from '../../pages/NotFound'
import ROUTES from '../routes'
import Forbidden from '../../pages/Forbidden'
import Login from '../../pages/Login'
import { Navigate } from 'react-router-dom'
import TaskList from '../../pages/TaskList'
import Annotator from '../../pages/Annotator'
import OpenEcg from '../../pages/OpenEcg'
import UploadTask from '../../pages/UploadTask'
import MainTool from '../../pages/MainTool'

/**
 * TODO:
 * useRouteAuthorizationChecker will receive permission and practice name (RPM / IDTF / CRP / TK)
 * each practice have set of routes in contants file
 */

// A hook will return a function
const useRouteAuthorizationChecker = (permissions, practice) => (route) => {
  const { isPhysician, isAdmin } = permissions

  // user permission logic can go @here
  let returnComponent = null
  // anything out of Navigation shall route to default / 404 (Not Found page)
  switch (route) {
    case ROUTES.ALL.ROOT:
      returnComponent = <Navigate to={ROUTES.ALL.LOGIN} />
      break
    case ROUTES.ALL.FORBIDDEN:
      returnComponent = <Forbidden />
      break
    case ROUTES.ALL.LOGIN:
      returnComponent = <Login />
      break
    case ROUTES.ALL.TASK_LIST:
      returnComponent = <TaskList />
      break
    case ROUTES.ALL.ANNOTATOR:
      returnComponent = <Annotator />
      break
    case ROUTES.ALL.OPEN_ECG:
      returnComponent = <OpenEcg />
      break
    case ROUTES.ALL.UPLOAD_TASK:
      returnComponent = <UploadTask />
      break
    case ROUTES.ALL.MAIN_TOOL:
      returnComponent = <MainTool />
      break
    default:
      returnComponent = <NotFound />
      break
  }

  return returnComponent
}

export default useRouteAuthorizationChecker
