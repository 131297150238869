import React from 'react';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import DefaultToolbarContainer from '../components/toolbar-container/DefaultToolbarContainer';
import { CONST_VARIANT, MUI_LICENSE } from '../constants';
import { useLanguageSupport } from '../hooks';
import { Paper } from '@mui/material'
import { DataGridPremiumWithKCStyles } from '../theme'
// import { InteropDataGrid, KardiaCareDataGrid } from '../theme/styled-default/DefaultKCLayout'
// -------------------- MUI-x Wrapper Table ---------------------
// |          Components uses @mui/x-data-grid-premium          |
// --------------------------------------------------------------

/** 
 * Props/use 
    defaultLocaleLanguage - default is 'enUS', Supported languages can be passed
    defaultLocaleText - AliveCor Supported Language def, if any

    toolBarComponents - value - 'MUI_DEFAULT' | 'DISABLED' | undefined - 'KC_DEFAULT'|  customFn()
    layoutStyle - {}-default MUI / null, undefined,false - KCDefault / custom object
 * */

LicenseInfo.setLicenseKey(MUI_LICENSE);

const KcTable = React.forwardRef((props, ref) => {
  const {
    defaultLocaleLanguage,
    defaultLocaleText,
    toolBarComponents = CONST_VARIANT.KC_DEFAULT,
    layoutStyle = CONST_VARIANT.KC_DEFAULT,
    layout = CONST_VARIANT.KC_DEFAULT,
    filterChangeCallback,
    disableQuickFilter = false,
    components,
    componentsProps,
    children
  } = props

  const LANGUAGE = useLanguageSupport(defaultLocaleLanguage, defaultLocaleText);

  const renderToolbarComponent = () => {
    const TOOLBAR_STATE = typeof toolBarComponents === 'object' ?
      CONST_VARIANT.KC_DEFAULT
      :
      toolBarComponents;

    // cases to validate
    switch (TOOLBAR_STATE) {
      // if it is 'MUI_DEFAULT' - defaulted to MUI Grid toolbar
      case CONST_VARIANT.MUI_DEFAULT:
        return GridToolbar
      // if it is 'DISABLED' - no toolbar to display
      case CONST_VARIANT.DISABLED:
        return null
      // if it is 'GRID_KC_LAYOUT_STYLE' - Default kardia component
      case CONST_VARIANT.KC_DEFAULT:
        return DefaultToolbarContainer
      default:
        return toolBarComponents
    }
  }

  const renderLayoutStyle = () => {
    const VARIANT = (layoutStyle === null) ?
      CONST_VARIANT.MUI_DEFAULT
      :
      (typeof layoutStyle === 'object' && Object.keys(layoutStyle).length === 0) ?
        CONST_VARIANT.KC_DEFAULT
        :
        layoutStyle;

    // cases to validate
    switch (VARIANT) {
      case CONST_VARIANT.MUI_DEFAULT:
        return null;
      default:
        return layoutStyle;
    }
  }

  const getComponent = () => {
  switch (layout) {
    case CONST_VARIANT.KC_DEFAULT:
      return DataGridPremiumWithKCStyles;
    default:
      return DataGridPremium;
  }
  }

  const GridComponent = getComponent()
  return (
    <Paper sx={{ height: '100%', width: '100%', padding: {
      sm: 1,
      xs: 1,
    } }}>
      <GridComponent
        ref={ref}
        {...props}
        sx={layout ? {} : renderLayoutStyle()}
        getEstimatedRowHeight={() => 100}
        localeText={LANGUAGE}
        components={{
          Toolbar: renderToolbarComponent(),
          ...components
        }}
        componentsProps={{ toolbar: { disableQuickFilter }, ...componentsProps }}
        onFilterModelChange={(newFilterModel) => {
          filterChangeCallback !== undefined && filterChangeCallback(newFilterModel)
        }
        }
      />
      {children}
    </Paper>
  )
})

export default KcTable;
