import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { PATH_PAGE_TITLE_MAP } from '../../constants/constants'

// Custom Components
import NotFound from '../../pages/NotFound'

// constants
import ROUTES from '../routes'

// hook to create the Browser router using the given routeConfig
const useRouter = (routeConfig) => {
  const path = ROUTES.ALL.ROOT
  const errorElement = <NotFound />

  // iterating over the given config list and generating the routes
  const routes = routeConfig.map((rc) => ({
    path,
    errorElement,
    element: rc?.layout,
    children: rc?.config,
    handle: {
      // you can put whatever you want on a route handle
      // here we use "crumb" and return some elements,
      // this is what we'll render in the breadcrumbs
      // for this route
      crumb: () => ({
        title: PATH_PAGE_TITLE_MAP[path],
        path
      }),
    },
  }))

  // creating router
  const router = createBrowserRouter(routes)

  return router
}

export default useRouter
