/**
 * Library to filter ECG Signal. 
 * Implements: 
 *      - Adaptive Filter, 
 *      - Butterworth Highpass Filter, 
 *      - Butterworth Lowpass Filter
 * @module ecgFilter.js
 * @version 1.0.0
 * @author Kim Barnett
*/
const filtfilt = (numeratorCoeffs, denominatorCoeffs, signal, padLength = 3 * Math.max(numeratorCoeffs.length, denominatorCoeffs.length)) => {
    const start = performance.now()
    if (signal.length <= 2 * padLength) {
        return [];
    }

    let startPad = signal.slice(0, padLength);
    startPad.reverse();
    let endPad = signal.slice(signal.length - padLength);
    endPad.reverse();

    let extendedSignal = startPad.concat(signal, endPad);
    let filteredSignal = new Array(extendedSignal.length).fill(0);

    let maxOrder = Math.max(numeratorCoeffs.length, denominatorCoeffs.length);
    let extendedLength = signal.length + 2 * padLength;

    // Forward filtering
    for (let i = maxOrder; i < extendedLength; i++) {
        let numeratorSum = 0;
        for (let j = 0; j < numeratorCoeffs.length; j++) {
            numeratorSum += numeratorCoeffs[j] * extendedSignal[i - j];
        }
        let denominatorSum = 0;
        for (let j = 1; j < denominatorCoeffs.length; j++) {
            denominatorSum += denominatorCoeffs[j] * filteredSignal[i - j];
        }
        filteredSignal[i] = (numeratorSum - denominatorSum) / denominatorCoeffs[0];
    }

    // Reverse filtering
    filteredSignal.reverse();
    extendedSignal = filteredSignal.slice();

    for (let i = maxOrder; i < extendedLength; i++) {
        let numeratorSum = 0;
        for (let j = 0; j < numeratorCoeffs.length; j++) {
            numeratorSum += numeratorCoeffs[j] * extendedSignal[i - j];
        }
        let denominatorSum = 0;
        for (let j = 1; j < denominatorCoeffs.length; j++) {
            denominatorSum += denominatorCoeffs[j] * filteredSignal[i - j];
        }
        filteredSignal[i] = (numeratorSum - denominatorSum) / denominatorCoeffs[0];
    }
    let result = filteredSignal.slice(padLength, padLength + signal.length);
    result.reverse();
    const end = performance.now()
    window.printPerformance(`filtfilt Filter took:`,`${(end-start).toFixed(2)}ms`);
    return result;
};

export const adaptiveFilter = (fs, mainsFreq, inputSignal) => {
    const start = performance.now()
    let filteredSignal = new Array(inputSignal.length);
    let x = new Array(3).fill(0);
    let y = new Array(3).fill(0);
    let e = new Array(3).fill(0);

    let normalizedMainsFreq = mainsFreq / fs;
    let coeff = 2.0 * Math.cos(2.0 * Math.PI * normalizedMainsFreq);

    // stepSize is calculated so that the filter adapts and converges in about the same time,
    // independent of fs and mainsFreq. Takes about 1sec to remove 0.8mV of mains noise
    let stepSize = 7200.0 * normalizedMainsFreq / fs;

    for (let i = 0; i < inputSignal.length; i++) {
        let inputVal = inputSignal[i];
        x[0] = inputVal;
        e[0] = coeff * e[1] - e[2];
        y[0] = inputVal - e[0];

        let diff = (x[0] - e[0]) - (x[1] - e[1]);
        e[0] += (diff > 0.0) ? stepSize : -stepSize;

        for (let j = 1; j >= 0; j--) {
            x[j + 1] = x[j];
            y[j + 1] = y[j];
            e[j + 1] = e[j];
        }

        filteredSignal[i] = y[0];
    }
    const end = performance.now()
    window.printPerformance(`adaptiveFilter Filter (${fs}) took:`,`${(end-start).toFixed(2)}ms`);
    return filteredSignal;
};


export function jAdaptiveFilter(fs, mainsFreq, inputSignal) {
    const start = performance.now()
    let filteredSignal = new Array(inputSignal.length);

    let h1 = 0.0;
    let h2 = 0.0;
    let x1 = 0.0;          // previous input
    let y1 = 0.0;          // previous filter output value
    const coeffQ = 2.0 * Math.cos(2.0 * Math.PI * mainsFreq / fs);
    const stepsize = 8.0;

    // A slope exceeding 800 uV / 2 msec should not be physiological signal, so skip adaption too
    const slopeThreshold = 800.0 * 500.0 / fs;

    for (let i = 0; i < inputSignal.length; i++) {
        let inputVal = inputSignal[i];
        let h00 = coeffQ * h1;
        let h0 = h00 - h2;
        let y0l = (inputVal - (h0 / 16.0));

        if (Math.abs(inputVal - x1) < slopeThreshold) {
            if (y0l > y1) {
                h0 += stepsize;
            } else {
                h0 -= stepsize;
            }
        }

        h2 = h1;
        h1 = h0;
        y1 = y0l;
        x1 = inputVal;

        filteredSignal[i] = y0l;
    }
    const end = performance.now()
    window.printPerformance(`jAdaptiveFilter Filter (${fs}) took:`,`${(end-start).toFixed(2)}ms`);
    return filteredSignal;
}

export const butterworthHighpassFiltfilt = (fc, fs, signal) => {
    const start = performance.now()
    let period = 1 / fs;
    let fcRadians = 2 * Math.PI * fc;
    let v = 2 * Math.tan(fcRadians * period / 2);
    let b0 = 2 / (2 + v);
    let b1 = -2 / (2 + v);
    let a0 = 1;
    let a1 = (v - 2) / (2 + v);
    const end = performance.now()
    window.printPerformance(`butterworthHighpassFiltfilt Filter (${fc}) took:`,`${(end-start).toFixed(2)}ms`);
    return filtfilt([b0, b1], [a0, a1], signal);
}

export const butterworthLowpassFiltfilt = (fc, fs, signal) => {
    const start = performance.now()
    let period = 1 / fs;
    let fcRadians = 2 * Math.PI * fc;
    let v = 2 * Math.tan(fcRadians * period / 2);
    let b0 = v / (2 + v);
    let b1 = v / (2 + v);
    let a0 = 1;
    let a1 = (v - 2) / (2 + v);
    const end = performance.now()
    window.printPerformance(`butterworthLowpassFiltfilt Filter (${fc}) took:`,`${(end-start).toFixed(2)}ms`);
    return filtfilt([b0, b1], [a0, a1], signal);
};


export const defaultFilterECG = (fs, mainsFreq, ecgLeads) => {

    const start = performance.now()
    const leadData = {};
    for (let [lead, samples] of Object.entries(ecgLeads)) {
      leadData[lead] = adaptiveFilter(fs, mainsFreq, samples);
      leadData[lead] = butterworthLowpassFiltfilt(40, fs, leadData[lead]);
      leadData[lead] = butterworthHighpassFiltfilt(0.5, fs, leadData[lead]);
    }
    const end = performance.now()
    window.printPerformance("Default ECG Filters took:",`${(end-start).toFixed(2)}ms`);
    return leadData;
  };
  