/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FullWidthECGPreview.module.css'
import Beat from './Beats';
import Grid from './Grid'
import Waveform from './Waveform'
import * as Lib from "../../constants/annotator"

const FullWidthECGPreview = (props) => {
    const {
        ecgTitle = null,
        seconds = 30,
        width = seconds*Lib.PX_PER_SECOND,
        height = Lib.MV_BANDWIDTH * Lib.PX_PER_MV,
        scale = 1,
        heightFactor = 1,
        inverted = false,
        mainsFreq = 60,
        sample = [],
        style = null,
        enableBeats = false,
        beats =[],
        mvSwing = 1,
        onClick,
        renderTimeScaleFactor = null,
        frequency = 300,
        offsetGrid = false,
        beatLabel = [{name: "Normal", color: "#6CCA6E", slug: "n"}]
    } = props
    
    return (
        <div style={{height:`${height*heightFactor * scale}`,...style}} onClick={()=>onClick()} className={styles.FullWidthECGPreviewContainer}>
            <svg width={width * scale} height={`${height*heightFactor * scale}px`} className={styles.previewGrid}>
                {ecgTitle.includes("-syn") && <rect x="4" y="4" width={`${60*scale}`} height={`${20*scale}`} style={{"stroke":"black","strokeWidth":2,"fillOpacity":0,"strokeOpacity":0.5}} />}
                {ecgTitle && <text x="9" y={`${19*scale}`} className={styles.LeadTitle} style={{fontSize: `${15*scale}px`}}>{ecgTitle}</text>}
                {enableBeats && beats != [] && beats.map(beat => 
                    <Beat 
                        beatLabel={beatLabel}
                        key ={beat.i}
                        capsule={false}
                        height={height*heightFactor * scale}
                        samples={sample}
                        inverted={inverted}
                        index={beat.i}
                        type={beat.t}
                        mvSwing={mvSwing}
                        frequency={frequency}
                        scale={scale}
                    />
                )}
                <g transform="translate(0,0)">
                <Waveform
                    capsule={false}
                    height={height*heightFactor * scale}
                    samples={sample}
                    inverted={inverted}
                    mainsFreq={mainsFreq}
                    mvSwing={mvSwing}
                    frequency={frequency}
                    waveformStrokeColor={ecgTitle.includes("-syn") ? "#000" : "#bb2739"}
                    scale={scale}
                />
                <Grid
                    renderSmallGrid={true}
                    rowSeconds={seconds}
                    stripWidthPx={width * scale}
                    height={height*heightFactor * scale}
                    renderTimeScaleFactor={renderTimeScaleFactor}
                    offsetGrid={offsetGrid}
                    scale={scale}
                />
                </g>
            </svg>
        </div>
    )
}

export default FullWidthECGPreview
