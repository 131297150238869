import axios from 'axios'
import { BASE_URL, METHODS } from './constants'

export default axios.create({
  baseURL: BASE_URL,
})

// Use axiosPrivate instance whenever the request the Authorization Bearer token
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-type': 'application/json',
  },
  // withCredentials: true
})
 

export const request = async (config) => {
  const { axiosInstance = axiosPrivate, path, method = METHODS.GET, signal, data, params } = config
  return await axiosInstance[method.toLowerCase()](path, data, params, signal)
}