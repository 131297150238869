import { styled } from '@mui/material'
import { GridActionsCellItem as MuiGridActionsCellItem } from '@mui/x-data-grid-premium'

const StyledGridActionsCellItem = styled(MuiGridActionsCellItem, {
  shouldForwardProp: (prop) => prop !== 'fontSize' && prop !== 'padding',
})(({ padding = '.65em', fontSize = '1.5rem' }) => ({
  padding,
  color: 'inherit',
  '& .MuiSvgIcon-root': {
    fontSize,
  },
}))


export default StyledGridActionsCellItem
