import { styled } from '@mui/material'

export const Spinner = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'loading' && prop !== 'size' && prop !== 'width',
})(({ theme, loading, size = '8rem', width = '4px' }) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:before': {
    content: "''",
    position: 'absolute',
    width: size,
    height: size,
    borderRadius: '50%',
    border: `${width} solid ${theme.palette.primary.main}`,
    opacity: loading ? '.5' : 0,
    transition: 'opacity .5s linear',
    borderBottomColor: 'transparent',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: loading ? 'spin 1s linear infinite' : 'none',
  },
}))

export const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}))

export const generateAnimatedIcon = (icon) => styled(icon, {
  shouldForwardProp: (prop) => prop !== 'animate',
})(({ animate }) => ({
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  animation: animate ? 'spin .5s infinite ease-in-out' : 'none',
}))
