import React from 'react'

// MUI Components
import TaskIcon from '@mui/icons-material/Task';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Constants
import ROUTES from '../../route/routes'
import CONSTANTS from '../../constants'

const SIDE_NAV_CONFIG = [
  {
    to: ROUTES.ALL.TASK_LIST,
    displayName: CONSTANTS.NAVIGATION_DISPLAY_NAME.TASK_LIST,
    icon: <TaskIcon color="primary" />,
  },
  {
    to: ROUTES.ALL.OPEN_ECG,
    displayName: CONSTANTS.NAVIGATION_DISPLAY_NAME.OPEN_ECG,
    icon: <OpenInNewIcon color="primary" />,
  },
  {
    to: ROUTES.ALL.UPLOAD_TASK,
    displayName: CONSTANTS.NAVIGATION_DISPLAY_NAME.UPLOAD_TASK,
    icon: <UploadFileIcon color="primary" />,
  },
]

export default SIDE_NAV_CONFIG
