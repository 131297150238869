const PROTECTED_ROUTES = {
  ROOT: '/',
  FORBIDDEN: '/forbidden',
  TASK_LIST: '/tasks',
  ANNOTATOR: '/annotator/:task_id/:ecg_id',
  OPEN_ECG: '/open',
  UPLOAD_TASK: '/upload',
}

const PUBLIC_ROUTES = {
  LOGIN: '/login',
}

const CUSTOM_PROTECTED_ROUTES = {
  MAIN_TOOL: '/tool/:task_id/:ecg_id',
}

const ROUTES = {
  PROTECTED: PROTECTED_ROUTES,
  PUBLIC: PUBLIC_ROUTES,
  CUSTOM_PROTECTED: CUSTOM_PROTECTED_ROUTES,
  ALL: { ...PUBLIC_ROUTES, ...PROTECTED_ROUTES, ...CUSTOM_PROTECTED_ROUTES }
}
export default ROUTES
