/* eslint-disable */
import React, { useEffect, useState } from 'react'
import styles from './Interval.module.css'
import { updateContext, updateGhostInterval, updateMouse, removeIntr, updateIntrContext } from '../../AnnotatorSlice'
import { useDispatch, useSelector } from 'react-redux';

const Interval = (prop) => {
    const dispatch = useDispatch()
    const [showMs, setShowMs] = useState(false)
    const IntervalRythms = useSelector(state => state.Annotator.annotations.labels.intervals);
    const fq = useSelector(state => state.Annotator.filtered.frequency);
    const {
        coordinates = [0, 0, 0, 0],
        x = coordinates[0] > coordinates[2] ? coordinates[2] : coordinates[0],
        y = coordinates[1],
        x2 = coordinates[0] < coordinates[2] ? coordinates[2] : coordinates[0],
        y2 = coordinates[3],
        type,
        index,
        interval,

        rowHeight,
        
        data = [],

    } = prop

    const IntervalRythmType = IntervalRythms.find(rm => rm.id === type)

    const IntervalTypes = {
        ghost: {
            color: '#6387C5',
            opacity: '0.55'
        },
        normal: {
            color: '#6387C5',
            opacity: '0.25'
        },
        type1: {
            color: '#add8e6',
            opacity: '0.25'
        },
        type2: {
            color: '#F0E68C',
            opacity: '0.25'
        },
    }

    // (x,y)======================
    //  ||                      ||
    //  i1    Interval Block    i2
    //  ||                      ||
    //  =====================(x2,y2)

    // console.log("rendering interval => ",prop)

    const editIntr = () => {
        dispatch(updateIntrContext({
            show: true,
            top: y,
            left: x,
            index: index,
            currentType: null,
            intrData: interval,
        }))
    }

    const deleteIntr = () => {
        dispatch(removeIntr(index))
    }
    return (
      <svg id={index}>
        {/* <rect x={x} y={y} width={Math.abs(x - x2)} height={Math.abs(y - y2)} fill={IntervalTypes[type].color} fillOpacity={IntervalTypes[type].opacity}/> */}
        {data.map((d) => (
          <rect
            key={JSON.stringify(d)}
            x={d[0]}
            y={d[1]}
            width={Math.abs(d[0] - d[2])}
            height={Math.abs(d[1] - d[3])}
            fill={"#6387C5"}
            fillOpacity={"0.25"}
          />
        ))}

        {type.name != "ghost" && (
          <text
            x={data[data.length - 1][2] - 25}
            y={data[data.length - 1][3] - 15}
            onMouseDown={deleteIntr}
            style={{ zIndex: "999" }}
            className={styles.LeadTitleDelete}
          >
            [x]
          </text>
        )}
        {type.name != "ghost" && (
          <text
            x={data[0][0] + 5}
            y={data[0][1] + 15}
            onMouseDown={editIntr}
            className={styles.LeadTitle}
          >
            {!type.name ? "Select..." : type.name} &#x25BC;{" "}
          </text>
        )}
        {interval?.bpm && type.name != "ghost" && (
          <text
            x={data[0][0] + 5}
            y={data[0][3] - 30}
            className={styles.LeadTitle}
            style={{ zIndex: "-999" }}
            onMouseDown={() => setShowMs(!showMs)}
          >
            {interval?.bpm?.average} ({interval?.bpm?.min}-{interval?.bpm?.max})
            BPM
          </text>
        )}
        {(interval?.time?.end - interval?.time?.start) * 1000 > 800 &&
          type.name != "ghost" && (
            <text
              x={data[0][0] + 5}
              y={data[0][3] - 10}
              className={styles.LeadTitle}
              style={{ zIndex: "-999" }}
              onMouseDown={() => setShowMs(!showMs)}
            >
              {showMs
                ? `${interval.time.start}s - ${interval.time.end}s`
                : `${((interval.time.end - interval.time.start) * 1000).toFixed(
                    2
                  )}ms`}
            </text>
          )}
      </svg>
    );
}

export default Interval
