/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './IntervalContextMenu.module.css'
import { addBeat, updateContext, updateMouse, updateGhostBeat, updateIntr, updateIntrContext } from '../../AnnotatorSlice'

const IntervalContextMenu = (props) => {
    const dispatch = useDispatch()
    const IntervalRythms = useSelector(state => state.Annotator.annotations.labels.intervals);
    const IntervalContextData = useSelector((state) => state.Annotator.intrervalMenu)

    const {
        top = 0,
        left = 0,
        index = null,
        currentType = null,
        intrData = null,
    } = IntervalContextData

    const modifyInterval = (type) => {
        let modData = intrData
        dispatch(updateIntr({index, value:{...modData, type}}))
        dispatch(updateIntrContext({
            show: false,
            top: 0,
            left: 0,
            index: null,
            currentType: null,
            intrData: null,
        }))
        dispatch(updateMouse({
            mousedown: false,
            mouseDownOnBeat: false,
            posX: null,
            posY: null,
            iStart: null,
            bStart: null,
            dragging: null,
        }))
    }

    return (
        <div
            id="IntervalContextMenu"
            style={{ top: top + 90, left }}
            className={styles.dropdownContent}
            onContextMenu={(e) => { e.preventDefault() }}
        >
            {/* <a href="#" onClick={() => { modifyInterval("normal") }}>normal</a>
            <a href="#" onClick={() => { modifyInterval("type1") }}>type1</a>
            <a href="#" onClick={() => { modifyInterval("type2") }}>type2</a> */}
            {IntervalRythms && IntervalRythms.map(rythm => {
                return (
                    <a href="#" key={rythm.id} onClick={() => { modifyInterval(rythm) }}>{rythm.name}</a>
                )
            })
            }

        </div>
    )
}

export default IntervalContextMenu
