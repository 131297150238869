import React from 'react'

// MUI Component
import Typography from '@mui/material/Typography'

const SampleText = ({ children, fontSize }) => {
  return (
    <Typography
      sx={{
        fontSize: `max(${fontSize || '15vw' }, 100px)`,
        fontWeight: 700,
        color: '#E1E1E1',
        textAlign: 'center',
        wordBreak: 'break-all',
        wordWrap: 'break-word',
      }}
    >
      {children}
    </Typography>
  )
}

export default SampleText
