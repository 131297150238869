// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactJson from 'react-json-view'
import Button from '@mui/material/Button'
import { useTheme } from '@emotion/react'


const JsonViewer = ({data}) => {
    const dispatch = useDispatch()
    const theme = useTheme()  
	const AnnotatorData = useSelector((state) => state.Annotator)

    function download(jsonData) {
        let dataStr = JSON.stringify(jsonData);
        let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        let exportFileDefaultName = new Date().toISOString() + '.json';
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        document.body.appendChild(linkElement); // Required for Firefox
        linkElement.click();
        document.body.removeChild(linkElement); // Remove the link element
    }
    
    
    return (
        <div style={{height: "100%", width: "100%", overflow:"scroll"}}>
            <ReactJson
            src={data}
            theme={theme.palette.mode === "light" ? "summerfruit:inverted" : "summerfruit"}
            style={{backgroundColor: "#ffffff00"}}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={1}
            name={false}
            iconStyle="square"
            indentWidth={2}
            />

            <Button
                variant="contained"
                sx={{ whiteSpace: 'nowrap', marginRight: theme.spacing(1), marginTop: theme.spacing(3)  }}
                size="large"
                onClick={() => download(data)}
            >
                Download JSON
            </Button>
            <Button
                variant="contained"
                sx={{ whiteSpace: 'nowrap', marginRight: theme.spacing(1), marginTop: theme.spacing(3)  }}
                size="large"
                onClick={() => download(AnnotatorData.annotations)}
            >
                Download Annotations
            </Button>

            {/* <Button
                variant="contained"
                sx={{ whiteSpace: 'nowrap', marginRight: theme.spacing(1), marginTop: theme.spacing(3)  }}
                size="large"
                onClick={() => console.log("Clear")}
            >
                Clear
            </Button> */}
        </div>
    );
}

export default JsonViewer