// import lib(s)
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./TaskList.module.css"

// @mui
import { GridActionsCellItem } from "@mui/x-data-grid-premium"

// Custom component
import { KcTable } from "../../components/common/kc-table"
import {
	SUPPORTED_LOCALE,
	LOCALE_DEFAULT_TEXT,
	IconComboCellRenderer,
} from "../../components/common/kc-table"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"

import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"

import { METHODS, errorHandler } from "../../hooks/useFetch"

import { API_URL } from "../../api/constants"

import { useNavigate } from "react-router-dom"
import ROUTES from "../../route/routes"
import { CONST_VARIANT } from "../../components/common/kc-table/constants"

import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/ModeEditOutlined"

import { setRawData, setCurrentTask, setCurrentTaskRecordings, setCurrentRecordingId, init, setAnnotatorState } from '../Annotator/AnnotatorSlice'
import universalFormatter from "../../utils/universalFormatter"

const TaskList = () => {
    
	useDocumentTitle(PAGE_TITLE.TASK_LIST)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [tasks, setData] = useState(null)


	const axiosPrivate = useAxiosPrivate()

	const fetchData = async () => {
		const PATH = API_URL.TASKS
		try {
			const response = await axiosPrivate[METHODS.GET.toLowerCase()](PATH)
			if (response.data.length === 0) {
				toast.error("No Tasks Found")
				throw new Error("No Tasks Found")
			}
			setData(response.data)
			setError(null)
		} catch (error) {
			setError(errorHandler(error))
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
        window.sidebar.open()
		// eslint-disable-next-line
	}, [])

	const renderDateCell = (params) => {
		const { value } = params
		const date = new Date(value)

		return (
			<div onClick={() => renderTask(params)} className={styles.rowClass}>
				{date.toLocaleDateString()} {date.toLocaleTimeString()}
			</div>
		)
	}

	const renderCell = (params) => {
		const { value } = params

		return (
			<div onClick={() => renderTask(params)} className={styles.rowClass}>
				{value}
			</div>
		)
	}

	const columns = [
		{ field: "name", headerName: "Task Name", renderCell, minWidth: 500, flex: 1 },
		{ field: "type", headerName: "Task Type", renderCell,  minWidth: 200, flex: 1 },
		{
			field: "createdAt",
			headerName: "Created at",
			minWidth: 100,
			renderCell: renderDateCell,
			flex: 1,
		},
		{
			field: "updatedAt",
			headerName: "Updated at",
			minWidth: 100,
			renderCell: renderDateCell,
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 100,
			disableExport: true,
			filterable: false,
			renderCell: (params) => {
				const { id } = params
				return (
					<GridActionsCellItem
						sx={{
							padding: 1.25,
							color: "inherit",
							"& .MuiSvgIcon-root": {
								fontSize: "1.6rem",
							},
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							zIndex: 999,
						}}
						icon={<DeleteIcon />}
						label="VIEW"
						key={`${id}-VIEW`}
						onClick={() => deleteTask(params.row.id)}
						color="inherit"
					/>
				)
			},
		},
	]

	const deleteTask = async (id) => {
		setLoading(true)
		try {
			const { data: CurrentTask } = await axiosPrivate[METHODS.DELETE.toLowerCase()](`task?task_id=${id}`)
			console.log(CurrentTask);
			toast.success("Task Deleted Successfully");
			setError(null)
		} catch (error) {
			toast.error("Error fetching Task Recordings")
			console.log(error);
			setError(errorHandler(error))
		} finally {
			fetchData()
		}
	}

	const LOCALE = {
		...LOCALE_DEFAULT_TEXT.enUS,
		noRowsLabel: "No Facilities Found...",
	}

	const renderTask = async (data) => {
		const { row } = data
		dispatch(init())
		dispatch(setCurrentTask(row))
		setLoading(true)
		try {
			// Fetch All recordingList of this Task
			const start = performance.now();
			const { data: TaskRecordingList } = await axiosPrivate[METHODS.GET.toLowerCase()](`recordings?task_id=${row.id}`)
			if (TaskRecordingList.length === 0) {
				toast.error("No Recordings Found")
				return
			}
			dispatch(setCurrentTaskRecordings(TaskRecordingList))

			// Fetch Recording Data of first recording
			const { data: RecordingData } = await axiosPrivate[METHODS.GET.toLowerCase()](`recording?recording_id=${TaskRecordingList[0].id}`)
			dispatch(setRawData(RecordingData))
			dispatch(setCurrentRecordingId(TaskRecordingList[0].id))
			console.log(RecordingData);
			let uniEcg;
			if (RecordingData.data.hasOwnProperty("type")) {
				uniEcg = universalFormatter(RecordingData.data, RecordingData.data.type);
			} else {
				uniEcg = universalFormatter(RecordingData.data, "turk");
			}
			if (uniEcg.error !== null) { toast.error(uniEcg.error); return; }
			console.log(uniEcg);
			const leadCount = Object.keys(uniEcg.filtered.samples).length
			const end = performance.now();
			// Calculate the elapsed time
			const elapsed = end - start;
			console.log(`Opening took ${elapsed} millisecond`);
			toast.success(`Loaded ${leadCount}L Turk Recording in ${elapsed.toFixed(1)}ms!`)
			dispatch(setAnnotatorState({ ...uniEcg, leadCount }))
			window.sidebar.close()
			setLoading(false)
			navigate(`/tool/${row.id}/${TaskRecordingList[0].id}`)

			setError(null)
		} catch (error) {
			toast.error("Error fetching Task Recordings")
			console.log(error);
			setError(errorHandler(error))
		} finally {
			setLoading(false)
		}
	}

	return (
		<Layout.CenterWrapper>
			{loading || error?.status === 421 ? (
				<Loader />
			) : error ? (
				<PlaceholderText>Error</PlaceholderText>
			) : (
				<React.Fragment>
					<div className={styles.gridPanel}>
						<KcTable
							initialState={{
								sorting: {
									sortModel: [{ field: "createdAt", sort: "desc" }],
								},
							}}
							rows={tasks}
							columns={columns}
							defaultLocaleLanguage={SUPPORTED_LOCALE.English}
							defaultLocaleText={LOCALE}
							pagination
							layout={CONST_VARIANT.KC_DEFAULT}
							disableSelectionOnClick
							// disableDensitySelector
							// disableColumnFilter
							// disableColumnSelector
							// disableTableExport

							// getRowClassName={(params) => {return styles.rowClass}}
							autoHeight={tasks.length <= 10}
						/>
					</div>
				</React.Fragment>
			)}
		</Layout.CenterWrapper>
	)
}

export default TaskList
