import React from 'react'

// MUI components
import LockIcon from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'
import { CONTENT } from '../../constants/constants'
import Layout from '../../layouts/common/Layout'

const Forbidden = () => {
  const { forbidden } = CONTENT
  return (
    <Layout.CenterWrapper>
      <LockIcon style={{ fontSize: '10em' }} />

      <Typography variant="h2">{forbidden.title}</Typography>
      <Typography my={2}>{forbidden.subtitle}</Typography>
    </Layout.CenterWrapper>
  )
}

export default Forbidden
