import { useState, useEffect } from 'react'
import { METHODS } from '../api/constants'

function errorHandler(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      ...error.response.data,
      status: error.response.status,
    }
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      status: 421,
    }
  }
  // Something happened in setting up the request that triggered an Error
  // console.log('Error', error.message);
  return {
    status: 1,
  }
}

const useFetchFunction = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [controller, setController] = useState()

  const axiosFetch = async (config) => {
    const {
      axiosInstance,
      method = METHODS.GET,
      path,
      requestConfig = {},
    } = config

    try {
      setLoading(true)
      const ctrl = new AbortController()
      setController(ctrl)
      const response = await axiosInstance[method.toLowerCase()](path, {
        ...requestConfig,
        signal: ctrl.signal,
      })
      setData(response.data)
      setError(null)
    } catch (error) {
      setError(errorHandler(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      controller && controller.abort()
    }
    //eslint-disable-next-line
  }, [controller])

  return [loading, error, data, axiosFetch]
}

export default useFetchFunction
