import React from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

const LinkButton = ({ to, variant = 'contained', children }) => {
  return (
    <Button
      variant={variant}
      sx={{ whiteSpace: 'nowrap' }}
      LinkComponent={Link}
      to={to}
      size="large"
    >
      {children}
    </Button>
  )
}

export default LinkButton
