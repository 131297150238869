import jwtDecode from 'jwt-decode'

export const getInitials = (firstName = '', lastName = '') =>
  `${firstName[0]}${lastName[0]}`.toLocaleUpperCase()

export const parseJWT = (jwt) => {
  return jwtDecode(jwt)
}

export const parseUserInfo = (token) => {
  if (token) {
    const {
      given_name: firstName,
      family_name: lastName,
      picture: picture,
      email_verified: emailVerified,
      hd: emailDomain,
      email: email,
      exp: exp,
    } = parseJWT(token)
    return {
      firstName,
      lastName,
      emailVerified,
      picture,
      token,
      emailDomain,
      email,
      exp,
      permissions: {
        isAdmin: true,
        isPhysician: true,
      },
    }
  }
  return null
}

export const isScrollable = function (ele) {
  // Compare the height to see if the element has scrollable content
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight

  // It's not enough because the element's `overflow-y` style can be set as
  // * `hidden`
  // * `hidden !important`
  // In those cases, the scrollbar isn't shown
  const overflowYStyle = window.getComputedStyle(ele).overflowY
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

  return hasScrollableContent && !isOverflowHidden
}

export async function loadDefaultFonts() {
  try {
    const font300 = await import(`@fontsource/roboto/300.css`)
    const font400 = await import(`@fontsource/roboto/400.css`)
    const font500 = await import(`@fontsource/roboto/500.css`)
    const font700 = await import(`@fontsource/roboto/700.css`)
    font300.default()
    font400.default()
    font500.default()
    font700.default()
  } catch (error) {
    console.error(error)
  }
}

export const charSet = {
  ALPHANUMERIC: 'alphanumeric',
  ALPHA: 'alpha',
  NUMERIC: 'numeric',
  ALPHA_UPPER: 'alphaUpper',
  ALPHA_LOWER: 'alphaLower',
}
export const guid = (len = 32, type = 'alphanumeric') => {
  const CHAR_SET_STRING = {
    alphanumeric:
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    alpha: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    alphaUpper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    alphaLower: 'abcdefghijklmnopqrstuvwxyz',
    numeric: '0123456789',
  }

  let code = []
  const charSetString = CHAR_SET_STRING[type] || CHAR_SET_STRING.alphanumeric

  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * charSetString.length)
    code.push(charSetString.charAt(index))
  }
  code = code.join('')
  return code
}

/**
 * Validation function
 * @param {*} validationRule // function | regEx
 * @param {*} value
 * @returns
 */
const validateFun = (validationRule, value) => {
  if (typeof validationRule === 'function') {
    return validationRule(value)
  }
  if (typeof validationRule === 'object') {
    return validationRule.test(value)
  }
  return true
}

// to validate list of validations
export const validate = (value, validators = []) => {
  let isValid = false
  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i]
    isValid = validateFun(validator, value)
    if (isValid !== true) break
  }
  return isValid
}

export const mask = (value, maskWith = '*', maxLength = Infinity,) =>
value ? value.substring(0, maxLength).replace(/./g, maskWith) : ''
