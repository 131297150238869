import { useSelector } from 'react-redux'
import { authActions, selectAuth } from '../slices/auth.slice'

/**
 * auth hook provider the access to auth state and actions
 * @returns [authState, loginAction, logoutAction]
 */
const useAuth = () => {
  // auth state
  const auth = useSelector(selectAuth)

  // login action which receives user payload
  const login = authActions.login

  // logout action - no payload is needed
  const logout = authActions.logout

  return { auth, login, logout }
}

export default useAuth
