// import lib(s)
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./Annotator.module.css"
import { useTheme } from "@emotion/react"

import ROUTES from "../../route/routes"
import { useNavigate, useParams } from "react-router-dom"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"

import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Grid } from "@mui/material"
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import copy2cliboard from "../../utils/copy2cliboard"
import {MorphologyLabels, RhythmLabels} from "../../utils/RhythmMorphology"
import JsonViewer from "./views/JsonView"
import TaskRecordings from "./TaskRecordings"

import { METHODS, errorHandler } from "../../hooks/useFetch"

import { removeBeat, setCurrentTaskRecordings } from './AnnotatorSlice'
import Chip from "@mui/material/Chip"
import { grey } from "@mui/material/colors"

const OverreadPanel = () => {

	const dispatch = useDispatch()
    const navigate = useNavigate()
	const urlParams = useParams()
	const theme = useTheme()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [AlgAnnotations, setAlgAnnotations] = useState({})
	const [AlgDet, setAlgDet] = useState([])

    const [MVals, setMVals] = useState ({0:false,1:false,2:false,3:false,4:false,5:false,6:false,7:false,8:false,9:false,10:false,11:false,12:false,13:false,14:false,15:false,16:false,17:false,18:false,19:false,20:false})
    const [RVals, setRVals] = useState ({0:false,1:false,2:false,3:false,4:false,5:false,6:false,7:false,8:false,9:false,10:false,11:false,12:false,13:false})
    const [MRAlgo, setMRAlgo] = useState ("") //Asymptomatic or Symptomatic

    const [NeedFollowup, setNeedFollowup] = useState(false)
    const [ShowFollowup, setShowFollowup] = useState(false)

    const [Comment, setComment] = useState("")

	const axiosPrivate = useAxiosPrivate()


	const isTurkTask = !(urlParams["task_id"] === "open");

	const FileRawData = useSelector((state) => state.Annotator.rawData)
	const Annotator = useSelector((state) => state.Annotator)
	const auth = useSelector((state) => state.auth)

	const kai12 = Annotator.rawData.data.kai12Analysis

	useEffect(() => {
	if(isTurkTask) {
		let tempAlgDet= []
		Annotator.annotations.algDet.map((alg) => {
			return Object.entries(alg).forEach(([key, value]) => (
				tempAlgDet.push(
					<Typography key={key} variant="p" component="div">
						<b>{key}</b> : {value}
					</Typography>
				)
			));
		})
		setAlgDet(tempAlgDet)
		let tempAlgAnnotations = {}
		Annotator.annotations.global.map((globalAnn) => {
			return Object.entries(globalAnn).forEach(([key, value]) => (
				tempAlgAnnotations[key] = value
			));
		})
		setAlgAnnotations(tempAlgAnnotations)
		setCurrentTab(1)
	} else {
		setCurrentTab(2)
	}
    if (Annotator.annotations.overreadMode) {
        let dx
        if (Annotator.annotations.confirmedDx) {
            dx = Annotator.annotations.confirmedDx
            setNeedFollowup(Annotator.annotations.NeedFollowup)
            setComment(Annotator.annotations.comment)
        } else {
            dx = Annotator.annotations.algStatement
        }
        const newM = {...MVals}
        dx.morphologyArray.map(m => newM[m] = true)
        setMVals(newM)
        const newR = {...RVals}
        dx.rhythmArray.map(r => newR[r] = true)
        setRVals(newR)
        setMRAlgo(dx.type)

        const leadNameArray = Object.keys(Annotator.filtered.samples)
        for (var i = 0; i < leadNameArray.length; i++) {
            if (leadNameArray[i].includes("-syn")) {
                setShowFollowup(true)
              break;
            }
        }
    }
	}, [])
	

	const copyCurrentUrl = () => {
		copy2cliboard(window.location.href);
		toast.success("Copied to clipboard!")
	}

	const onClickSave = async () => {
		setLoading(true)
		let data = JSON.parse(JSON.stringify(Annotator.rawData));
		data.data.annotator.push({
			time: new Date().toISOString(),
			person: auth.user.email,
		})
		data.data.annotationResults = {questionCorrect : true}
		// now write annotations
		// data.data.annotations.beatLabels
		// [{label:"n",sample:209,duration:1}]
		// TODO: Try to make this backward compatible 
		data.data.annotations.beatsV2 = Annotator.annotations.beats

		// ========================================================================
		// data.data.annotations.global
		// [{"hr":true}]
		let fGlobal = []
		Object.entries(AlgAnnotations).forEach(([key, value]) => (
			fGlobal.push({[key]: value})
		));
		data.data.annotations.global = fGlobal
		// ========================================================================
		// data.data.annotations.intervals
		// [{label:"Noisy",start:209,end:1}]
		// TODO: Try to make this backward compatible 
		data.data.annotations.intervalsV2 = Annotator.annotations.intervals
		// ========================================================================

        // ConfirmedDx
        if (data.data.annotations.overreadMode)
        {
            let morphologyArray = [], rhythmArray = []
            Object.keys(MVals).map(v => {
                if (MVals[v]) morphologyArray.push(v)
            })
            Object.keys(RVals).map(v => {
                if (RVals[v]) rhythmArray.push(v)
            })
            let morphology = morphologyArray.map((id) => MorphologyLabels[id])
            let rhythm = rhythmArray.map((id) => RhythmLabels[id])

            data.data.annotations.confirmedDx = { morphology, rhythm, type: MRAlgo, morphologyArray, rhythmArray }
            data.data.annotations.NeedFollowup = NeedFollowup
            data.data.annotations.comment = Comment

            data.data.annotator.pop()
            data.data.annotator.push({
                time: new Date().toISOString(),
                person: auth.user.email,
                message: "Confirmed Dx"
            })
        }

		// Modifying the left panel

		const NewRecordingArray = Annotator.currentTaskRecordings.map((recording) => {
			let newRecording = {...recording}
			if (recording.id === Annotator.currentRecordingId) {
				newRecording.annotationResults = {questionCorrect : true}
				newRecording.annotations = Annotator.annotations
				newRecording.annotator = {
					time: new Date().toISOString(),
					person: auth.user.email,
				}
			}
			return newRecording
		})

		try {
			const { data: postData } = await axiosPrivate[METHODS.PUT.toLowerCase()](`recording`, data)
			dispatch(setCurrentTaskRecordings(NewRecordingArray))
		} catch (error) {
			if (typeof error === "string") {
				toast.error(error)
			} else {
				toast.error("Error fetching Task Recordings")
			}
			console.log(error);
			setError(errorHandler(error))
		} finally {
			setLoading(false)
		}

		toast.success("Saved! Reopening Task...")
		window.loadTurkTask(urlParams["task_id"],urlParams["ecg_id"], false, false);
	}
	
	// Tab Logic
	const [currentTab, setCurrentTab] = React.useState(isTurkTask ? 1 : 2)
	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue)
	}

	return (
		<>
			{loading || error?.status === 421 ? (
				<Loader />
			) : error ? (
				<PlaceholderText>Error</PlaceholderText>
			) : (
				<React.Fragment>
					<CardContent sx={{padding: 1.5}}>
						<Grid container>
							{/* Header */}
							<Grid item xs={10}>
								<Typography variant="h5" sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} component="div">
									{isTurkTask ? Annotator.currentTask.name : `Local ${urlParams["ecg_id"].toUpperCase()} File`}
								</Typography>
								{isTurkTask && <Typography variant="p" py={1} component="div">
									Recording id: <b>{Annotator.currentRecordingId}</b>
								</Typography>}
								{isTurkTask  ? 
								<Link onClick={copyCurrentUrl}>{window.location.href}</Link>
							 	:
								 <Link>This ECG is not saved on Cloud</Link>
								}
		
							</Grid>
							<Grid item xs={12} py={1}>
								<Divider variant="middle" />
							</Grid>
                            <Grid item  alignItems="center" justifyContent="center" xs={12}>
                                <Typography variant="h5" color={Annotator.annotations.confirmedDx == null ? "grey" : "Green"} fontWeight={"bold"} sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} component="center">
                                    {Annotator.annotations.confirmedDx == null ? "Unconfirmed" : "Confirmed"}
                                </Typography>
                            </Grid>
							<Grid item xs={12} py={1}>
								<Divider variant="middle" />
							</Grid>
							{Annotator.leadCount == 12 && <>
								<Grid item xs={12} py={1}>
									<table style={{width:"100%"}}>
										<tbody>
										<tr>
											<td className={styles.title}>Heart Rate:</td>
											<td className={styles.text}>{kai12.hr} bpm</td>
										</tr>
										<tr>
											<td className={styles.title}>PR Interval:</td>
											<td className={styles.text}>{kai12.pr} ms</td>
										</tr>
										<tr>
											<td className={styles.title}>ORS Duration:</td>
											<td className={styles.text}>{kai12.qrs} ms</td>
										</tr>
										<tr>
											<td className={styles.title}>QT / QTcB / QTcF:</td>
											<td className={styles.text}>{kai12.qt} / {kai12.qtcb} / {kai12.qtcf} ms</td>
										</tr>
										<tr>
											<td className={styles.title}>P / QRS / T Axis:</td>
											<td className={styles.text}>{kai12.pax}° / {kai12.qax}° / {kai12.tax}°</td>
										</tr>
										</tbody>
									</table>
								</Grid>

								<Grid item xs={12}>
									<Divider variant="middle" />
								</Grid>
                                {Annotator.annotations.algStatement.type !== "" && <>
								<Grid item xs={12} py={1}>
                                    <Typography variant="h5" sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} component="div">
                                        Original Dx
                                    </Typography>
									<table style={{width:"100%"}}>
										<tbody>
										<tr>
											<td className={styles.text}>{Annotator.annotations.algStatement.type} selected</td>
										</tr>
										<tr>
											<td className={styles.title}>Rhythm:</td>
										</tr>
										{Annotator.annotations.algStatement.rhythm.map(t => (
											<tr key={t}>
												<td className={styles.text}>{t}</td>
											</tr>
										))}
										<tr>
											<td className={styles.title}>Morphology:</td>
										</tr>
										{Annotator.annotations.algStatement.morphology.map(t => (
											<tr key={t}>
												<td className={styles.text}>{t}</td>
											</tr>
										))}
										</tbody>
									</table>
								</Grid>
                                </>}
								<Grid item xs={12}>
									<Divider variant="middle" />
								</Grid>
							</>}
                            <Grid item xs={12} py={1}>
                                <Typography variant="h5" sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} component="div">
                                    Confirmed Dx
                                </Typography>
                            </Grid>
                            <Grid item xs={12} py={1}>
                                
                                <FormControl>
                                <RadioGroup
                                    row
                                    value={MRAlgo}
                                    onChange={(e)=>setMRAlgo(e.target.value)}
                                >
                                    <FormControlLabel value="Asymptomatic" disabled={Annotator.annotations.confirmedDx !== null} control={<Radio />} label="Asymptomatic" />
                                    <FormControlLabel value="Symptomatic" disabled={Annotator.annotations.confirmedDx !== null} control={<Radio />} label="Symptomatic" />
                                </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5} py={1}>
                                <Typography variant="h6" pb={1} component="div">
                                    <b>Morphology:</b>
                                </Typography>
                                <FormGroup>
                                    {Object.keys(MorphologyLabels).map((labels) => (
                                        <FormControlLabel
                                            key={labels}
                                            control={
                                                <Checkbox
                                                    checked={MVals[labels]}
													disabled={Annotator.annotations.confirmedDx !== null}
                                                    onChange={() => {setMVals({...MVals, [labels]: !MVals[labels]})}}
                                                    name={MorphologyLabels[labels]}
                                                />
                                            }
                                            label={MorphologyLabels[labels]}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={5} py={1}>
                                <Typography variant="h6" pb={1} component="div">
                                    <b>Rhythm:</b>
                                </Typography>
                                <FormGroup>
                                    {Object.keys(RhythmLabels).map((labels) => (
                                        <FormControlLabel
                                            key={labels}
                                            control={
                                                <Checkbox
                                                checked={RVals[labels]}
												disabled={Annotator.annotations.confirmedDx !== null}
                                                onChange={() => {setRVals({...RVals, [labels]: !RVals[labels]})}}
                                                    name={RhythmLabels[labels]}
                                                />
                                            }
                                            label={RhythmLabels[labels]}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                            {/* {ShowFollowup && <> */}
                            {false && <>
                            <Grid item xs={12} p={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={NeedFollowup}
                                        onChange={e => {setNeedFollowup(v => !v)}}
                                        />
                                    }
                                    label={"Do you require a follow up 12-lead?"}
                                />                                
                            </Grid>
                            </>}
                            <Grid item xs={12} p={2}>
								<TextField
									multiline
									fullWidth
									label="Comment"
									disabled={Annotator.annotations.confirmedDx !== null}
									rows={4}
									value={Comment}
									onChange={e => setComment(e.target.value)}
								/>
                            </Grid>
                            <Grid item xs={12} p={2}>
                                <Button
                                    variant="contained"
                                    sx={{ whiteSpace: "nowrap", width: "100%" }}
                                    size="large"
                                    onClick={() => {
                                        onClickSave()
                                    }}
									disabled={Annotator.annotations.confirmedDx !== null}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="middle" />
                            </Grid>
                            <Grid item xs={10} pb={2}>
                                <Typography variant="h6" py={1} component="div">
                                    Audit Log
                                </Typography>
                                {Annotator?.annotations?.audit &&
                                    Annotator?.annotations?.audit.map((log, i) => (
                                        <Typography key={i} variant="p" component="div">
                                            {new Date(log.time).toLocaleDateString()} - <b>{log.person}</b> {log.message ? `: ${log.message}` : ""}
                                        </Typography>
                                    ))}
                            </Grid>

						</Grid>
					</CardContent>
				</React.Fragment>
			)}
		</>
	)
}

export default OverreadPanel
