import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	rawData: {},
	currentTask: {},
	currentTaskRecordings: [],
	currentRecordingId: null,
	original: {
		frequency: 0,
		mainsFrequency: 0,
		gain: 0,
		duration: 0,
		samples: {},
		info: {},
	},
	filtered: {
		frequency: 0,
		mainsFrequency: 0,
		gain: 0,
		duration: 0,
		samples: {},
		filters: {
			adaptive: false,
			ButterworthLowPass: 0,
			ButterworthHighPass: 0,
			noSyn: true,
		},
		info: {},
	},
	annotations: {
		beats: [], // [offset, beatType]
		intervals: [],
		global: [],
		labels: {
			beats: [],
			intervals: [],
		},
		audit: [],
	},
	settings: {
		scale: 1,
		inverted: false,
	},
	leadCount: 0,
	selectedLead: null,
	context: {
		show: false,
		data: {
			top: 0,
			left: 0,
			showDelete: false,
			beatIndex: 0,
		},
	},
	intrervalMenu: {
		show: false,
		top: 0,
		left: 0,
		index: null,
		currentType: null,
		intrData: null,
	},
	ghostBeat: {
		show: false,
		coordinates: [0, 0],
		type: 0,
		index: "ghost-beat",
	},
	interval: {
		show: false,
		coordinates: [0, 0, 0, 0],
		type: 0,
		index: "ghost-interval",
	},
	highlight: {
		LeadData: null,
		centerIndex: null,
	},
	mouse: {
		mousedown: false,
		mouseDownOnBeat: false,
		mouseDownOnBeatType: null,
		posX: null,
		posY: null,
		iStart: null,
		bStart: null,
		dragging: null,
	},
}

export const Annotator = createSlice({
	name: "@Annotator",
	initialState,
	reducers: {
		init: (state, action) => {
			return initialState
		},
		setAnnotatorState: (state, action) => {
			return {
				...state,
				annotations: action.payload.annotations,
				filtered: {
					...state.filtered,
					...action.payload.filtered,
					filters: {
						...state.filtered.filters,
						...action.payload.filtered.filters,
					},
				},
				original: action.payload.original,
				leadCount: action.payload.leadCount,
			};
		},
		setFilteredSamples: (state, action) => {
			state.filtered.samples = action.payload
		},
		setFilters: (state, action) => {
			state.filtered.filters = action.payload
		},
		setRawData: (state, action) => {
			state.rawData = action.payload
		},
		setCurrentTask: (state, action) => {
			state.currentTask = action.payload
		},
		setCurrentTaskRecordings: (state, action) => {
			state.currentTaskRecordings = action.payload
		},
		setCurrentRecordingId: (state, action) => {
			state.currentRecordingId = action.payload
		},
		setScale: (state, action) => {
			state.settings.scale = action.payload
		},
		setInverted: (state, action) => {
			state.settings.inverted = action.payload
		},
		selectLead: (state, action) => {
			state.selectedLead = action.payload
		},
		deselectLead: (state, action) => {
			state.selectedLead = null
		},
		addBeat: (state, action) => {
			const { type, beatIndex } = action.payload
			state.annotations.beats.push({ i: beatIndex, t: type })
		},
		removeBeat: (state, action) => {
			state.annotations.beats = state.annotations.beats.filter((b) => b.i !== action.payload)
		},
		modifyBeat: (state, action) => {
			const { beatIndex, newValue } = action.payload
			state.annotations.beats = state.annotations.beats.map((beat) => (beat.i === beatIndex ? { i: newValue[0], t: newValue[1] } : beat))
		},
		addIntr: (state, action) => {
			state.annotations.intervals.push(action.payload)
		},
		removeIntr: (state, action) => {
			state.annotations.intervals = state.annotations.intervals.filter((b) => b.start !== action.payload)
		},
		updateIntr: (state, action) => {
			const { index, value } = action.payload
			state.annotations.intervals = state.annotations.intervals.map((intr) => (intr.start === index ? { ...intr, ...value } : intr))
		},
		updateIntrContext: (state, action) => {
			state.intrervalMenu = {
				...state.intrervalMenu,
				...action.payload,
			}
		},
		updateContext: (state, action) => {
			state.context = {
				...state.context,
				...action.payload,
			}
		},
		updateGhostBeat: (state, action) => {
			state.ghostBeat = {
				...state.ghostBeat,
				...action.payload,
			}
		},
		updateGhostIntr: (state, action) => {
			state.interval = {
				...state.interval,
				...action.payload,
			}
		},
		updateMouse: (state, action) => {
			state.mouse = {
				...state.mouse,
				...action.payload,
			}
		},
	},
})

export const {
	init,
	setAnnotatorState,
	setFilteredSamples,
	setFilters,
	setRawData,
	setCurrentTask,
	setCurrentTaskRecordings,
	setCurrentRecordingId,
	setScale,
	setInverted,
	selectLead,
	deselectLead,
	addBeat,
	removeBeat,
	modifyBeat,
	addIntr,
	removeIntr,
	updateIntr,
	updateIntrContext,
	updateContext,
	updateGhostBeat,
	updateGhostIntr,
	updateMouse,
} = Annotator.actions

export default Annotator.reducer
