/**
 * Library to calculate sample array of All leads.
 * @module leadCalc.js
 * @version 1.0.0
 * @author Kim Barnett
 * 
 * @param {object} ecg - ECG object with I, II, V1 (or) V2, V4 leads
 * 
 * @returns {object} leadData - Object with all leads
 * 
 * @example
 * const ecg = {
 *     I: [1, 2, 3, 4, 5],
 *    II: [1, 2, 3, 4, 5],
 *    V1: [1, 2, 3, 4, 5],
 *    V4: [1, 2, 3, 4, 5]
 * };
 * const leadData = calculateLeads(ecg);
 * console.log(leadData);
 *  {
 *  I: [1, 2, 3, 4, 5],
 *  II: [1, 2, 3, 4, 5],
 *  III: [0, 0, 0, 0, 0],
 *  aVR: [0, 0, 0, 0, 0],
 *  aVL: [0, 0, 0, 0, 0],
 *  aVF: [0, 0, 0, 0, 0],
 *  V1-syn: [0, 0, 0, 0, 0],
 *  V2: [1, 2, 3, 4, 5],
 *  V3-syn: [0, 0, 0, 0, 0],
 *  V4: [1, 2, 3, 4, 5],
 *  V5-syn: [0, 0, 0, 0, 0],
 *  V6-syn: [0, 0, 0, 0, 0],
 *  }
 * 
*/

import * as math from 'mathjs';
 
function FnWithPerformance (data) {
    const start = performance.now()
    const returnVal = calculateLeads(data)
    const end = performance.now()
    window.printPerformance("Calculating Leads took:",`${(end-start).toFixed(2)}ms`);
    return returnVal
}


const calculateLeads = (ecg) => {
    const { I: leadI, II: leadII, V1: leadV1, V2: leadV2, V3: leadV3, V4: leadV4, V5: leadV5, V6: leadV6 } = ecg;
    const leadData = {};

    // Calculate Leads III, aVR, aVL, aVF from Leads I, II
    if (leadI && leadII) {
        leadData['I'] = leadI;
        leadData['II'] = leadII;
        leadData['III'] = math.subtract(leadII, leadI);
        leadData['aVR'] = math.multiply(-0.5, math.add(leadI, leadII));
        leadData['aVL'] = math.subtract(leadI, math.multiply(0.5, leadII));
        leadData['aVF'] = math.subtract(leadII, math.multiply(0.5, leadI));
    }
    if (leadV1 && leadV2 && leadV3 && leadV4 && leadV5 && leadV6) {
        leadData['V1'] = leadV1;
        leadData['V2'] = leadV2;
        leadData['V3'] = leadV3;
        leadData['V4'] = leadV4;
        leadData['V5'] = leadV5;
        leadData['V6'] = leadV6;
        return leadData;
    }
    if (leadI && leadII && leadV2 && leadV4) {
        // Calculate Leads V1, V3, V5, V6 from Leads I, II, V2, V4
        const inputLeads = math.transpose(math.matrix([leadI, leadII, leadV2, leadV4]));

        const WLS4x4 = math.matrix([
            [-0.5863866, 0.12382167, 0.28485256, 0.39619061],
            [-0.12291817, -0.06476317, 0.30724672, 0.50331135],
            [0.4798221, 0.6032773, -0.13168489, -0.13499239],
            [-0.00942712, 0.6233477, 0.61597792, 0.23216768],
        ]);
        const vLeads = math.multiply(inputLeads, WLS4x4);
        const [rows, columns] = vLeads.size();
        leadData['V1-syn'] = vLeads.subset(math.index(math.range(0, rows), 0)).toArray().flat();
        leadData['V2'] = leadV2;
        leadData['V3-syn'] = vLeads.subset(math.index(math.range(0, rows), 1)).toArray().flat();
        leadData['V4'] = leadV4;
        leadData['V5-syn'] = vLeads.subset(math.index(math.range(0, rows), 2)).toArray().flat();
        leadData['V6-syn'] = vLeads.subset(math.index(math.range(0, rows), 3)).toArray().flat();

    } else if (leadI && leadII && leadV1 && leadV4) {
        // Calculate Leads V2, V3, V5, V6 from Leads I, II, V1, V4
        const inputLeads = math.transpose(math.matrix([leadI, leadII, leadV1, leadV4]));
        const WLS4x4 = math.matrix([
            [0.62950665, 0.47780008, 0.21043114, 0.31790918],
            [-0.13829798, -0.16529997, 0.33109493, 0.52642836],
            [1.44278785, 0.84251819, -0.18082757, -0.18753116],
            [0.31313108, 0.82497713, 0.57055053, 0.18659219],
        ]);
        const vLeads = math.multiply(inputLeads, WLS4x4);
        const [rows, columns] = vLeads.size();
        leadData['V1'] = leadV1;
        leadData['V2-syn'] = vLeads.subset(math.index(math.range(0, rows), 0)).toArray().flat();
        leadData['V3-syn'] = vLeads.subset(math.index(math.range(0, rows), 1)).toArray().flat();
        leadData['V4'] = leadV4;
        leadData['V5-syn'] = vLeads.subset(math.index(math.range(0, rows), 2)).toArray().flat();
        leadData['V6-syn'] = vLeads.subset(math.index(math.range(0, rows), 3)).toArray().flat();
    }
    return leadData;
}

export default FnWithPerformance;