import nj from './numjs.min.js'
const KARDIA_BLACK = '#242828'
const MV_LOW = -1.5
const MV_HIGH = 1.5

const EXCLUDE_ECTOPICS = false

export default function calculateAverageBeat (beatLabels, signal, intervals, useMedian) {
    signal = nj.array(signal)
  
    // Clone beatLabels so we can modify it
    beatLabels = JSON.parse(JSON.stringify(beatLabels))
  
    // Ensure beat labels are ordered by time
    beatLabels = beatLabels.sort((a, b) => {
      return a.i - b.i
    })
  
    // Remove noise annotations
    beatLabels = beatLabels.filter((d) => {
      return d.t !== 'x'
    })
  
    // Remove any beat labels that fall outside the signal
    beatLabels = beatLabels.filter((d) => {
      return d.i >= 0 && d.i < signal.size
    })
  
    // Treat LBBB and RBBB as normal beats for avg beat calculation
    for (var i = 0; i < beatLabels.length; i++) {
      if (beatLabels[i].t === 'r' || beatLabels[i].t === 'l') {
        beatLabels[i].t = 'n'
      }
    }
  
    var halfLength = 180
  
    if (EXCLUDE_ECTOPICS) {
      // if we have a N beat followed by a PAC or PVC, label it noise so it is excluded from averaging
      pairwise(beatLabels, (b1, b2) => {
        if (b1.t === 'n' && (b2.t === 'a' || b2.t === 'v')) {
          b1.t = 'x'
        }
      })
    }
  
    var onlyNormal = beatLabels.filter(function (d) {
      return d.t === 'n' && !sampleInIntervals(d.i, intervals)
    })
    var normalIndices = nj.array(onlyNormal.map(function (d) {
      return d.i
    }))
    if (normalIndices.size > 1) {
      return alignedBeatFromIndices(normalIndices, signal, halfLength, useMedian)
    } else {
      return []
    }
  }
  

// index of el in NumJS array a
function indexOf (el, a) {
    for (var i = 0; i < a.size; i++) {
      if (a.get(i) === el) {
        return i
      }
    }
    return undefined
  }
  
  // return xcorr(a,b,offset)
  function correlation (a, b, offset) {
    var sum = 0
    var pointsIncluded = 0
    for (var i = 0; i < Math.max(a.size, b.size); i++) {
      const aIdx = i
      const bIdx = i + offset
  
      if (aIdx < a.size && bIdx >= 0 && bIdx < b.size) {
        sum += a.get(aIdx) * b.get(bIdx)
        pointsIncluded++
      }
    }
    sum /= pointsIncluded
    return sum
  }
  
  // return 2D array of delays and cross-correlation values at each delay
  function correlationVector (a, b, delta) {
    var results = nj.zeros([2, 2 * delta + 1])
  
    for (var d = -delta; d <= delta; d++) {
      const resultIndex = d + delta
      const result = correlation(a, b, d)
      results.set(0, resultIndex, d)
      results.set(1, resultIndex, result)
    }
  
    return results
  }
  
  // extract beat from signal centered at index with halfLength, or undefined is this is outside signal bounds
  function extractBeatAtSample (index, signal, halfLength) {
    const offset = 0
    const start = index - halfLength + offset
    const end = index + halfLength + 1 + offset
    if (start >= 0 && end < signal.size) {
      var beat = signal.slice([start, end])
      return beat
    } else {
      return undefined
    }
  }
  
  // compute an average beat from signal of halfLength using beat indexes at (beatIndices + delays)
  function computeAverageBeat (signal, halfLength, beatIndices) {
    const beatLength = 2 * halfLength + 1
  
    var averageBeat = nj.zeros([beatLength])
    var totalBeats = 0
    for (var i = 0; i < beatIndices.size; i++) {
      const index = beatIndices.get(i)
      var beat = extractBeatAtSample(index, signal, halfLength)
      if (beat) {
        beat = beat.add(-beat.mean())
        averageBeat = averageBeat.add(beat)
        totalBeats++
      }
    }
  
    averageBeat = averageBeat.divide(totalBeats)
    return averageBeat
  }
  
  function findMedian (data) {
    var m = data.sort(function (a, b) {
      return a - b
    })
  
    var middle = Math.floor((m.length - 1) / 2)
    if (m.length % 2) {
      return m[middle]
    } else {
      return (m[middle] + m[middle + 1]) / 2.0
    }
  }
  
  function computeMedianBeat (signal, halfLength, beatIndices) {
    const beatLength = 2 * halfLength + 1
  
    var beats = []
    for (var i = 0; i < beatIndices.size; i++) {
      const index = beatIndices.get(i)
      var beat = extractBeatAtSample(index, signal, halfLength)
      if (beat) {
        beat = beat.add(-beat.mean())
        beats.push(beat)
      }
    }
  
    beats = nj.stack(beats)
    var averageBeat = nj.zeros([beatLength])
    for (i = 0; i < beatLength; i++) {
      averageBeat.set(i, findMedian(beats.slice(null, [i, i + 1]).flatten().tolist()))
    }
  
    return averageBeat
  }
  
  // calculate an average beat, align all beats relative to that average beat, and
  // then regenerate an average beat using the corrected alignment
  function alignedBeatFromIndices (normalIndices, signal, halfLength, useMedian) {
    const beatLength = 2 * halfLength + 1
    if (normalIndices.size === 1) {
      return nj.zeros([beatLength]).tolist()
    }
  
    var averageBeat = computeAverageBeat(signal, halfLength, normalIndices)
  
    var delays = nj.zeros([normalIndices.size])
    for (var i = 0; i < normalIndices.size; i++) {
      const index = normalIndices.get(i)
      var beat = extractBeatAtSample(index, signal, halfLength)
      if (beat) {
        var resultVector = correlationVector(averageBeat, beat, 10)
        var corrVector = resultVector.slice(1, null).flatten()
        var delayVector = resultVector.slice(0, null).flatten()
        const maxCorrIndex = indexOf(corrVector.max(), corrVector)
        const delay = delayVector.get(maxCorrIndex)
        delays.set(i, delay)
      }
    }
  
    var alignedAverageBeat
  
    if (useMedian) {
      alignedAverageBeat = computeMedianBeat(signal, halfLength, normalIndices.add(delays))
    } else {
      alignedAverageBeat = computeAverageBeat(signal, halfLength, normalIndices.add(delays))
    }
  
    // Align first sample of average beat with 0 on amplitude axis
    alignedAverageBeat = alignedAverageBeat.add(-alignedAverageBeat.mean())
    return alignedAverageBeat.tolist()
  }
  
  function pairwise (arr, func) {
    for (var i = 0; i < arr.length - 1; i++) {
      func(arr[i], arr[i + 1])
    }
  }
  
  /*
  function calculateNNInterval (beatLabels) {
    var sum = 0
    var count = 0
    pairwise(beatLabels, (d1, d2) => {
      if (d1.t === 'n' && d2.t === 'n') {
        sum += d2.i - d1.i
        count++
      }
    })
    const meanRRSamples = (sum / count)
  
    if (count === 0) {
      return -1
    } else {
      return meanRRSamples
    }
  }
  
  function calculateRRInterval (beatLabels) {
    const meanNNInterval = calculateNNInterval(beatLabels)
  
    if (meanNNInterval > 0) {
      return meanNNInterval
    } else {
      // There are no Normal-Normal intervals. This can happen in, e.g., bigeminy.
      // As a fallback, we will remove all non-normal beats and recalculate.
      var normalLabels = beatLabels.filter((d) => {
        return d.t === 'n'
      })
      return calculateNNInterval(normalLabels)
    }
  }
  */
  
  function sampleInIntervals (sample, intervals) {
    for (var i = 0; i < intervals.length; i++) {
      if (sample > intervals[i].start && sample < intervals[i].end) {
        return true
      }
    }
    return false
  }