import React from 'react'

// MUI Components
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CONSTANTS from '../../../constants/'

/**
 * A Basic footer component
 * @returns
 */

const Footer = ({alignment = 'left' }) => (
  <Stack textAlign={alignment}>
    {CONSTANTS?.CONTENT?.footer.map((text, index) => (
      <Typography variant="caption" key={index}>{text}</Typography>
    ))}
  </Stack>
)

export default Footer
