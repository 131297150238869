import { createSlice } from '@reduxjs/toolkit'

const initialState = { title: 'Update Interop Facility' }

// page slice to store to any global page related data
const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    updateTitle(state, action) {
      state.title = action.payload
    },
  },
})

export const pageActions = pageSlice.actions

export const pageReducer = pageSlice.reducer

export const getTitle = (state) => state.page.title
