import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
}

export const OpenEcg = createSlice({
  name: '@OpenEcg',
  initialState,
  reducers: {
    setData : (state, action) => {
      state.data = action.payload
    }
  },
})

export const { setData } = OpenEcg.actions

export default OpenEcg.reducer