import * as d3 from 'd3'

const KARDIA_BLACK = '#bb2739'

export const paintRRGraph = (context, width, height, data, domain, samplesPerSecond) => {
  const xScale = d3.scaleLinear()
    .domain([0, (domain / samplesPerSecond)])
    .range([0, width])
  const yScale = d3.scaleLinear()
    .domain([0, 2])
    .range([height, 0])

  const xAxisCreate = d3.axisBottom(xScale)
  const yAxisCreate = d3.axisLeft(yScale)
  const yAxisRightCreate = d3.axisRight(yScale).tickFormat((d) => {
    const num = 60 / d
    if (d === 0) {
      return
    } else if (Number.isInteger(num)) {
      return num
    } else {
      return num.toFixed(1)
    }
  })
  const xAxis = d3.select('#xAxisRR')
  const yAxis = d3.select('#yAxisRR')
  const yAxisRight = d3.select('#yAxisBPM')
  xAxis.call(xAxisCreate)
  yAxis.call(yAxisCreate)
  yAxisRight.call(yAxisRightCreate)

  // dont call line and point draw fxs if there is no data
  if (data) {
    for (let i = 0; i < data.length; i++) {
      paintPoint(context, data[i], xScale, yScale, 3, samplesPerSecond)
    }
    const line = d3.line()
      .x((d) => xScale(d.sample / samplesPerSecond))
      .y((d) => yScale(d.rr / samplesPerSecond))
      .context(context)

    context.beginPath()
    line(data)
    context.strokeStyle = KARDIA_BLACK
    context.lineWidth = 1.5
    context.stroke()
  }
}
export const paintPoint = (context, data, xScale, yScale, radius, samplesPerSecond) => {
  const startAngle = 0
  const endAngle = 2 * Math.PI
  context.fillStyle = KARDIA_BLACK
  context.beginPath()
  context.arc(
    xScale(data.sample / samplesPerSecond),
    yScale(data.rr / samplesPerSecond),
    radius,
    startAngle,
    endAngle * Math.PI,
    true)
  context.fill()
  context.closePath()
}
