import React from 'react'
import { Pagination, Stack } from '@mui/material'
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid-premium'
import { AddConfigButton } from '../../../../GlobalConfigList/GlobalConfigList'

function CustomPagination(props) {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  const { handleClick, rows } = props
  if (!rows?.length) return null
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      padding={1}
      paddingTop={2}
    >
      <AddConfigButton onClick={handleClick} />
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Stack>
  )
}
export default CustomPagination
