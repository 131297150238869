// import lib(s)
import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./Annotator.module.css"
import { useTheme } from "@emotion/react"

import ROUTES from "../../route/routes"
import { useNavigate, useParams } from "react-router-dom"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"

import JsonView from './views/JsonView';
import TaskRecordings from './TaskRecordings';
import AllLeadView from './views/AllLeadView';
import SingleLeadView from './views/SingleLeadView';
import ThreeRowView from './views/ThreeRowView';
import MedianBeats from './views/MedianBeats';
import RRIntervalPlot from './views/RRIntervalPlot';
import RightPanel from './RightPanel';

import Toolbar from "./views/Toolbar"

import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"


import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Grid } from "@mui/material"

import { init,  setRawData, setCurrentTask, setCurrentTaskRecordings, setCurrentRecordingId, setAnnotatorState, selectLead, setInverted, setScale, setFilteredSamples, setFilters } from './AnnotatorSlice'
import universalFormatter from "../../utils/universalFormatter"
import { METHODS, errorHandler } from "../../hooks/useFetch"


import { adaptiveFilter, butterworthLowpassFiltfilt, butterworthHighpassFiltfilt } from '../../utils/ecgFilter'

import FullWidthECGPreview from "../../components/FullWidthECGPreview"
import { useScrollContainer } from 'react-indiana-drag-scroll';



const Annotator = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const urlParams = useParams()
	const theme = useTheme()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	const axiosPrivate = useAxiosPrivate()

	const isTurkTask = !(urlParams["task_id"] === "open");

	// Tab Logic
	const [currentTab, setCurrentTab] = React.useState(0)
	const handleTabChange = (event, newValue) => {
		if (newValue == 100) {
			if (urlParams["task_id"] === "open") {
				toast.success(`${urlParams["ecg_id"].toUpperCase()} file closed!`)
				dispatch(init())
				navigate(ROUTES.ALL.OPEN_ECG)
			} else {
				console.log("task list")
				dispatch(init())
				navigate(ROUTES.ALL.TASK_LIST)
			}
		} else {
			setCurrentTab(newValue)
		}
	}

	// Annotator Data
	const AnnState = useSelector((state) => state.Annotator)
	useDocumentTitle( AnnState?.currentTask?.name || PAGE_TITLE.ANNOTATOR)


	const fetchTurkRecordingsAndTask = async (taskId, recordingID, changeTab = true) => {
		const start = performance.now()
		setLoading(true)
		console.log("Fetching Data of recording: ", recordingID, ". Task: ", taskId);
		console.log("state recording id: ", AnnState.currentRecordingId, ". Task: ", AnnState.currentTask);
		AnnState.currentTask.id !== taskId && dispatch(init())
		try {
			// Fetch All recordingList of this Task
			const start = performance.now();
			
			if(AnnState.currentTask.id !== taskId) {
				const { data: CurrentTask } = await axiosPrivate[METHODS.GET.toLowerCase()](`task?task_id=${taskId}`)
				dispatch(setCurrentTask(CurrentTask))

				const { data: TaskRecordingList } = await axiosPrivate[METHODS.GET.toLowerCase()](`recordings?task_id=${taskId}`)
				if (TaskRecordingList.length === 0) {
					toast.error("No Recordings Found")
					throw new Error("No Recordings Found")
				}
				dispatch(setCurrentTaskRecordings(TaskRecordingList))
			}

			// Fetch Recording Data of first recording
			const { data: RecordingData } = await axiosPrivate[METHODS.GET.toLowerCase()](`recording?recording_id=${recordingID}`)
			dispatch(setRawData(RecordingData))
			dispatch(setCurrentRecordingId(recordingID))
			// check if RecordingData.data.type is present 
			let uniEcg;
			if (RecordingData.data.hasOwnProperty("type")) {
				uniEcg = universalFormatter(RecordingData.data, RecordingData.data.type);
			} else {
				uniEcg = universalFormatter(RecordingData.data, "turk");
			}
			if (uniEcg.error !== null) { toast.error(uniEcg.error); throw new Error("Error processing recording") }
			const leadCount = Object.keys(uniEcg.filtered.samples).length
			const end = performance.now();
			// Calculate the elapsed time
			const elapsed = end - start;
			console.log(`Opening took ${elapsed} millisecond`);
			toast.success(`Loaded ${leadCount}L Turk Recording in ${elapsed.toFixed(1)}ms!`)
			dispatch(setAnnotatorState({ ...uniEcg, leadCount }))
			window.sidebar.close()
			setLoading(false)

			if (changeTab) {
				if (leadCount < 7) {
					console.log("Current tab 0");
					setCurrentTab(0)
				} else {
					console.log("Current tab 1");
					setCurrentTab(1)
				}
			}
			setError(null)
		} catch (error) {
			if (typeof error === "string") {
				toast.error(error)
			} else {
				toast.error("Error fetching Task Recordings")
			}
			console.log(error);
			setError(errorHandler(error))
		} finally {
			setLoading(false)
			const end = performance.now()
			window.printPerformance("Fetching the recording took:",`${(end-start).toFixed(2)}ms`);
		}
	}


	useEffect( () => {
		window.loadTurkTask = fetchTurkRecordingsAndTask;
		let stopLoading = true;
		if(!isTurkTask && AnnState.leadCount === 0) {
			// toast.error("Data Error: ECG not found!")
			navigate(ROUTES.ALL.OPEN_ECG)
		} else if (AnnState.leadCount === 0) {
			stopLoading = false
			console.log("Fetching Data of recording: ", urlParams["ecg_id"], ". Task: ", urlParams["task_id"])
			fetchTurkRecordingsAndTask(urlParams["task_id"], urlParams["ecg_id"])
		}
		stopLoading && setLoading(false)

		document.addEventListener ('keyup', function (e) {
			if (e.keyCode === 9 ) {
				e.preventDefault();
				console.log("Tab pressed");
				if(currentTab < 5){
					if (AnnState.leadCount < 7 && currentTab == 0)
						setCurrentTab(currentTab + 2)
					else
						setCurrentTab(currentTab + 1)
				}
				else 
					setCurrentTab(0)
			}
		}, false);

		if(AnnState.leadCount == 12) {
			console.log("Current tab 1");
			setCurrentTab(1)

			// ... Set Filters ... 
			const LocalFilters = window.localStorage.getItem("12l_filters")
		}
		  
	}, [])


    const scrollContainer = useScrollContainer();

	return (
		<Layout.CenterWrapper>
			{loading || error?.status === 421 ? (
				<Loader />
			) : error ? (
				<PlaceholderText>Error</PlaceholderText>
			) : (
				<Box sx={{ width: "100%", height: "100%" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs value={currentTab} onChange={handleTabChange} aria-label="Annotator Page Tab">
							<Tab value={0} sx={{ fontWeight: "Bold" }} label="Row View" />
							<Tab value={1} sx={{ fontWeight: "Bold", display: (AnnState.leadCount < 7 && "none") }} label={`${AnnState.leadCount/3}x${(30/AnnState.leadCount).toFixed(1)}s - 1 Channel(s) View`} />
							<Tab value={2} sx={{ fontWeight: "Bold" }} label="Lead Annotator" />
							<Tab value={3} sx={{ fontWeight: "Bold" }} label="Median Beat Plot" />
							{/* <Tab value={3} sx={{ fontWeight: "Bold", display: (!AnnState.annotations.medianBeatGraph?.I && "none") }} label="Median Beats" /> */}
							<Tab value={4} sx={{ fontWeight: "Bold", }} label="RR Interval" />
							<Tab value={100} sx={{ marginLeft: "auto", fontWeight: "Bolder", color: "#ff6961" }} label="&times; Close" />
						</Tabs>
					</Box>
					{currentTab == 0 && (
						<div style={{ marginTop: theme.spacing(2), height: `calc(100vh - ${theme.spacing(20)})`, display: "flex" }}>
							{/*100vh - 62.5 - 8 - 24 - 49 - 16  ^^^*/}

							{ true ? (
							// {AnnState.selectedLead === null ? (
								<div style={{display : "flex", flexDirection: "column"}}>
									<Card className={styles.disableselect} sx={{ width: "70vw", height: "calc(100% - 70px)", marginBottom: "10px", overflow: "scroll" }}>
										<AllLeadView changeTab={setCurrentTab} />
									</Card>
									<Card sx={{ width: "70vw", height: "60px"}}>
										<Toolbar />
									</Card>
								</div>
							) : (
								<SingleLeadView />
							)}
							<Card sx={{ width: `calc(100vw - 70vw - ${theme.spacing(19)})`, marginLeft: `10px`, height: "100%", overflowY: "scroll" }}>
								<RightPanel />
							</Card>
						</div>
					)}
					{currentTab == 1 && (
						<div style={{ marginTop: theme.spacing(2), height: `calc(100vh - ${theme.spacing(20)})`, display: "flex" }}>
							{/*100vh - 62.5 - 8 - 24 - 49 - 16  ^^^*/}
								<div style={{display : "flex", flexDirection: "column"}}>
									<Card sx={{width: `70vw`, height: "769px", marginBottom: "10px", overflow: "scroll", border: "1px solid #6387C5"}}>
										<ThreeRowView />
									</Card>
									<Card sx={{ width: `70vw`, height: "60px", display: "flex", alignContent: "center", justifyContent: "center" }}>
										<Toolbar disableTitle={true} />
									</Card>
								</div>
							<Card sx={{ width: `calc(100vw - 70vw - ${theme.spacing(19)})`, marginLeft: `10px`, height: "100%", overflowY: "scroll" }}>
								<RightPanel />
							</Card>
						</div>
					)}
					{currentTab == 2 && (
						<div style={{ marginTop: theme.spacing(2), height: `calc(100vh - ${theme.spacing(20)})`, display: "flex" }}>
							{/*100vh - 62.5 - 8 - 24 - 49 - 16  ^^^*/}

							{AnnState.selectedLead === null ? (
								<Card className={styles.disableselect} sx={{ width: "1150px", height: "calc(100%)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
									<Typography variant="h5" sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} my={3} component="div">
										Select a Lead to annotate
									</Typography>
									<FormControl sx={{width: "200px" }}  size="small">
										<InputLabel id="lead-select-label">ECG Leads</InputLabel>
										<Select
											labelId="lead-select-label"
											id="lead-select"
											label="ECG Leads"
											onChange={(e) => dispatch(selectLead(e.target.value))}
											value={AnnState.selectedLead}
										>
											{Object.keys(AnnState.filtered.samples).map((lead) => (
												<MenuItem  key={lead} value={lead}>Lead {lead}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Card>
							) : (
								<>
									<SingleLeadView changeTab={setCurrentTab} />
								</>
							)}

							<Card sx={{ width: `calc(100vw - 1150px - ${theme.spacing(19)})`, marginLeft: `10px`, height: "100%", overflowY: "scroll" }}>
								<RightPanel />
							</Card>
						</div>
					)}
					{currentTab == 3 && (
						<div style={{ marginTop: theme.spacing(2), height: `calc(100vh - ${theme.spacing(20)})`, display: "flex" }}>
							{/*100vh - 62.5 - 8 - 24 - 49 - 16  ^^^*/}
								<div style={{display : "flex", flexDirection: "column"}}>
									<Card sx={{width: `70vw`, height: "769px", marginBottom: "10px", overflow: "scroll", border: "1px solid #6387C5"}}>
										<MedianBeats />
									</Card>
									<Card sx={{ width: `70vw`, height: "60px", display: "flex", alignContent: "center", justifyContent: "center" }}>
										<Toolbar disableTitle={true} />
									</Card>
								</div>
							<Card sx={{ width: `calc(100vw - 70vw - ${theme.spacing(19)})`, marginLeft: `10px`, height: "100%", overflowY: "scroll" }}>
								<RightPanel />
							</Card>
						</div>
					)}
					{currentTab == 4 && (
						<div style={{ marginTop: theme.spacing(2), height: `calc(100vh - ${theme.spacing(20)})`, display: "flex" }}>
							{/*100vh - 62.5 - 8 - 24 - 49 - 16  ^^^*/}
							<Card sx={{width: `70vw`, height: "calc(100%)", marginBottom: "10px", overflow: "scroll", border: "1px solid #6387C5"}}>
								<RRIntervalPlot />
							</Card>
							<Card sx={{ width: `calc(100vw - 70vw - ${theme.spacing(19)})`, marginLeft: `10px`, height: "100%", overflowY: "scroll" }}>
								<RightPanel />
							</Card>
						</div>
					)}
				</Box>
			)}
		</Layout.CenterWrapper>
	)
}
export default Annotator
