import { useState, useEffect } from 'react'
export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
}

export const errorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      ...error.response.data,
      status: error.response.status,
    }
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      status: 421,
    }
  }
  // Something happened in setting up the request that triggered an Error
  // console.log('Error', error.message);
  return {
    status: 1,
  }
}

const useFetch = (config, skip) => {
  const { axiosInstance, method = METHODS.GET, path, requestConfig } = config
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [reFetch, setReFetch] = useState(0)
  const handleReFetch = () => setReFetch((prev) => prev + 1)
  useEffect(() => {
    const controller = new AbortController()
    const fetchResponse = async () => {
      try {
        const response = await axiosInstance[method.toLowerCase()](path, {
          ...requestConfig,
          signal: controller.signal,
        })
        setData(response.data)
        setError(null)
      } catch (error) {
        setError(errorHandler(error))
      } finally {
        setLoading(false)
      }
    }
    if (!skip) {
      fetchResponse()
    }
    return () => {
      controller.abort()
    }
    //eslint-disable-next-line
  }, [reFetch])

  return [loading, error, data, handleReFetch]
}

export default useFetch
