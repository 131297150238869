// import lib(s)
import React, { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./OpenEcg.module.css"

import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"

import ROUTES from '../../route/routes'
import { useNavigate, useParams } from 'react-router-dom'

import { useDropzone } from "react-dropzone"

import atc2json from "../../utils/atc2json"
import universalFormatter from "../../utils/universalFormatter"

import { init, setAnnotatorState, setRawData } from "../Annotator/AnnotatorSlice"

const OpenEcg = () => {

	useDocumentTitle(PAGE_TITLE.OPEN_ECG)
	const dispatch = useDispatch()
    const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        window.sidebar.open()
    }, [])

	const onDrop = useCallback((acceptedFiles) => {
        setLoading(true)
		if (acceptedFiles.length > 0) {
			let acceptedFileType = ""
			const readingFile = new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onloadend = (e) => resolve(e.target.result)
				reader.onerror = (e) => reject(e.target.error)
				reader.readAsArrayBuffer(acceptedFiles[0])
				acceptedFileType = acceptedFiles[0].name.split(".").pop()
			})
				.then((FileBuffer) => {
                    let uniEcg;
					dispatch(init())
					const start = performance.now();
					switch (acceptedFileType) {
						case "atc":
							const data = new Uint8Array(FileBuffer)
							const { parsed_data, error_msg } = atc2json.parse(data)
							if (error_msg) {
								console.log(parsed_data);
								toast.error(error_msg)
								setLoading(false)
								return
							}
							dispatch(setRawData(parsed_data))
                            uniEcg = universalFormatter(parsed_data, "atc");
                            if (uniEcg.error !== null) { toast.error(uniEcg.error); return; }
							break
						case "json":
							const jsonString = new TextDecoder().decode(FileBuffer)
							try {
								const parsed_json_data = JSON.parse(jsonString)
								dispatch(setRawData(parsed_json_data))
                                uniEcg = universalFormatter(parsed_json_data, "json");
                                if (uniEcg.error !== null) { toast.error(uniEcg.error);setLoading(false); return; }
							} catch (error) {
                                console.log(error);
								toast.error("Invalid JSON File")
								setLoading(false)
								return
							}
							break
						default:
							toast.error("Unsupported file type")
							return
					}
                    const leadCount = Object.keys(uniEcg.filtered.samples).length
					const end = performance.now();
					// Calculate the elapsed time
					const elapsed = end - start;
					console.log(`Opening took ${elapsed} millisecond`);
					toast.success(`Loaded ${leadCount}L ${acceptedFileType.toUpperCase()} file in ${elapsed.toFixed(1)}ms!`)
                    dispatch(setAnnotatorState({ ...uniEcg, leadCount }))
                    window.sidebar.close()
        			setLoading(false)
                    navigate(`/annotator/open/${acceptedFileType}`)
				})
				.catch((error) => {
					setLoading(false)
					console.log(error);
					toast.error("Parsing file failed")
				})
		} else {
			setLoading(false)
			toast.error("No ECG file selected")
		}
		setLoading(false)
	}, [])

	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			accept: {
				"application/atc": [".atc"],
				"application/json": [".json"],
			},
			maxFiles: 1,
			disabled,
			onDrop,
		})

	return (
		<Layout.CenterWrapper>
			{loading || error?.status === 421 ? (
				<Loader />
			) : error ? (
				<PlaceholderText>Error</PlaceholderText>
			) : (
				<React.Fragment>
					<div className={styles.gridPanel}>
						<div className={styles.uploadContainer} {...getRootProps()}>
							<input className={styles.inputBox} {...getInputProps()} />
							{!isDragActive && !isDragReject && (
								<h3 style={{ fontWeight: "500" }}>
									Click or Drag & drop ECG files here
								</h3>
							)}
							{isDragActive && !isDragReject && (
								<h3 style={{ fontWeight: "500" }}>Drop the file to parse...</h3>
							)}
							{isDragReject && (
								<h3 style={{ fontWeight: "500" }}>Drop the file to parse...</h3>
							)}
						</div>
					</div>
				</React.Fragment>
			)}
		</Layout.CenterWrapper>
	)
}

export default OpenEcg
