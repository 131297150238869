// import lib(s)
/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./SingleLeadView.module.css"

import {selectLead, deselectLead, updateContext, updateGhostBeat, updateGhostIntr, updateMouse, addIntr, removeIntr, modifyBeat, updateIntrContext, setScale } from '../AnnotatorSlice'
import FullWidthECGPreview from '../../../components/FullWidthECGPreview';
import * as Lib from '../../../constants/annotator'
import { paintSingleLeadWaveform, xScale, yScale } from '../components/utils/PaintGraph'
import { paintGrid } from '../components/utils/PaintGrid'
import * as d3 from 'd3';
import Beat from '../components/Beat/Beat'
import Interval from '../components/Interval/Interval';
import ContextMenu from '../components/ContextMenu/ContextMenu';
import IntervalContextMenu from '../components/IntervalContextMenu/IntervalContextMenu'

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useTheme } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const SingleLeadView = (props) => {
    const dispatch = useDispatch()
    const Annotator = useSelector((state) => state.Annotator)
    const settings = useSelector((state) => state.Annotator.settings)
    const ShowContext = useSelector((state) => state.Annotator.context.show)
    const ShowIntervalContext = useSelector((state) => state.Annotator.intrervalMenu.show)
    const ContextData = useSelector((state) => state.Annotator.context.data)
    const isCompleted = false
    // useSelector((state) => state.Annotator.recordingInfo.annotation.inbox) === 4
    const [Height, setHeight] = useState(925)
    const [Width, setWidth] = useState(1150)

    // logic for filters:
    const [filters, setFilters] = React.useState([]);
    const filterList = [
        'Adaptive',
        'Highpass - 0.1 Hz',
        'Highpass - 0.2 Hz',
        'Highpass - 0.5 Hz',
        'Lowpass - 40 Hz',
        'Lowpass - 50 Hz',
        'Lowpass - 100 Hz',
    ];
    

    const handleFilterChange = (event) => {
        const {
        target: { value },
        } = event;
        setFilters(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };


    const [ windowSize, SetWindowSize ] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    const handleWindowResize = () => {
        console.log("Window Resized");
        SetWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    const onDeSelectLead = () =>{
        dispatch(deselectLead())
        props.changeTab(0)
    }
    
    const AnnotatorContainer = useRef()
    const AnnotatorCanvas = useRef()

    // === New Component States ===
    const [PageRendered, setPageRendered] = useState(false)

    // === Annotator Constants ===
    let CanvasWidth
    let CanvasHeight
    let CurrentSample = Annotator.filtered.samples[Annotator.selectedLead]
    let CurrentSampleLength = CurrentSample.length
    let SecondsPerRow
    let SamplePerRow
    let NoOfRows
    let RowHeightPx
    let RowWidthPx
    let RecordingHeightPx
    let EkgHeight
    let annotatorCanvas
    let context
    let TotalSeconds = CurrentSampleLength / Annotator.filtered.frequency;

    const handleMouseDown = () =>{
        //console.log("Handle Mouse Down EVENT")
    }

    const rightClick = () =>{
        //console.log("Right Click EVENT")
    }

    const canvasBlurFix = () => {
        const canvas = document.getElementById('waveCanvas');
        const ctx = canvas.getContext('2d');
        const devicePixelRatio = window.devicePixelRatio || 1;

        function setCanvasSize() {
        // Get the display size of the canvas container (parent element)
        const containerWidth = canvas.parentElement.clientWidth;
        const containerHeight = canvas.parentElement.clientHeight;

        // Scale the canvas based on the device pixel ratio
        canvas.width = containerWidth * devicePixelRatio;
        canvas.height = containerHeight * devicePixelRatio;

        // Set the canvas size in CSS to ensure it maintains the correct aspect ratio
        canvas.style.width = containerWidth + 'px';
        canvas.style.height = containerHeight + 'px';

        // Scale the context to match the device pixel ratio
        ctx.scale(devicePixelRatio, devicePixelRatio);
        }

        // Call the function once on page load and whenever the window is resized
        setCanvasSize();
        window.addEventListener('resize', setCanvasSize);
    }

    const eventCoordinate = (e) => [e.nativeEvent.offsetX,e.nativeEvent.offsetY,e.pageX,e.pageY]

    useEffect(() => {
        // setHeight(AnnotatorContainer.current.offsetHeight)
        // setWidth(AnnotatorContainer.current.offsetWidth)
        CanvasWidth = AnnotatorContainer.current.offsetWidth
        CanvasHeight = AnnotatorContainer.current.offsetHeight

        SecondsPerRow =  CanvasWidth / Lib.PX_PER_SECOND;
        SamplePerRow = SecondsPerRow * Annotator.filtered.frequency;
        NoOfRows = Math.ceil(CurrentSampleLength / SamplePerRow);
        RowHeightPx = Lib.MV_BANDWIDTH * Lib.PX_PER_MV * 2;
        RowWidthPx = CanvasWidth
        RecordingHeightPx = RowHeightPx * NoOfRows;

        EkgHeight = RecordingHeightPx > CanvasHeight ? RecordingHeightPx : CanvasHeight; // Checking is the Graph is Bigger than the container
        setPageRendered(true)
        window.addEventListener("resize", handleWindowResize, false);
    }, [])

    useEffect(() => {
        canvasBlurFix()
        CanvasWidth = AnnotatorContainer.current.offsetWidth
        CanvasHeight = AnnotatorContainer.current.offsetHeight

        SecondsPerRow =  CanvasWidth / Lib.PX_PER_SECOND;
        SamplePerRow = SecondsPerRow * Annotator.filtered.frequency;
        NoOfRows = Math.ceil(CurrentSampleLength / SamplePerRow);
        RowHeightPx = Lib.MV_BANDWIDTH * Lib.PX_PER_MV * 2;
        RowWidthPx = CanvasWidth
        RecordingHeightPx = RowHeightPx * NoOfRows;

        EkgHeight = RecordingHeightPx > CanvasHeight ? RecordingHeightPx : CanvasHeight; // Checking is the Graph is Bigger than the container
        
        annotatorCanvas = document.getElementById('waveCanvas');
        
        context = annotatorCanvas.getContext('2d');
        context.clearRect(0, 0, CanvasWidth, CanvasHeight);

        paintGrid(context, SecondsPerRow, Height, Width);
        paintSingleLeadWaveform(
            CurrentSample,
            RowHeightPx,
            context,
            settings.scale,
            false,
            SamplePerRow,
            RowWidthPx,
            NoOfRows,
            false,
            Annotator.filtered.gain * 2.5
        );
    }, [PageRendered, windowSize, Annotator.selectedLead])

    const ClosestEkgPx = e => {
        const { offsetX: xCoord, offsetY: yCoord } = e.nativeEvent; // Exact coordinate of the cursor
        const currentRow = Math.floor(yCoord / RowHeightPx); // The closest row the mouse is clicking on, goes from 0 to n
        const YScale = yScale(rowHeight, false, settings.scale, Annotator.filtered.gain * 2.5); // This function returns the Y coordinate adjusted to the scale provided a value
        const newYcoordinate = currentRow * rowHeight + rowHeight / 2; // 0 line of evey row 
        const currentLead = IS_SINGLE_LEAD ? Object.keys(DATA_SAMPLES)[0] : Object.keys(DATA_SAMPLES)[currentRow];// the lead the mouse is hovering on
        const currentSample = DATA_SAMPLES[currentLead]; // the sample the mouse is hovering on 
        const samplesPerRow = IS_SINGLE_LEAD ? secondsInEachRow * Lib.SAMPLES_PER_SECOND : currentSample.length; // total number of samples in a row
        const XScale = xScale(samplesPerRow, canvasPanelDimension.width, settings.scale) // This function returns the X coordinate adjusted to the scale provided a value - samplesPerRow is SliceSize
        const offset = XScale.invert(xCoord) // gives the offset sample number (N - the data point or the location on samples array) the mouse is on
        const dataPointIndex = IS_SINGLE_LEAD ? Math.floor(currentRow * samplesPerRow + offset - 0.5 * DATA_FREQUENCY_PER_SEC) : Math.floor(offset - 0.5 * DATA_FREQUENCY_PER_SEC); // adjusts N value based on which row is currently active
        const sampleDataAtN = YScale(currentSample[dataPointIndex]) - rowHeight / 2; // finds the scale adjusted value of current Y
        return {
          xCoord,
          yCoord: newYcoordinate + sampleDataAtN,
          dataPointIndex,
          currentLead
        }
        // plotBeat(xCoord, newYcoordinate + sampleDataAtN);  // Plots a beat on the hovering point
      };

    const EtoSampleIndex = (e) => {
        const [x,y] = eventCoordinate(e)
        const currentRow = Math.floor(y/RowHeightPx)
        const YScale = yScale(RowHeightPx, false, settings.scale, Annotator.filtered.gain * 2.5); // This function returns the Y coordinate adjusted to the scale provided a value
        const XScale = xScale(SamplePerRow, CanvasWidth, 1) // This function returns the X coordinate adjusted to the scale provided a value
        const iFromLeft = Math.floor(XScale.invert(x))
        const iFromTop = SamplePerRow * currentRow
        return iFromLeft+iFromTop
    }

    const SampleIndextoPx = (i) => {
      // ==========================================
      //  Move to Redux
      CanvasWidth = 1150
      CanvasHeight = 1025  

      SecondsPerRow =  CanvasWidth / Lib.PX_PER_SECOND;
      SamplePerRow = SecondsPerRow * Annotator.filtered.frequency;
      NoOfRows = Math.ceil(CurrentSampleLength / SamplePerRow);
      RowHeightPx = Lib.MV_BANDWIDTH * Lib.PX_PER_MV * 2;
      RowWidthPx = CanvasWidth
      RecordingHeightPx = RowHeightPx * NoOfRows;
      // ==========================================
      
      let XCoordinate, YCoordinate

      const currentRow = Math.floor(i/SamplePerRow)
      const sampleFromLeft = i - currentRow * SamplePerRow // Generate X coordinate using this
      const YScale = yScale(RowHeightPx, false, settings.scale, Annotator.filtered.gain * 2.5); // This function returns the Y coordinate adjusted to the scale provided a value
      const XScale = xScale(SamplePerRow, CanvasWidth, 1) // This function returns the X coordinate adjusted to the scale provided a value
      const OffsetHeight = currentRow * RowHeightPx // 0 line of evey row 
      XCoordinate = XScale(sampleFromLeft)
      YCoordinate = YScale(CurrentSample[i]) + OffsetHeight
      return [XCoordinate,YCoordinate]
    }

    const getIntrXYs = (a,b) => {
        let x1,x2,y1,y2
        const start = a > b ? b : a
        const end =  a > b ? a : b
        const currentStartRow = Math.floor(start/SamplePerRow)
        const currentEndRow = Math.floor(end/SamplePerRow)

        // X values
        const sampleFromLeftStart = start - currentStartRow * SamplePerRow 
        const sampleFromLeftEnd = end - currentEndRow * SamplePerRow 
        const XScale = xScale(SamplePerRow, CanvasWidth, 1)
        x1=XScale(sampleFromLeftStart)
        x2=XScale(sampleFromLeftEnd)

        // Y values
        y1= currentStartRow*RowHeightPx
        y2= (currentEndRow+1)*RowHeightPx

        return [x1,y1,x2,y2]
    }

    const multiRowIntervalCalculator = (coordinates) => {
        // console.log("====$==$==$==$==$==$==$====")
        // console.log(coordinates)
        //console.log(AnnotatorContainer.current.offsetWidth)
        //console.log(Lib.MV_BANDWIDTH * Lib.PX_PER_MV * 2)
        const [x,y,x2,y2] = coordinates
        const rw = AnnotatorContainer.current.offsetWidth
        const rh = Lib.MV_BANDWIDTH * Lib.PX_PER_MV * 2
        let data=[]
        const startRow = y/rh
        const endRow = y2/rh
        // console.log("Spanning ",endRow-startRow," rows")
        data.push([x,y,rw,y+rh])
        // console.log(data.slice(-1),"- Console");
        // console.log("======");
        for(let i = startRow; i<endRow-1; i ++){
            data.push([0,rh*(i+1),rw,rh*(i+2)])
        }
        data[data.length-1][2]=x2
        // console.log("======");
        // console.log(data)
        return data
    }


    const peakDetectionCoords = (windowWidth, index, currentRow, yAmp, leadTitle='Lead I', isExtra=false) => {
        let peak;
        if (this.props.inverted && leadTitle==='Lead II') {
          let leadIData, leadIIData;
          if (isExtra) {
            leadIData = this.props.recording.data;
            leadIIData = this.props.recording.data.extraLeads.find(lead=>lead.title === 'Lead II')
          } else {
            leadIData = this.props.recording.data.extraLeads.find(lead=>lead.title === 'Lead I')
            leadIIData = this.props.recording.data;
          }
          const leadIIInversionSamples = leadIData.samples.map((samp, i) => leadIIData.samples[i] - samp)
          peak = searchForLocalPeak(index, yAmp, windowWidth, leadIIInversionSamples, this.samplesPerSecond)
          const y = yScale(this.rowHeight, false, this.props.scale)(peak.sampleData)
          const newOffset = peak.index - (this.sliceSize * currentRow)
          const x = xScale(this.sliceSize, this.strip_width_px, this.props.scale)(newOffset)
          return {
            x: x,
            y: y,
            index: peak.index,
            offset: newOffset
          }
        }
        
        if(isExtra) {
          const idx = this.props.recording.data.extraLeads.findIndex(lead => lead.title==leadTitle)
          peak = searchForLocalPeak(index, yAmp, windowWidth, this.props.recording.data.extraLeads[idx].samples, this.samplesPerSecond)
        } else {
          peak = searchForLocalPeak(index, yAmp, windowWidth, this.props.recording.data.samples, this.samplesPerSecond)
        }
        const y = yScale(this.rowHeight, this.props.inverted, this.props.scale)(peak.sampleData)
        const newOffset = peak.index - (this.sliceSize * currentRow)
        const x = xScale(this.sliceSize, this.strip_width_px, this.props.scale)(newOffset)
        return {
          x: x,
          y: y,
          index: peak.index,
          offset: newOffset
        }
      }

    const BeatPlotPeakDetection = (sampleIndex) => {
        //   yAmp,
        //   windowWidth, // done
        //   samples,
        //   samplesPerSecond,
        const currentRow = Math.ceil(sampleIndex / SamplePerRow)
        const yCoordInRow = SampleIndextoPx(sampleIndex)[1] - ( currentRow * RowHeightPx)
        const yClickScale = yScale(RowHeightPx, false, settings.scale, Annotator.filtered.gain * 2.5) // Inverted and scale
        const yAmp = yClickScale.invert(yCoordInRow)
        const samples = CurrentSample;
        const samplesPerSecond = Annotator.filtered.frequency;
        const windowWidth = 10; // one second is 300 units => 300/5 => 60 units for smaller box
        const startIndex = _.max([0, sampleIndex - windowWidth]);
        const endIndex = _.min([sampleIndex + windowWidth, samples.length - 1]);
        const sampleWindow = samples.slice(startIndex, endIndex + 1 || undefined);

        let minDiffVal, minDiffIndex;
        for (let i = 0; i < sampleWindow.length; i++) {
            const diffVal = Math.sqrt(
            square(Lib.PX_PER_MV * (yAmp - sampleWindow[i])) +
                square(
                (Lib.PX_PER_SECOND / samplesPerSecond) *
                    (sampleIndex - startIndex - i),
                ),
            );
            if (i === 0 || diffVal < minDiffVal) {
            minDiffVal = diffVal;
            minDiffIndex = i;
            }
        }
        const newIndex = startIndex + minDiffIndex;
        const newValue = samples[newIndex];
        const peak = {
            index: newIndex,
            sampleData: newValue,
        };

        const y = yClickScale(peak.sampleData);
        const newOffset = peak.index - SamplePerRow * currentRow;
        const x = xScale(SamplePerRow, CanvasWidth, 1)(newOffset);
        return {
            x: x,
            y: y,
            index: peak.index,
            offset: newOffset,
        };
    };

    const square = number => {
        return number * number;
    };

    const rangeIntersection = (a,b) => {
        // const {start:r1s, end:r1e} = a
        // const {start:r2s, end:r2e} = b
        // const [r1s,r1e] = [r1.start,r1.end]
        // const [r2s,r2e] = [r2.start,r2.end]
        // console.log(r1s,r1e);
        // console.log(r2s,r2e);    
        const min = (a.start < b.start  ? a : b)
        const max = (min == a ? b : a)
    
        //min ends before max starts -> no intersection
        if(min.end < max.start)
            return null //the ranges don't intersect

        //  Generates the sumed up intervals
        return [ (min.start < max.start ? min.start : max.start) , (min.end > max.end ? min.end : max.end)]
    }

    const SampleIndextoTime = (i) => {
        const timeScale = xScale(CurrentSampleLength,TotalSeconds,1)
        return timeScale(i).toFixed(2)
    }

    const sort2D = (a, b) => {
        if (a[0] === b[0]) {
            return 0;
        }
        else {
            return (a[0] < b[0]) ? -1 : 1;
        }
    }

    // Mouse Constants 
    const GhostData = useSelector(state => state.Annotator.ghostBeat)
    const ShowGhost = useSelector(state => state.Annotator.ghostBeat.show)
    const GhostIntrData = useSelector(state => state.Annotator.interval)
    const ShowIntrGhost = useSelector(state => state.Annotator.interval.show)
    const Mouse = useSelector(state => state.Annotator.mouse)
    const deleting = useSelector(state => state.Annotator.context.data.showDelete)

    //   Mouse Event Handling   
    const Actions = {
      MOVE_BEAT : 'move_beat',
    }
    const MouseHandler = (e) => {
        // mousedown - Mouse Click
        // mousemove - Mouse Drag
        // mouseup - Mouse leave Click
        // Click - Mouse Left Click
        // contextmenu - Mouse Right Click
        e.stopPropagation();
        e.preventDefault()
        const [x,y,pageX,pageY]=eventCoordinate(e)
        let index
        index = BeatPlotPeakDetection(EtoSampleIndex(e)).index
        // index = EtoSampleIndex(e)
        const [bx,by] = SampleIndextoPx(index)
        if(!isCompleted)
        switch(e.type){
            case "mousedown":
                if(e.button == 0 ){ // If Left Click
                    dispatch(updateMouse({
                        mousedown: true,
                        posX : bx,
                        posY : by,
                        iStart: index,
                    }))
                    dispatch(updateContext({
                        show : false,
                        data : {
                            top:0,
                            left:0,
                            showDelete:false,
                            beatIndex:0,
                        }
                    }))
                    // check if interval context menu is open
                    if(ShowIntervalContext){
                        dispatch(updateIntrContext({
                            show : false,
                        }))
                    }
                }
                // if(e.button == 2){ //Right Click
                //     //console.log("Mouse Right click ",x,y)
                // } Currently being handled using Context menu event 
                break
            case "mousemove":
                if(Mouse.mousedown && !ShowIntervalContext){ // If Mouse is in clicked state
                    if(Mouse.mouseDownOnBeat){ // If mouse is on top of a beat 
                        //console.log("==== Draging a beat ====")
                        dispatch(updateGhostBeat({
                            show : true,
                            coordinates : [bx,by],
                        }))
                    } else {
                        //console.log("==== Creating an interval ====")
                        dispatch(updateGhostIntr({
                            show : true,
                            coordinates : getIntrXYs(Mouse.iStart,index),
                        }))
                    }
                }
                break
            case "mouseup":
                if(Mouse.mousedown){
                    if(Mouse.iStart != index){ //if mouse has been dragged
                    if(Mouse.mouseDownOnBeat){ // if it was on a beat
                        //console.log("Beat at ",Mouse.bStart," moved to ",EtoSampleIndex(e))
                        
                        if( e.button != 2 ){ // if it was not a right click
                        dispatch(modifyBeat({beatIndex:Mouse.bStart,newValue:[index,Mouse.mouseDownOnBeatType]}))
                        }
                    } else {
                        // ============= Interval Creation Block =============
                        //console.log("Interval created from ",Mouse.iStart," to ",EtoSampleIndex(e))
                        let IntervalPayload = {
                            start:Mouse.iStart < index ? Mouse.iStart : index,     // Start "i" value
                            end:Mouse.iStart > index ? Mouse.iStart : index,       // End "i" value
                            time:{
                                // start:SampleIndextoTime(Mouse.Start),i          // start time in seconds
                                // end:SampleIndextoTime(index),                   // end time in seconds
                            },
                            type: {
                                id:'',                                             // type id from backed
                                name:'',                                           // type name from backend
                            },
                            // position:getIntrXYs(Mouse.iStart,index),            // this is the start and end coordinated in the graph. WILL BE REMOVED DOWN THE LANE
                            bpm:69, // BPM for the interval
                        }

                        // ============= Checking to See if altering intervals are required =============
                        let alterIntr = false
                        let alterIntrIndex = []
                        Annotator.annotations.intervals.map(intr => {
                            let newIntr = rangeIntersection(intr,IntervalPayload)
                            if(newIntr){
                                alterIntr=true
                                alterIntrIndex.push(intr.start)
                                IntervalPayload.start=newIntr[0]
                                IntervalPayload.end=newIntr[1]
                            }
                        })
                        if(alterIntr) alterIntrIndex.map(i=>dispatch(removeIntr(i)))
                        // ============= ============= ============= =============

                        // ============= Generating position & time meta data =============
                        IntervalPayload.position=getIntrXYs(IntervalPayload.start,IntervalPayload.end)
                        IntervalPayload.time.start = SampleIndextoTime(IntervalPayload.start)
                        IntervalPayload.time.end = SampleIndextoTime(IntervalPayload.end)
                        // ============= ============= ============= =============

                        // ============= BPM Calculations for the new interval =============
                        const beatlist = Annotator.annotations.beats.filter(b=> b.i>IntervalPayload.start && b.i<IntervalPayload.end)
                        let bpmArray = []
                        //console.log("<===BPM===>")
                        //console.log("Found ",beatlist.length," beats");
                        beatlist.sort(sort2D)
                        for(let i =0; i<beatlist.length-1;i++){
                            if((beatlist[i].t   == "n") && (beatlist[i+1].t == "n") ){
                                bpmArray.push(Math.round(60/(Math.abs(beatlist[i].i-beatlist[i+1].i)/Annotator.filtered.frequency)))
                                console.log(`%c+ Beats ${beatlist[i].i} and ${beatlist[i+1].i} type: ${beatlist[i].t},${beatlist[i+1].t}`, 'color: #5FA777')
                            } else {
                                console.log(`%c- Beats ${beatlist[i].i} and ${beatlist[i+1].i} type: ${beatlist[i].t},${beatlist[i+1].t}`, 'color: #FF6962')
                            }
                        }
                        IntervalPayload.LeadData = Annotator.selectedLead
                        IntervalPayload.beatCount = beatlist.length
                        //console.log("BPM Array => ",bpmArray);
                        const sum = bpmArray.reduce((a, b) => a + b, 0);
                        const avg = (sum / bpmArray.length) || 0;
                        if(bpmArray.length > 0){
                        IntervalPayload.bpm={
                            min:Math.min(...bpmArray).toFixed(0),
                            max:Math.max(...bpmArray).toFixed(0),
                            average:avg.toFixed(0)
                        }}
                        else{
                            IntervalPayload.bpm = null
                        }
                        //console.log("BPM set: ",IntervalPayload.bpm)
                        // ============= ============= ============= =============

                        // ============= Finally Adding Interval =============
                        if(IntervalPayload.end-IntervalPayload.start > 75)dispatch(addIntr(IntervalPayload))
                        // ============= ============= ============= =============

                        // ============= Action completed - Reset all the state =============
                        dispatch(updateMouse({
                            mousedown : false,
                            mouseDownOnBeat : false,
                            mouseDownOnBeatType : null,
                            posX : null,
                            posY : null,
                            iStart : null,
                            bStart : null,
                            dragging: null,
                        }))
                        // RESET GHOST
                        dispatch(updateGhostBeat({
                            show : false,
                        }))
                        // RESET GHOST 
                        dispatch(updateGhostIntr({
                            show : false,
                        }))
                    }}
                    // RESET MOUSE
                    dispatch(updateMouse({
                        mousedown : false,
                        mouseDownOnBeat : false,
                        mouseDownOnBeatType : null,
                        posX : null,
                        posY : null,
                        iStart : null,
                        bStart : null,
                        dragging: null,
                    }))
                    // RESET GHOST
                    dispatch(updateGhostBeat({
                        show : false,
                    }))
                }
                break
            case "click":
                dispatch(updateGhostBeat({
                    show : true,
                    coordinates : [bx,by],
                }))
                break
            case "contextmenu":
                if(!deleting){ // if the beat deleting didnt not invoke this event ( thats handled on the beats component)
                    dispatch(updateGhostBeat({
                        show : true,
                        coordinates : [bx,by],
                    }))
                    // RESET MOUSE
                    dispatch(updateMouse({
                        mousedown : false,
                        mouseDownOnBeat : false,
                        mouseDownOnBeatType : null,
                        posX : null,
                        posY : null,
                        iStart : null,
                        bStart : null,
                        dragging: null,
                    }))
                    dispatch(updateContext({
                        show : true,
                        data : {
                            left:bx,
                            top:by,
                            showDelete:false,
                            beatIndex:index,
                        }
                    }))
                }
                break
            default:
                //console.log("Warning: Unhandled Mouse Event - ",e.type)
                break
        }
    }

    return (
        <div className={styles.AnnotatorContainer}>
            <div className={styles.AnnotatorHeader}>
                <Card sx={{width: "100%", display: "flex"}}>
                    <button className={styles.goBackButton} onClick={()=>onDeSelectLead()}> 
                        <ChevronLeftIcon />
                        &nbsp;&nbsp;
                        Go back
                    </button>
                    <div className={styles.CenterSection}>
						<Divider orientation="vertical" sx={{marginRight: "20px"}} />
                        <FormControl sx={{width: "300px" }}  size="small">
                            <InputLabel id="lead-select-label">ECG Leads</InputLabel>
                            <Select
                                labelId="lead-select-label"
                                id="lead-select"
                                label="ECG Leads"
                                onChange={(e) => dispatch(selectLead(e.target.value))}
                                value={Annotator.selectedLead}
                            >
                                {Object.keys(Annotator.filtered.samples).map((lead) => (
                                    <MenuItem key={lead} value={lead}>Lead {lead}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
						<Divider orientation="vertical" sx={{marginX: "20px"}} />
                            <span className={styles.Leadname}>{Annotator.filtered.duration}s</span>
						<Divider orientation="vertical" sx={{marginRight: "20px"}} />
                            <span className={styles.Leadname}>{Annotator.leadCount}L ECG</span>
						<Divider orientation="vertical" sx={{marginRight: "20px"}} />
                    </div>
                    <div  className={styles.ScaleDiv}>
						<Divider orientation="vertical" sx={{marginRight: "20px"}} />
                        <h4 className={styles.ScaleText}>25 mm/s &nbsp; 10 mm/mV</h4>
                    </div>
                    
                </Card>
            </div>
            <Card ref={AnnotatorContainer} className={styles.AnnotatorTool}
                onMouseMove={MouseHandler}
                onMouseUp={MouseHandler}
                onMouseDown={MouseHandler}
                onClick={MouseHandler}
                onContextMenu={MouseHandler}
            >
                <canvas
                    className={styles.canvas}
                    id="waveCanvas"
                    height={Height ? Height : prop.h}
                    width={Width ? Width : prop.w}
                    ref={AnnotatorCanvas}
                    // onMouseDown={(e) => mouseDown(e)}
                    // onMouseMove={(e) => mouseOver(e)}
                    // onMouseUp={(e) => mouseUp(e)}
                    // onContextMenu={(e) => contextMenu(e)}
                    >
                </canvas>
                <svg width={Width ? Width : prop.w} height={Height ? Height : prop.h} className={styles.topSVG}>
                  {PageRendered && Annotator.annotations.intervals && Annotator.annotations.intervals.map(intr => 
                    <Interval 
                        coordinates ={intr.position}
                        data = {multiRowIntervalCalculator(intr.position)}
                        type ={intr.type}
                        index ={intr.start}
                        interval = {intr}
                        key ={JSON.stringify(intr)}
                    />
                  )}
                  {PageRendered && ShowIntrGhost &&
                    <Interval 
                        coordinates ={GhostIntrData["coordinates"]}
                        type ={{name:"ghost"}}
                        index ={GhostIntrData["index"]}
                        data = {multiRowIntervalCalculator(GhostIntrData["coordinates"])}
                    />
                  }
                  {/* { === Interval done === } */}
                  {PageRendered && Annotator.annotations.beats && Annotator.annotations.beats != [] && Annotator.annotations.beats.map(beat => 
                    <Beat 
                        coordinates={SampleIndextoPx(beat.i)}
                        type={beat.t}
                        index={beat.i}
                        key ={beat.i}
                        beatLabel={Annotator.annotations.labels.beats}
                    />
                  )}
                  {PageRendered && ShowGhost &&
                    <Beat 
                        coordinates ={GhostData["coordinates"]}
                        type ={"ddf"}
                        index ={GhostData["index"]}
                        beatLabel={Annotator.annotations.labels.beats}
                    />
                  }
                </svg>
            </Card>
            { ShowContext && 
                <ContextMenu 
                    beatLabel={Annotator.annotations.labels.beats}
                />
            }
            { ShowIntervalContext && 
                <IntervalContextMenu />
            }
        </div>
        
    )
}

export default SingleLeadView
