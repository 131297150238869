import {
  arSD, bgBG, csCZ,
  daDK, nlNL, enUS,
  fiFI, frFR, deDE,
  elGR, heIL, huHU,
  itIT, jaJP, koKR,
  nbNO, faIR, plPL,
  ptBR, roRO, ruRU,
  skSK, esES, svSE,
  trTR, ukUA, zhCN,
  zhTW
} from '@mui/x-data-grid-premium';
import { LOCALE_DEFAULT_TEXT } from '../utils';

const SUPPORT_LANGUAGE = {
  'arSD': arSD, 'bgBG': bgBG, 'csCZ': csCZ, 'daDK': daDK, 'nlNL': nlNL, 'enUS': enUS,
  'fiFI': fiFI, 'frFR': frFR, 'deDE': deDE, 'elGR': elGR, 'heIL': heIL, 'huHU': huHU,
  'itIT': itIT, 'jaJP': jaJP, 'koKR': koKR, 'nbNO': nbNO, 'faIR': faIR, 'plPL': plPL,
  'ptBR': ptBR, 'roRO': roRO, 'ruRU': ruRU, 'skSK': skSK, 'esES': esES, 'svSE': svSE,
  'trTR': trTR, 'ukUA': ukUA, 'zhCN': zhCN, 'zhTW': zhTW
}

const useLanguageSupport = (defaultLocaleLanguage, defaultLocaleText) => {
  const LANG = defaultLocaleLanguage === undefined ? enUS
    : SUPPORT_LANGUAGE[defaultLocaleLanguage].components.MuiDataGrid.defaultProps.localeText;
  return Object.assign({}, LANG,
    (defaultLocaleText && defaultLocaleText !== undefined) ?
      defaultLocaleText
      :
      LOCALE_DEFAULT_TEXT[defaultLocaleLanguage]
  )
}

export default useLanguageSupport;