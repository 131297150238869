import React, { useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import ROUTES from '../../route/routes'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { GoogleLoginWithAlivecorEmail, useAuthPersist } from '../../auth/hooks/'
import { useDocumentTitle, useUserLoggedIn } from '../../hooks'

import Layout from '../../layouts/common/Layout'
import { CONTENT, PAGE_TITLE } from '../../constants/constants'
import { ReactComponent as AliveCorLogo } from '../../assets/images/kc_white_logo.svg'
import { Alert } from '@mui/material'

const Login = () => {
  const [hasError, setHasError] = useState(false)
  useDocumentTitle(PAGE_TITLE.LOGIN)
  // checks if the user is already logged-in then redirects to dashboard
  useUserLoggedIn()
  const navigate = useNavigate()
  const location = useLocation()

  const from = location?.state?.from?.pathname || ROUTES.ALL.TASK_LIST

  const handleSuccess = (data) => {
    // navigate to the protected page from where it is redirected to login page
    navigate(from, {
      replace: true,
    })
  }

  const handleError = () => {
    console.log('Login failed')
    setHasError(true)
  }

  // const googleLogin = useGoogleLoginWithToken(handleSuccess, handleError)

  return (
    <Layout.CenterWrapper>
      {hasError ? (
        <Alert
          severity="error"
          sx={{
            my: 3,
          }}
        >
          {CONTENT.UNAUTHORIZED_USER}
        </Alert>
      ) : null}

      {/* <Button
        onClick={googleLogin}
        variant="contained"
        size="large"
        startIcon={<AliveCorLogo width="24px" />}
      >
        Login with AliveCor email
      </Button> */}
      <GoogleLoginWithAlivecorEmail
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </Layout.CenterWrapper>
  )
}

const LoginWithAuth = () => {
  const isLoading = useAuthPersist()
  if (isLoading)
    return (
      <Layout.CenterWrapper>
        <Typography variant="h6">One moment please...</Typography>
      </Layout.CenterWrapper>
    )
  return <Login />
}

export default LoginWithAuth
