import { useDispatch } from 'react-redux'
import { axiosPrivate } from '../../api/axios'
import { API_URL } from '../../api/constants'
import useAuth from './useAuth'

const useLocalStorageToken = () => {
  const { auth, login } = useAuth()
  const dispatch = useDispatch()
  const LSFetch = async () => {
    try {
    const userInfo = JSON.parse(window.localStorage.getItem('usr'))
    // console.log("Fetched User Info from Local Storage: ", userInfo)
    if (userInfo) {
      const now = new Date()
      const exp = new Date(userInfo.exp * 1000)
      if (now < exp) {
        dispatch(login(userInfo))
        return userInfo
      }
      // token is expired
      throw new Error('Token is expired')
    }
    } catch (error) {
      // console.log(error)
    } finally {
      return null
    }
  }
  return LSFetch
}

export default useLocalStorageToken
