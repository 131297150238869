// Morphology and Rhythm Statement generator

export const MorphologyLabels = {
    0:"rbbb",
    1:"lbbb",
    2:"other_bbb",
    3:"lvh",
    4:"rvh",
    5:"rae",
    6:"lae",
    7:"ant_old_mi",
    8:"inf_old_mi",
    9:"lat_old_mi",
    10:"ant_acute_mi",
    11:"inf_acute_mi",
    12:"lat_acute_mi",
    13:"ant_ischemia",
    14:"inf_ischemia",
    15:"lat_ischemia",
    16:"early_repolarization",
    17:"prolonged_qt",
    18:"paced_ecg",
    19:"wpw",
    20:"normal"
}

export const RhythmLabels = {
	0: "normal_sinus_rhythm",
	1: "sinus_rhythm",
	2: "atrial_fibrillation",
	3: "atrial_flutter",
	4: "firstdegree_av_block",
	5: "high_degree_avblock",
	6: "sinus_arrythmia",
	7: "marked_sinus_arrythmia",
	8: "paced_rhythm",
	9: "marked_bradycardia",
	10: "junctional_rhythm",
	11: "sinus_tachy",
	12: "bigeminy",
	13: "pvcs",
}


const main = (input) => {

	let morphologyArray, rhythmArray, type
	if (input?.algorithm === "HighSpecificity") {
		morphologyArray = input.morphologyHighSpec
		rhythmArray = input.rhythmHighSpec
        type = "Asymptomatic"
	} else {
		// Assume HighSensitivity if not HighSpecificity
		morphologyArray = input.morphologyHighSens
		rhythmArray = input.rhythmHighSens
        type = "Symptomatic"
	}

	let morphology = morphologyArray.map((id) => MorphologyLabels[id])
	let rhythm = rhythmArray.map((id) => RhythmLabels[id])

	return { morphology, rhythm, type, morphologyArray, rhythmArray }
}

export default main