import React from 'react'
import ReactDOM from 'react-dom/client'

// Google Auth
import GoogleAuth from './auth/components/GoogleAuth'

// Redux
import { Provider } from 'react-redux'
import store from './state/store'

// Toast
import { Toaster } from 'react-hot-toast';

// Diaable dev tools on production
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

import './index.css'
import App from './App'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}
const appRoot = ReactDOM.createRoot(document.getElementById('app-root'))

appRoot.render(
  <>
    <Provider store={store}>
      <GoogleAuth.Provider
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
      >
        <App />
      </GoogleAuth.Provider>
    </Provider>
    <Toaster />
  </>
)

try {
  window.actk = {
    buildInfo: JSON.parse(process.env.REACT_APP_BUILD_INFO),
  }
} catch (error) {
  window.actk = {
    buildInfo: "Not Available",
  }
}

const printPerformance = (type, value) => {

  const titleStyles = `
    background: #6387C5; 
    color: #fff; 
    padding: 5px; 
    font-size: 14px; 
    font-weight: 600;
  `
  const valueStyles = `
    background: #164e8c; 
    color: #fff; 
    padding: 5px; 
    font-size: 14px; 
    font-weight: 800;
  `

  // console.log(`%c ${type} %c ${value} `, titleStyles, valueStyles);
}
window.printPerformance = printPerformance;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
