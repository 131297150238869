/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ContextMenu.module.css'
import { addBeat, updateContext, updateGhostBeat, removeBeat, updateMouse } from '../../AnnotatorSlice'

const ContextMenu = (props) => {
    const {
        beatLabel,
    } = props

    const dispatch = useDispatch()
    const ContextData = useSelector((state) => state.Annotator.context.data)

    const {
        top = 0,
        left = 0,
        showDelete = false,
        beatIndex = null,
    } = ContextData

    const createBeat = (type) => {
        dispatch(addBeat({type,beatIndex}))
        dispatch(updateContext({
            show : false,
            data : {
                top:0,
                left:0,
                showDelete:false,
                beatIndex:0,
            }
        }))
        dispatch(updateGhostBeat({
            show : false,
        }))
    }

    const deleteBeat = (e) => {
        e.preventDefault();
        dispatch(updateContext({
            show : false,
            data : {
                top:0,
                left:0,
                showDelete:false,
                beatIndex:0,
            }
        }))
        dispatch(updateMouse({
            mousedown : false,
            mouseDownOnBeat : false,
            posX : null,
            posY : null,
            iStart : null,
            bStart : null,
            dragging: null,
        }))
        dispatch(updateGhostBeat({
            show : false,
        }))
        
        dispatch(removeBeat(beatIndex))
    }
    
    return (
        <div
            id="contextMenu"
            style={{ top: top+90, left }}
            className={styles.dropdownContent}
            onContextMenu={(e)=>{e.preventDefault()}}
        >
            {/* <a href="#" onClick={()=>{createBeat(1)}}>Noise</a> */}
            {beatLabel.map((label, i) => !label.name.includes("DEFAULT_") && (
                <a href="#" key={i} style={{backgroundColor: label.color, filter: "alpha(opacity=10)"}} onClick={()=>{createBeat(label.slug)}}>{label.name}</a>
            ))}
           {showDelete && <a href="#" id="delete-beat" style={{ backgroundColor: '#aa2e25', color:'#fff'}} onClick={(e)=>{deleteBeat(e)}}>Delete</a>}
        </div>
    )
}

export default ContextMenu
