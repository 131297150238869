/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./Beats.module.css"
import * as d3 from "d3"
import _, { sample } from "lodash"
// XXX import MainsFilter from '../MainsFilter'
import * as Lib from "../../../constants/annotator"

const Beat = (props) => {
	const { height, inverted, samples, index, type, mvSwing, frequency, scale = 1, beatLabel } = props
	const stripTimeSec = samples.length / frequency
	const stripWidthPx = stripTimeSec * Lib.PX_PER_SECOND * scale
	// TODO these may depend on paper speed and gain

	const xScale = () => d3.scaleLinear().domain([0, samples.length]).range([0, stripWidthPx])

	const yScale = () => {
		const range = [ height, 0 ]
		if (inverted) {
			console.log("Inverted");
		  range.reverse()
		}
		// TODO this domain needs to be set from properties of the recording
		return d3.scaleLinear()
		  .domain([ (mvSwing * -1), mvSwing ])
		  .range(range)
	  }
  

	const itopx = (i) => {
		const yS = yScale()
		const xS = xScale()
		return [xS(i), yS(samples[i])]
	}

	// 0 = Noise (Shown on Frontend)
	// 1 = Noise (Shown on Frontend)
	// 2 = Normal (Shown on Frontend)
	// 3 = VEB (Shown on Frontend)
	// 5 = SVEB (Shown on Frontend)

	const renderGhost = (x, y) => (
		<g transform={`translate(${x},${y})`}>
			<circle r="7" fill="#8c8c8c" />
		</g>
	)
	const renderNoise = (x, y) => (
		<g transform={`translate(${x},${y})`}>
			<circle r="7" fill="#6CCA6E" />
		</g>
	)
	const renderBeat = (x, y) => (
		<g transform={`translate(${x},${y})`}>
			<circle r="7" fill="#6CCA6E" />
		</g>
	)
	const renderVe = (x, y) => (
		<g transform={`translate(${x - 11},${y - 11})`}>
			<path cx="-11" cy="-11" d="M10 0L19.5263 16.5H0.473721L10 0Z" fill="#DC271B" />
		</g>
	)
	const renderSVe = (x, y) => (
		<g transform={`translate(${x - 11},${y - 11})`}>
			<path d="M0 10L10 0L20 10L10 20L0 10Z" fill="#5EC2FB" />
		</g>
	)

	const renderOne = (type) => {
		const [x, y] = itopx(index)
		switch (type) {
			case 0:
				return renderGhost(x, y)
				break
			case 1:
				return renderNoise(x, y)
				break
			case 2:
				return renderBeat(x, y)
				break
			case 3:
				return renderVe(x, y)
				break
			case 4:
				return <g></g>
				break
			case 5:
				return renderSVe(x, y)
				break
			default:
				return <g></g>
				break
		}
	}

	// return renderOne(type)
	return (
		<g transform={`translate(${itopx(index)})`}>
			<circle r="7" fill={beatLabel.find((label) => label.slug === type)?.color || "#29292929"} />
		</g>
	)
}

export default Beat
