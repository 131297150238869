import { GoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode'

import useAuth from './useAuth'

import { parseUserInfo,parseJWT } from '../../utils'

const GoogleLoginWithAlivecorEmail = ({onSuccess, onError}) => {
    const dispatch = useDispatch()
    // login action
    const { login } = useAuth()

    const handleSuccess = (token) => {
        const userInfo = parseUserInfo(token.credential)
        if (userInfo.emailVerified) {
            window.localStorage.setItem('usr', JSON.stringify(userInfo))
            dispatch(login(userInfo))
            onSuccess(userInfo)
        }
    }

    return (
        <GoogleLogin
            onSuccess={handleSuccess}
            onError={onError}
        />
    )
}

export default GoogleLoginWithAlivecorEmail;