import { useEffect } from 'react'
import { axiosPrivate } from '../api/axios'
import useAuth from '../auth/hooks/useAuth'
import useRefreshToken from '../auth/hooks/useRefreshToken'
import toast from "react-hot-toast"
import { useDispatch } from 'react-redux'

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const dispatch = useDispatch()
  const { auth, logout } = useAuth()
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.user?.token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          const newAccessToken = await refresh()
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          return axiosPrivate(prevRequest)
        }
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          console.log('401');
          toast.error("Your session has expired. Please login again.")
          prevRequest.sent = true
          dispatch(logout())
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )
    return () => {
      axiosPrivate.interceptors.response.eject(responseIntercept)
      axiosPrivate.interceptors.request.eject(requestIntercept)
    }
  }, [auth, refresh])

  return axiosPrivate
}

export default useAxiosPrivate
