/* eslint-disable */
import * as d3 from 'd3';
import * as Lib from '../../../../constants/annotator'

export const xScale = (domain, range, scale) =>
  d3
    .scaleLinear()
    .domain([0, domain])
    .range([0, range * scale]);

export const yScale = (height, inverted, scale, mvSwing = 2000) => {
  const range = [height, 0];
  if (inverted) {
    range.reverse();
  }

  return d3
    .scaleLinear()
    .domain([mvSwing * -1, mvSwing])
    .range(range);
};

export const paintSingleLeadWaveform = (
  samples,
  height,
  context,
  scale,
  inverted,
  sliceSize,
  stripWidthPx,
  rows,
  calpulse = true,
  mvSwing = 2000,
) => {
  /* eslint-disable no-param-reassign */
  let sampleSlice;
  let step = 0;
  // shiftPx and shiftSamp for moving waveform to accomodate calpulse
  const shiftPx = Lib.PX_PER_SECOND / 2;

  context.save(); // Since we are transforming the context we need to reset to make sure scale is proper

  for (let j = 0; j < rows; j += 1) {
    const shiftSamp = j === 0 && calpulse ? shiftPx * Lib.SAMPLES_PER_PIXEL : 0;
    sampleSlice = samples.slice(step, step + sliceSize - shiftSamp);
    const returnX = (d, k) => xScale(sliceSize, stripWidthPx, scale)(k);
    const returnY = d => yScale(height, inverted, scale, mvSwing)(d);
    const line = d3
      .line()
      .x(returnX)
      .y(returnY)
      .context(context);

    if (j !== 0) {
      context.translate(0, height);
    }
    context.beginPath();

    if (j === 0 && calpulse) {
      context.translate(shiftPx, 0);
    } else if (j === 1 && calpulse) {
      context.translate(-shiftPx, 0);
    }
    line(sampleSlice);
    context.strokeStyle = Lib.WAVEFORM_BLACK;
    context.lineJoin = "round"
    context.lineWidth = 1.5;
    context.stroke();

    step += sampleSlice.length;
  }

  context.restore(); // Since we are transforming the context we need to reset to make sure scale is proper
};

export const paintMultiLeadWaveform = (
  samples,
  height,
  context,
  scale,
  inverted,
  sliceSize,
  stripWidthPx,
  calpulse = true,
) => {
  /* eslint-disable no-param-reassign */
  // shiftPx for moving waveform to accomodate calpulse
  const shiftPx = Lib.PX_PER_SECOND / 2;

  const returnX = (d, k) => xScale(sliceSize, stripWidthPx, scale)(k);
  const returnY = d => yScale(height, inverted, scale)(d);
  const line = d3
    .line()
    .x(returnX)
    .y(returnY)
    .context(context);

  // converting object into array to make sure that leads are in the right order
  const allSamples = [
    samples.leadI,
    samples.leadII,
    samples.leadIII,
    samples.aVR,
    samples.aVL,
    samples.aVF,
  ];

  context.save(); // Since we are transforming the context we need to reset to make sure scale is proper

  allSamples.forEach((lead, i) => {
    if (i !== 0) {
      // translating each lead half the height of a full row
      context.translate(0, height);
    }
    context.beginPath();

    if (i === 0 && calpulse) {
      context.translate(shiftPx, 0);
    }
    line(allSamples[i]);
    context.strokeStyle = Lib.WAVEFORM_BLACK;
    context.lineJoin = "round"
    context.lineWidth = 1.5;
    context.stroke();
  });

  context.restore(); // Since we are transforming the context we need to reset to make sure scale is proper
};