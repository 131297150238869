import React from 'react'

import { styled } from '@mui/material'

import { Outlet } from 'react-router-dom'
import Layout from '../common/Layout'

const FullScreenLayout = ({ header, footer }) => {
  return (
    <>
        <Outlet />
    </>
  )
}

export default FullScreenLayout
