// import lib(s)
/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./Toolbar.module.css"


import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useTheme } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Grid } from "@mui/material"

import { setInverted, setScale, setFilteredSamples, setFilters } from '../AnnotatorSlice'
import { toast } from "react-hot-toast"

import { adaptiveFilter, butterworthLowpassFiltfilt, butterworthHighpassFiltfilt } from '../../../utils/ecgFilter'
import calculateLeads from "../../../utils/leadCalc"

const Toolbar = ( props ) => {
    const { 
        disableTitle = false,
    } = props
    const dispatch = useDispatch()
    const Annotator = useSelector((state) => state.Annotator)
    const Filters = useSelector((state) => state.Annotator.filtered.filters)
    const AnnotatorSettings = useSelector((state) => state.Annotator.settings)

    const filterable = false
    // const filterable = Annotator?.original?.samples?.I

    const OnFiltered = (event) => { 

        const start = performance.now();
        const OriginalSamples = Annotator.original.samples 

        let ExistingFilters = JSON.parse(JSON.stringify(Filters))
        switch ( event.target.name ) {
            case "adaptive":
                if (event.target.value === 30) {
                    ExistingFilters.adaptive = false
                }
                if (event.target.value === 10) {
                    ExistingFilters.adaptive = true
                }
                break;
            case "lowpass":
                ExistingFilters.ButterworthLowPass = event.target.value
                break;
            case "highpass":
                ExistingFilters.ButterworthHighPass = event.target.value
                break;
            case "noSyn":
                ExistingFilters.noSyn = !ExistingFilters.noSyn
                break;
            default:
                break;
        }

        const leadData = {...OriginalSamples};
        for (let [lead, samples] of Object.entries(OriginalSamples)) {
            if (ExistingFilters.adaptive) 
                leadData[lead] = adaptiveFilter(Annotator.original.frequency, Annotator.original.mainsFrequency, leadData[lead]);
            if (ExistingFilters.ButterworthLowPass !== 0) 
                leadData[lead] = butterworthLowpassFiltfilt(ExistingFilters.ButterworthLowPass, Annotator.original.frequency, leadData[lead]);
            leadData[lead] = butterworthHighpassFiltfilt(ExistingFilters.ButterworthHighPass, Annotator.original.frequency, leadData[lead]);
        }
        dispatch(setFilters(ExistingFilters))
        dispatch(setFilteredSamples(calculateLeads(leadData)))
        const end = performance.now();
        toast.success(`Filters Applied in ${(end - start).toFixed(0)}ms`)
    }

  return (
    <Grid container sx={{height: "100%", justifyContent: "center"}}>
        {!disableTitle && <>
            <Grid item xs={1.4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <span className={styles.Leadname}>{Annotator.filtered.duration}s - {Annotator.leadCount}L ECG</span>
            </Grid>
            <Grid item py={1} xs={0.1}>
                <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
            </Grid>
        </>}
        {/* zoom */}
        <Grid item xs={0.5} ml={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <button
                    style={{ margin: "auto", padding: "7px" }}
                    className={styles.goBackButton}
                    onClick={() => {
                        const start = performance.now()
                        if (AnnotatorSettings.scale === 0.25) {
                            toast.error("Minimum zoom level reached")
                            return
                        }
                        dispatch(setScale(AnnotatorSettings.scale - 0.25))
                        const end = performance.now()
                        toast.success(`Zoomed Out in ${(end - start).toFixed(0)}ms`)
                    }}
                >
                    <ZoomOutIcon />
                </button>
        </Grid>
        <Grid item xs={0.6} sx={{display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={()=> {dispatch(setScale(1)) * toast.success("Zoom Reset")}}>
            {AnnotatorSettings.scale*100}%
        </Grid>
        <Grid item xs={0.5} mr={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <button
                    style={{ padding: "7px" }}
                    className={styles.goBackButton}
                    onClick={() => {
                        const start = performance.now()
                        if (AnnotatorSettings.scale === 3) {
                            toast.error("Maximum zoom level reached")
                            return
                        }
                        dispatch(setScale(AnnotatorSettings.scale + 0.25))
                        const end = performance.now()
                        toast.success(`Zoomed In in ${(end - start).toFixed(0)}ms`)
                        
                    }}
                >
                    <ZoomInIcon />
                </button>
        </Grid>
        {/* invert ecg */}
        {!(Annotator.leadCount === 12) && <>
        <Grid item py={1} xs={0.1}>
            <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
        </Grid>
        <Grid item xs={0.8} px={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                Invert
                <Switch
                    size="small"
                    checked={AnnotatorSettings.inverted}
                    disabled={Annotator.leadCount === 12}
                    onClick={() => {
                        dispatch(setInverted(!AnnotatorSettings.inverted))
                        toast.success("Inverted ECG")
                    }}
                />
            </div>
        </Grid>
        </>}
        {/* no syn */}
        {(Annotator.leadCount === 12) && <>
        <Grid item py={1} xs={0.1}>
            <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
        </Grid>
        <Grid item xs={1} px={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                No Syn
                <Switch
                    size="small"
                    checked={Filters.noSyn}
                    disabled={Annotator.leadCount !== 12}
                    onClick={OnFiltered}
                    name="noSyn"
                />
            </div>
        </Grid>
        </>}
        {/* Filters 6 units */}
        <Grid item py={1} xs={0.1}>
            <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
        </Grid>
        <Grid item xs={1.8} px={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <FormControl sx={{height: "40px" }} fullWidth size="small">
                <InputLabel id="fil-1-label">Powerline Filter</InputLabel>
                <Select
                    labelId="fil-1-label"
                    id="fil-1"
                    label="Powerline Filter"
                    value={Filters.adaptive ? 10 : 30}
                    disabled = {!filterable}
                    onChange={OnFiltered}
                    name="adaptive"
                >
                    <MenuItem value={10}>Adaptive</MenuItem>
                    <MenuItem disabled value={20}>Notch</MenuItem>
                    <MenuItem value={30}>Off</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item py={3} xs={0.1}>
            <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
        </Grid>
        <Grid item xs={1.8} px={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <FormControl sx={{height: "40px" }} fullWidth size="small">
                <InputLabel id="fil-2-label">Baseline Filter</InputLabel>
                <Select
                    labelId="fil-2-label"
                    id="fil-2"
                    label="Baseline Filter"
                    value={Filters.ButterworthHighPass}
                    disabled = {!filterable}
                    onChange={OnFiltered}
                    name="highpass"
                >
                    <MenuItem value={0.1}>0.1Hz</MenuItem>
                    <MenuItem value={0.2}>0.2Hz</MenuItem>
                    <MenuItem value={0.5}>0.5Hz</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item py={3} xs={0.1}>
            <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
        </Grid>
        <Grid item xs={1.8} px={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <FormControl sx={{height: "40px" }} fullWidth size="small">
                <InputLabel id="fil-3-label">Muscle Filter</InputLabel>
                <Select
                    labelId="fil-3-label"
                    id="fil-3"
                    label="Muscle Filter"
                    value={Filters.ButterworthLowPass}
                    disabled = {!filterable}
                    onChange={OnFiltered}
                    name="lowpass"
                >
                    <MenuItem value={30}>30Hz</MenuItem>
                    <MenuItem value={40}>40Hz</MenuItem>
                    <MenuItem value={100}>100Hz</MenuItem>
                    <MenuItem value={0}>Off</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item py={1} xs={0.1}>
            <Divider orientation="vertical" sx={{margin: "0px 20px 0px 5px"}} />
        </Grid>
        {/* scale */}
        <Grid item px={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div className={styles.ScaleText}>25 mm/s &nbsp; 10 mm/mV</div>
        </Grid>
    </Grid>
  )
}

export default Toolbar