import React from 'react'
import { RouterProvider } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

// custom component
import AppLayout from './layouts/AppLayout'
import BasicLayout from './layouts/BasicLayout'
import FullScreenLayout from './layouts/FullScreenLayout'
import Sidebar from './components/Sidebar'
import Header from './components/common/Header'
import HeaderWithActionButtons from './components/HeaderWithActionButtons'
import Footer from './components/common/Footer'

import ROUTE_CONFIG from './route/config'
import { useRouteAuthorizationChecker, useRouter } from './route/hooks'
import ProtectedRoute from './route/components/ProtectedRoute'

import { ThemeProviderWithMode } from './theme/hooks'

import { useAuth } from './auth/hooks'
import GoogleAuth from './auth/components/GoogleAuth'

import { useLoadFonts } from './hooks/'
import { FONTS } from './constants/constants'
import ThemeModeToggler from './theme/components/ThemeModeToggler/ThemeModeToggler'

const App = () => {
  // loading fonts
  useLoadFonts([FONTS.WORK_SANS], true)
  const { auth } = useAuth()
  const user = auth.user
  const BaseLayout = <BasicLayout header={<Header />} footer={<Footer alignment='center'/>} />

  const ProtectedPageLayout = (
    <ProtectedRoute>
      <AppLayout
        sideNav={
          <Sidebar
            logoutButton={
              <GoogleAuth.LogoutButton>Log out</GoogleAuth.LogoutButton>
            }
            bottomMenu={<ThemeModeToggler /> }
          />
        }
        header={<HeaderWithActionButtons />}
      />
    </ProtectedRoute>
  )

  const ProtectedCustomPageLayout = (
    <ProtectedRoute>
      <FullScreenLayout />
    </ProtectedRoute>
  )

  const permissions = (user && user.permissions) || {}

  // Check if the accessed route is authorized or not
  const routeAuthroizationChecker = useRouteAuthorizationChecker(permissions)

  const router = useRouter([
    {
      config: ROUTE_CONFIG.PROTECTED(routeAuthroizationChecker),
      layout: ProtectedPageLayout,
    },
    {
      config: ROUTE_CONFIG.PUBLIC(routeAuthroizationChecker),
      layout: BaseLayout,
    },
    {
      config: ROUTE_CONFIG.CUSTOM_PROTECTED(routeAuthroizationChecker),
      layout: ProtectedCustomPageLayout,
    },
  ])
  return (
    <ThemeProviderWithMode>
      <CssBaseline /> {/* apply normalize.css */}
      <RouterProvider router={router}></RouterProvider>
    </ThemeProviderWithMode>
  )
}

export default App
