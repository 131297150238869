// import lib(s)
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./Annotator.module.css"
import { useTheme } from "@emotion/react"

import ROUTES from "../../route/routes"
import { useNavigate, useParams } from "react-router-dom"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"

import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Grid } from "@mui/material"

import copy2cliboard from "../../utils/copy2cliboard"
import JsonViewer from "./views/JsonView"
import TaskRecordings from "./TaskRecordings"

import { METHODS, errorHandler } from "../../hooks/useFetch"

import { removeBeat } from './AnnotatorSlice'
import Chip from "@mui/material/Chip"

const RightPanel = () => {

	const dispatch = useDispatch()
    const navigate = useNavigate()
	const urlParams = useParams()
	const theme = useTheme()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [AlgAnnotations, setAlgAnnotations] = useState({})
	const [AlgDet, setAlgDet] = useState([])

	const axiosPrivate = useAxiosPrivate()


	const isTurkTask = !(urlParams["task_id"] === "open");

	const FileRawData = useSelector((state) => state.Annotator.rawData)
	const Annotator = useSelector((state) => state.Annotator)
	const auth = useSelector((state) => state.auth)

	const kai12 = Annotator?.rawData?.data?.kai12Analysis || FileRawData?.kai12Analysis

	useEffect(() => {
	if(isTurkTask) {
		let tempAlgDet= []
		Annotator.annotations.algDet.map((alg) => {
			return Object.entries(alg).forEach(([key, value]) => (
				tempAlgDet.push(
					<Typography key={key} variant="p" component="div">
						<b>{key}</b> : {value}
					</Typography>
				)
			));
		})
		setAlgDet(tempAlgDet)
		let tempAlgAnnotations = {}
		Annotator.annotations.global.map((globalAnn) => {
			return Object.entries(globalAnn).forEach(([key, value]) => (
				tempAlgAnnotations[key] = value
			));
		})
		setAlgAnnotations(tempAlgAnnotations)
		setCurrentTab(0)
	} else {
		setCurrentTab(2)
	}
	}, [])
	

	const copyCurrentUrl = () => {
		copy2cliboard(window.location.href);
		toast.success("Copied to clipboard!")
	}

	const onClickSave = async () => {
		setLoading(true)
		let data = JSON.parse(JSON.stringify(Annotator.rawData));
		data.data.annotator.push({
			time: new Date().toISOString(),
			person: auth.user.email
		})
		data.data.annotationResults = {questionCorrect : true}
		// now write annotations
		// data.data.annotations.beatLabels
		// [{label:"n",sample:209,duration:1}]
		// TODO: Try to make this backward compatible 
		data.data.annotations.beatsV2 = Annotator.annotations.beats

		// ========================================================================
		// data.data.annotations.global
		// [{"hr":true}]
		let fGlobal = []
		Object.entries(AlgAnnotations).forEach(([key, value]) => (
			fGlobal.push({[key]: value})
		));
		data.data.annotations.global = fGlobal
		// ========================================================================
		// data.data.annotations.intervals
		// [{label:"Noisy",start:209,end:1}]
		// TODO: Try to make this backward compatible 
		data.data.annotations.intervalsV2 = Annotator.annotations.intervals
		// ========================================================================
		try {
			const { data: postData } = await axiosPrivate[METHODS.PUT.toLowerCase()](`recording`, data)
		} catch (error) {
			if (typeof error === "string") {
				toast.error(error)
			} else {
				toast.error("Error fetching Task Recordings")
			}
			console.log(error);
			setError(errorHandler(error))
		} finally {
			setLoading(false)
		}

		toast.success("Saved! Reopening Task...")
		window.loadTurkTask(urlParams["task_id"],urlParams["ecg_id"], false);
	}
	
	// Tab Logic
	const [currentTab, setCurrentTab] = React.useState(isTurkTask ? 0 : 2)
	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue)
	}


	return (
		<>
			{loading || error?.status === 421 ? (
				<Loader />
			) : error ? (
				<PlaceholderText>Error</PlaceholderText>
			) : (
				<React.Fragment>
					<CardContent sx={{padding: 1.5}}>
						<Grid container>
							{/* Header */}
							<Grid item xs={10} pb={2}>
								<Typography variant="h5" sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} component="div">
									{isTurkTask ? Annotator.currentTask.name : `Local ${urlParams["ecg_id"].toUpperCase()} File`}
								</Typography>
								{isTurkTask && <Typography variant="p" py={1} component="div">
									Recording id: <b>{Annotator.currentRecordingId}</b>
								</Typography>}
								{isTurkTask  ? 
								<Link onClick={copyCurrentUrl}>{window.location.href}</Link>
							 	:
								 <Link>This ECG is not saved on Cloud</Link>
								}
		
							</Grid>
							<Grid item xs={12}>
								<Divider variant="middle" />
							</Grid>
							{Annotator.leadCount == 12 && <>
								<Grid item xs={12} py={2}>
									<table style={{width:"100%"}}>
										<tbody>
										<tr>
											<td className={styles.title}>Heart Rate:</td>
											<td className={styles.text}>{kai12?.hr} bpm</td>
										</tr>
										<tr>
											<td className={styles.title}>PR Interval:</td>
											<td className={styles.text}>{kai12?.pr} ms</td>
										</tr>
										<tr>
											<td className={styles.title}>ORS Duration:</td>
											<td className={styles.text}>{kai12?.qrs} ms</td>
										</tr>
										<tr>
											<td className={styles.title}>QT / QTcB / QTcF:</td>
											<td className={styles.text}>{kai12?.qt} / {kai12?.qtcb} / {kai12?.qtcf} ms</td>
										</tr>
										<tr>
											<td className={styles.title}>P / QRS / T Axis:</td>
											<td className={styles.text}>{kai12?.pax}° / {kai12?.qax}° / {kai12?.tax}°</td>
										</tr>
										{Annotator.annotations.algStatement.type !== "" && <>
										<tr>
											<td className={styles.title}>Interpretations:</td>
										</tr>
										<tr>
											<td className={styles.text}>{Annotator.annotations.algStatement.type} selected</td>
										</tr>
										<tr>
											<td className={styles.title}>Rhythm:</td>
										</tr>
										{Annotator.annotations.algStatement.rhythm.map(t => (
											<tr>
												<td className={styles.text}>{t}</td>
											</tr>
										))}
										<tr>
											<td className={styles.title}>Morphology:</td>
										</tr>
										{Annotator.annotations.algStatement.morphology.map(t => (
											<tr>
												<td className={styles.text}>{t}</td>
											</tr>
										))}
										</>}
										</tbody>
									</table>
								</Grid>
								<Grid item xs={12}>
									<Divider variant="middle" />
								</Grid>
							</>}
							
							{/* Tabs */}
							<Grid item xs={12}>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<Tabs value={currentTab} onChange={handleTabChange} aria-label="Annotator Page Tab">
										<Tab value={0} sx={{ fontWeight: "Bold", display: `${!isTurkTask && "none"}` }} label="Recordings" />
										<Tab value={1} sx={{ fontWeight: "Bold", display: `${!isTurkTask && "none"}` }} label="Annotate" />
										<Tab value={2} sx={{ fontWeight: "Bold" }} label="JSON" />
									</Tabs>
								</Box>
							</Grid>
							{/* Tab Content */}
							{currentTab === 0 && (
								<Grid item xs={12} pt={2} sx={{ display: "flex", height: "62vh"}}>
									<TaskRecordings />
								</Grid>
							)}
							{currentTab === 1 && isTurkTask == true && (
								<>
									<Grid item xs={12} py={2}>
										{AlgDet.map((child) => child)}
									</Grid>
									<Grid item xs={12}>
										<Divider variant="middle" />
									</Grid>
									<Grid item xs={12} py={2}>
										<Typography variant="h6" pb={2} component="div">
											<b>Beat Annotation:</b>
										</Typography>
										<Typography variant="p" px={2} pb={1} component="div">
											<b>Labels:</b>
										</Typography>
										{ JSON.parse(JSON.stringify(Annotator?.annotations?.labels?.beats)).map(b => b.name !== "DEFAULT_LEFT_CLICK" && (
											<Chip
												key={b.slug}
												label={`${b.name}`}
												color="primary"
												style={{ margin: "3px", backgroundColor: b.color || "#29292929" }}
											/>
										))}
										<Typography variant="p" px={2} py={1} component="div">
											<b>Markings:</b>
										</Typography>
										{ JSON.parse(JSON.stringify(Annotator?.annotations?.beats)).sort((a, b) => a.i - b.i).map(b => (
											<Chip
												key={b.i}
												label={`${(b.i/Annotator.original.frequency).toFixed(1)}s`}
												color="primary"
												onDelete={() => {
													dispatch(removeBeat(b.i))
												}}
												style={{ margin: "3px", backgroundColor: Annotator?.annotations?.labels?.beats.find((label) => label.slug === b.t)?.color || "#29292929" }}
											/>
										))}
									</Grid>
									<Grid item xs={12}>
										<Divider variant="middle" />
									</Grid>
									<Grid item xs={12} py={2}>
										<Typography variant="h6" pb={1} component="div">
											<b>Global Annotation:</b>
										</Typography>
										<FormGroup>
											{Object.keys(AlgAnnotations).map((ann) => (
												<FormControlLabel
													key={ann}
													control={
														<Checkbox
															checked={AlgAnnotations[ann]}
															onChange={() => {setAlgAnnotations({...AlgAnnotations, [ann]: !AlgAnnotations[ann]})}}
															name={ann}
														/>
													}
													label={ann}
												/>
											))}
										</FormGroup>
									</Grid>
									<Grid item xs={6} sm={6} md={6} mb={2} p={1}>
										<Button
											variant="contained"
											sx={{ whiteSpace: "nowrap", width: "100%" }}
											size="large"
											onClick={() => {
												onClickSave()
											}}
										>
											Save
										</Button>
									</Grid>
									<Grid item xs={6} sm={6} md={6} mb={2} p={1}>
										<Button variant="contained" sx={{ whiteSpace: "nowrap", width: "100%" }} size="large" onClick={() => window.loadTurkTask(urlParams["task_id"],urlParams["ecg_id"], false)}>
											Clear
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Divider variant="middle" />
									</Grid>
									<Grid item xs={10} pb={2}>
										<Typography variant="h6" py={1} component="div">
											Audit Log
										</Typography>
										{Annotator?.annotations?.audit &&
											Annotator?.annotations?.audit.map((log, i) => (
												<Typography key={i} variant="p" component="div">
													{new Date(log.time).toLocaleDateString()} - <b>{log.person}</b> {log.message ? `: ${log.message}` : ""}
												</Typography>
											))}
									</Grid>
								</>
							)}

							{currentTab === 2 && (
								<Grid item xs={12} my={2}>
									<Typography variant="h6" component="div">
										{`JSON File Content: `}
									</Typography>
									<JsonViewer data={FileRawData} />
								</Grid>
							)}

						</Grid>
					</CardContent>
				</React.Fragment>
			)}
		</>
	)
}

export default RightPanel
