import React from 'react'
import Grid from '@mui/material/Grid'
import { useLocation, useParams } from 'react-router-dom'
import ROUTES from '../../route/routes'
import Header from '../common/Header'
import LinkButton from '../common/LinkButton'

export const HeaderWithActionButtons = () => {
  const { pathname } = useLocation()
  const { facilityId } = useParams()
  const ACTION_BUTTONS = {
    facilities: (
      <LinkButton variant="contained" to={ROUTES.ALL.FACILITIES_ADD}>
        Add Interop Facility
      </LinkButton>
    ),
    // 'facilitiesadd': (
    //   <Grid container gap={2}>
    //     <Grid item>
    //       <LinkButton variant="outlined" to={ROUTES.ALL.FACILITIES}>
    //         Cancel
    //       </LinkButton>
    //     </Grid>
    //     <Grid item>
    //       <LinkButton variant="contained" to={ROUTES.ALL.FACILITIES}>
    //         create
    //       </LinkButton>
    //     </Grid>
    //   </Grid>
    // ),
  }

  const key = (
    facilityId ? pathname.replace(facilityId, '') : pathname
  )?.replace(/\//g, '')
  return (
    <Header>
      <Grid item>{ACTION_BUTTONS[key]}</Grid>
    </Header>
  )
}

export default HeaderWithActionButtons
