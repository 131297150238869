// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increment, decrement, setCount } from './MainToolSlice';
import styles from "./MainTool.module.css";
import { useTheme } from "@emotion/react"

import ROUTES from "../../route/routes"
import { useNavigate, useParams } from "react-router-dom"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"

import JsonView from './views/JsonView';
import TaskRecordings from './TaskRecordings';
import AllLeadView from './views/AllLeadView';
import SingleLeadView from './views/SingleLeadView';
import ThreeRowView from './views/ThreeRowView';
import MedianBeats from './views/MedianBeats';
import RRIntervalPlot from './views/RRIntervalPlot';
import RightPanel from './RightPanel';
import OverreadPanel from './OverreadPanel';

import Toolbar from "./views/Toolbar"

import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"


import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Grid } from "@mui/material"

import { init,  setRawData, setCurrentTask, setCurrentTaskRecordings, setCurrentRecordingId, setAnnotatorState, selectLead } from './AnnotatorSlice'
import universalFormatter from "../../utils/universalFormatter"
import { METHODS, errorHandler } from "../../hooks/useFetch"

import FullWidthECGPreview from "../../components/FullWidthECGPreview"
import { useScrollContainer } from 'react-indiana-drag-scroll';


import logo from '../../assets/images/kardia_logo_blue.png'

import { Home, ChevronRight, ChevronLeft } from '@mui/icons-material'



const MainTool = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()
    const theme = useTheme()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const axiosPrivate = useAxiosPrivate()

    const isTurkTask =   !(urlParams["task_id"] === "open");

	// Tab Logic
	const [currentTab, setCurrentTab] = React.useState(0)
	const handleTabChange = (event, newValue) => {
		if (newValue == 100) {
			if (urlParams["task_id"] === "open") {
				toast.success(`${urlParams["ecg_id"].toUpperCase()} file closed!`)
				dispatch(init())
				navigate(ROUTES.ALL.OPEN_ECG)
			} else {
				console.log("task list")
				dispatch(init())
				navigate(ROUTES.ALL.TASK_LIST)
			}
		} else {
			setCurrentTab(newValue)
		}
	}

	// Annotator Data
	const AnnState = useSelector((state) => state.Annotator)
	useDocumentTitle( AnnState?.currentTask?.name || PAGE_TITLE.ANNOTATOR)


	const fetchTurkRecordingsAndTask = async (taskId, recordingID, changeTab = true, fetchAll = true) => {
		const start = performance.now()
		setLoading(true)
		fetchAll && dispatch(init())
		const url = window.location.href;
		const urlArray = url.split("/").filter((item) => item);
		try {
			// Fetch All recordingList of this Task
			const start = performance.now();
			
			if(fetchAll) {
				const { data: CurrentTask } = await axiosPrivate[METHODS.GET.toLowerCase()](`task?task_id=${taskId}`)
				dispatch(setCurrentTask(CurrentTask))

				const { data: TaskRecordingList } = await axiosPrivate[METHODS.GET.toLowerCase()](`recordings?task_id=${taskId}`)
				if (TaskRecordingList.length === 0) {
					toast.error("No Recordings Found")
					throw new Error("No Recordings Found")
				}
				dispatch(setCurrentTaskRecordings(TaskRecordingList))
			}

			// Fetch Recording Data of first recording
			const { data: RecordingData } = await axiosPrivate[METHODS.GET.toLowerCase()](`recording?recording_id=${recordingID}`)
			dispatch(setRawData(RecordingData))
			dispatch(setCurrentRecordingId(recordingID))
			// check if RecordingData.data.type is present 
			let uniEcg;
			if (RecordingData.data.hasOwnProperty("type")) {
				uniEcg = universalFormatter(RecordingData.data, RecordingData.data.type);
			} else {
				uniEcg = universalFormatter(RecordingData.data, "turk");
			}
			if (uniEcg.error !== null) { toast.error(uniEcg.error); throw new Error("Error processing recording") }
			const leadCount = Object.keys(uniEcg.filtered.samples).length
			const end = performance.now();
			// Calculate the elapsed time
			const elapsed = end - start;
			console.log(`Opening took ${elapsed} millisecond`);
			toast.success(`Loaded ${leadCount}L Turk Recording in ${elapsed.toFixed(1)}ms!`)
			dispatch(setAnnotatorState({ ...uniEcg, leadCount }))
			// window.sidebar.close()
			setLoading(false)

			if (changeTab) {
				if (leadCount < 7) {
					console.log("Current tab 0");
					setCurrentTab(0)
				} else {
					console.log("Current tab 1");
					setCurrentTab(1)
				}
			}
			setError(null)
		} catch (error) {
			if (typeof error === "string") {
				toast.error(error)
			} else {
				toast.error("Error fetching Task Recordings")
			}
			console.log(error);
			setError(errorHandler(error))
		} finally {
			setLoading(false)
			const end = performance.now()
			window.printPerformance("Fetching the recording took:",`${(end-start).toFixed(2)}ms`);
		}
	}


	useEffect( () => {
		window.loadTurkTask = fetchTurkRecordingsAndTask;
		let stopLoading = true;
		if(!isTurkTask && AnnState.leadCount === 0) {
			// toast.error("Data Error: ECG not found!")
			navigate(ROUTES.ALL.OPEN_ECG)
		} else if (AnnState.leadCount === 0) {
			stopLoading = false
			console.log("Fetching Data of recording: ", urlParams["ecg_id"], ". Task: ", urlParams["task_id"])
			fetchTurkRecordingsAndTask(urlParams["task_id"], urlParams["ecg_id"])
		}
		stopLoading && setLoading(false)

		document.addEventListener ('keyup', function (e) {
			if (e.keyCode === 9 ) {
				e.preventDefault();
				console.log("Tab pressed");
				if(currentTab < 5){
					if (AnnState.leadCount < 7 && currentTab == 0)
						setCurrentTab(currentTab + 2)
					else
						setCurrentTab(currentTab + 1)
				}
				else 
					setCurrentTab(0)
			}
		}, false);

		if(AnnState.leadCount == 12) {
			console.log("Current tab 1");
			setCurrentTab(1)
		}
		  
	}, [])

	// write a wrapper funtion that checks the loading state and shows loader or the child component
	const LoaderWrapper = ({children}) => {
		if (loading) {
			return <Loader />
		} else {
			return children
		}
	}


    const scrollContainer = useScrollContainer();

	const goNextRecording = () => {
		const currentRecordingIndex = AnnState.currentTaskRecordings.findIndex(obj => {return obj.id == AnnState.currentRecordingId})
		const totalRecordings = AnnState.currentTaskRecordings.length
		let newRecordingIndex = currentRecordingIndex + 1 == totalRecordings ? 0 : (currentRecordingIndex + 1)	
		console.log(newRecordingIndex);
		navigate(`/tool/${urlParams["task_id"]}/${AnnState.currentTaskRecordings[newRecordingIndex].id}`);
		window.loadTurkTask(urlParams["task_id"],AnnState.currentTaskRecordings[newRecordingIndex].id, false, false)
	}
    
	const goPrevRecording = () => {
		const currentRecordingIndex = AnnState.currentTaskRecordings.findIndex(obj => {return obj.id == AnnState.currentRecordingId})
		const totalRecordings = AnnState.currentTaskRecordings.length
		let newRecordingIndex = currentRecordingIndex - 1 == -1 ? totalRecordings -1 : (currentRecordingIndex - 1)
		console.log(newRecordingIndex);
		navigate(`/tool/${urlParams["task_id"]}/${AnnState.currentTaskRecordings[newRecordingIndex].id}`);
		window.loadTurkTask(urlParams["task_id"],AnnState.currentTaskRecordings[newRecordingIndex].id, false, false)
	}

    
    return (
        <>
                <div className={styles.mainContainer}>
                    <div className={styles.header}>
                        <div className={styles.hLeft}>
                            <img src={logo} className={styles.logo} />
                        </div>
                        <Divider orientation="vertical" variant="middle" sx={{marginRight: "20px", height: "75%"}} />
                        <div className={styles.hCenter}>
                            <div onClick={()=>navigate(ROUTES.ALL.TASK_LIST)} className={styles.hLink}>
                                <Home />
                            </div>
                            <ChevronRight />
                            <div onClick={()=>navigate(ROUTES.ALL.TASK_LIST)} className={styles.hLink}>
                                Task list
                            </div>
                            <ChevronRight />
                            <div className={styles.hLink}>
                                {AnnState?.currentTask?.name || PAGE_TITLE.ANNOTATOR}
                            </div>
                            <ChevronRight />
                            <div className={styles.hLink}>
                                {AnnState?.rawData?.data?.filename || "EKG Recording"}
                            </div>
                        </div>
                        <div className={styles.hRight}>
							<div className={styles.hLink}>
								<Button
                                    variant="contained"
									onClick={goPrevRecording}
                                >
                                    <ChevronLeft />
                                </Button>
								
								&nbsp;&nbsp; {AnnState.currentTaskRecordings.findIndex(obj => {return obj.id == AnnState.currentRecordingId}) + 1} of {AnnState.currentTaskRecordings.length} &nbsp;&nbsp;
								<Button
                                    variant="contained"
									onClick={goNextRecording}
                                >
                                    <ChevronRight />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.toolContainer}>

                        <div className={styles.tool1Col}>
							<TaskRecordings />
                        </div>
                        <div className={styles.tool2Col}>
					<LoaderWrapper>
						<>
                            <div className={styles.tool2Col1Row}>
                                <Tabs value={currentTab} onChange={handleTabChange} aria-label="Annotator Page Tab">
                                    <Tab value={0} sx={{ fontWeight: "Bold" }} label="Row View" />
                                    <Tab value={1} sx={{ fontWeight: "Bold", display: (AnnState.leadCount < 7 && "none") }} label={`${AnnState.leadCount/3}x${(30/AnnState.leadCount).toFixed(1)}s - 1 Channel(s) View`} />
                                    <Tab value={2} sx={{ fontWeight: "Bold" }} label="Lead Annotator" />
                                    <Tab value={3} sx={{ fontWeight: "Bold" }} label="Median Beat Plot" />
                                    {/* <Tab value={3} sx={{ fontWeight: "Bold", display: (!AnnState.annotations.medianBeatGraph?.I && "none") }} label="Median Beats" /> */}
                                    <Tab value={4} sx={{ fontWeight: "Bold", }} label="RR Interval" />
                                    {/* <Tab value={100} sx={{ marginLeft: "auto", fontWeight: "Bolder", color: "#ff6961" }} label="&times; Close" /> */}
                                </Tabs>
                            </div>
                            <div className={styles.tool2Col2Row}>
                                {currentTab == 0 && (
                                    <AllLeadView changeTab={setCurrentTab} />
                                )}
                                {currentTab == 1 && (
                                    <ThreeRowView />
                                )}
                                {currentTab == 2 && (
                                    <>
                                        { AnnState.selectedLead === null ? (
                                            <Card className={styles.disableselect} sx={{ width: "1150px", height: "calc(100%)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                                <Typography variant="h5" sx={{whiteSpace: "initial" ,overflowWrap: "break-word"}} my={3} component="div">
                                                    Select a Lead to annotate
                                                </Typography>
                                                <FormControl sx={{width: "200px" }}  size="small">
                                                    <InputLabel id="lead-select-label">ECG Leads</InputLabel>
                                                    <Select
                                                        labelId="lead-select-label"
                                                        id="lead-select"
                                                        label="ECG Leads"
                                                        onChange={(e) => dispatch(selectLead(e.target.value))}
                                                        value={AnnState.selectedLead}
                                                    >
                                                        {Object.keys(AnnState.filtered.samples).map((lead) => (
                                                            <MenuItem  key={lead} value={lead}>Lead {lead}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Card>
                                        ) : (
                                            <>
                                                <SingleLeadView changeTab={setCurrentTab} />
                                            </>
                                        )}
                                    </>
                                )}
                                {currentTab == 3 && (
                                    <MedianBeats />
                                )}
                                {currentTab == 4 && (
                                    <RRIntervalPlot />
                                )}
                            </div>
                            <div className={styles.tool2Col3Row}>
                                <Toolbar disableTitle={true} />
                            </div>
							</>
						</LoaderWrapper>
                        </div>
                        <div className={styles.tool3Col}>
							<LoaderWrapper>
							{AnnState.annotations.overreadMode ?
								<OverreadPanel />
								:
								<RightPanel />
							}
							</LoaderWrapper>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default MainTool