import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getTitle, pageActions } from '../pages/slices/page.slice'

// Updates document title with given value or the stored state page title

const useDocumentTitle = (documentTitle) => {
  const { pathname } = useLocation()
  const { facilityId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pageActions.updateTitle(documentTitle))
  }, [dispatch, documentTitle])

  const storedTitle = useSelector(getTitle)

  const title = storedTitle
  useEffect(() => {
    document.title =
      (title && title[0].toUpperCase() + title.substring(1)) || ''
  }, [title])

  return [title, pathname, facilityId]
}

export default useDocumentTitle
