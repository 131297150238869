import { useSelector } from 'react-redux'
import { PAGE_TITLE } from '../../../constants/constants'
import { getTitle } from '../../../pages/slices/page.slice'

const BreadcrumbTitle = ({ prop }) => {
  const storedTitle = useSelector(getTitle)
  if (prop === 'STORED_TITLE') return storedTitle
  return PAGE_TITLE[prop] || ''
}

export default BreadcrumbTitle
