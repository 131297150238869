import React from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Divider from '@mui/material/Divider'

const DefaultToolbarContainer = (props) => {
  const { disableQuickFilter } = props
  return (
    <>
      <GridToolbarContainer
        sx={{
          paddingBottom: 3,
          gap: 3,
        }}
      >
        <GridToolbarColumnsButton
          variant="outlined"
          size="large"
          startIcon={null}
          endIcon={<ViewColumnOutlinedIcon />}
        />
        <GridToolbarFilterButton
          variant="outlined"
          // startIcon={null} ** NOT WORKING **
          componentsProps={{
            button: {
              size: 'large',
              startIcon: null,
              endIcon: <FilterListOutlinedIcon />,
            },
          }}
          // endIcon={<FilterListOutlinedIcon />} ** NOT WORKING **
        />
        <GridToolbarDensitySelector
          size="large"
          variant="outlined"
          startIcon={null}
          endIcon={<FormatAlignJustifyOutlinedIcon />}
        />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }} // removing print option from export dropdown, will add it back, once all the print issues resolved
          size="large"
          variant="outlined"
          startIcon={null}
          endIcon={<FileDownloadOutlinedIcon />}
        />
        {!disableQuickFilter && (
          <Box
            sx={{
              p: 0.5,
              pb: 0,
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <GridToolbarQuickFilter
              variant="outlined"
              fullWidth
              sx={{
                '& input': {
                  height: '9px',
                  width: '16rem',
                },
              }}
            />
          </Box>
        )}
      </GridToolbarContainer>
      <Divider />
    </>
  )
}

export default DefaultToolbarContainer
